import { api } from "../../services/api.service";
import { toastr } from "../../services/common.service";

export const getUserPermission = async () => {
    try {
        const response = await api.get('admin/user/permissions');
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const updateUserPermission = async (permissionsData) => {
    try {
        const response = await api.post('admin/user/permissions', permissionsData);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}