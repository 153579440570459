import { createColumnHelper, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import AlertModal from '../Common/CustomConfirmationModal/CustomConfirmationModal';
import moment from 'moment';
import NewMember from '../attendance/add-member/NewMember';
import { s3Url } from '../../services/api.service';

const NewEntryList = ({ data, currenetSabha, handleCollapsePofile, handleDeleteUser }) => {
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
    const [currentUserId, setCurrentUserId] = useState('');
    const [columnPinning, setColumnPinning] = useState({
        right: ['actions'],
    });
    const [userDetail, setUserDetail] = useState({});

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const columnHelper = createColumnHelper();

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [sorting, setSorting] = useState([]);

    const editUserDetail = (_id) => {
        const user = data.find(
            (member) => member._id + "" === _id + ""
        );
        setUserDetail(user);
        setIsAddMemberModalOpen(true);
    };

    const deleteNewUser = async (id) => {
        handleDeleteUser(id);
    }

    const columns = [
        columnHelper.accessor('photo', {
            cell: info => <div className='w-12 h-12'><img src={s3Url + info.row.original.profileImgUrl} className='w-full h-full rounded-full mr-3 object-cover' /></div>,
            header: () => <span>Photo</span>,
            size: 150,
        }),
        columnHelper.accessor('fullName', {
            cell: (info) => (
                <div className="flex items-center">

                    {info.row.original?.firstName} {info.row.original?.middleName}{" "}
                    {info.row.original?.lastName}
                </div>
            ),
            header: () => <span>Name</span>,
            size: 450,
        }),
        columnHelper.accessor('age', {
            cell: info => info.getValue(),
            header: () => <span>Age</span>,
        }),
        columnHelper.accessor('mobile', {
            cell: info => info.getValue(),
            header: () => <span>Mobile No.</span>,
        }),
        columnHelper.accessor('city', {
            cell: info => info.getValue(),
            header: () => <span>City</span>,
        }),
        columnHelper.accessor('state', {
            cell: info => info.getValue(),
            header: () => <span>State</span>,
        }),
        columnHelper.accessor('country', {
            cell: info => info.getValue(),
            header: () => <span>Country</span>,
        }),
        columnHelper.accessor('birthDate', {
            cell: info => moment(info.getValue()).format('DD-MM-YYYY'),
            id: "birthDate",
            header: () => <span>DOB</span>,
        }),
        columnHelper.accessor('occupation', {
            cell: info => info.getValue(),
            header: () => <span>occupation</span>,
        }),
        columnHelper.accessor('education', {
            cell: info => info.getValue(),
            header: () => <span>education</span>,
        }),
        columnHelper.accessor('referrerName', {
            cell: info => info.getValue(),
            header: () => <span className='whitespace-pre'>Referrer Name</span>,
        }),
        columnHelper.accessor('referrerMobile', {
            cell: info => info.getValue(),
            header: () => <span className='whitespace-pre'>Referrer Mo.</span>,
        }),
        columnHelper.accessor('village', {
            cell: info => info.getValue(),
            header: () => <span>Village</span>,
        }),
        columnHelper.display({
            header: () => <span className="text-center">Actions</span>,
            id: 'actions',
            cell: info => {
                return (
                    <span className="flex items-center justify-center" onClick={(e) => e.stopPropagation()}>
                        {/* <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
                            <button onClick={() => editUserDetail(info.row.original?._id)}>
                                <Icon
                                    icon="ic:baseline-edit"
                                    className="text-2xl transition-all duration-150 text-black900"
                                />
                            </button>
                        </span> */}
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button onClick={() => {
                                setCurrentUserId(info.row.original);
                                handleOpenUpdateModal();
                            }}>
                                <Icon
                                    icon="material-symbols:delete"
                                    className="transition-all duration-150 text-2xl text-black900"
                                />
                            </button>
                        </span>
                    </span>
                )
            }
        }),
    ]

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            columnPinning,
            pagination
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnPinningChange: setColumnPinning,
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        getRowId: row => row.id,
    })

    const handleOpenUpdateModal = () => {
        setShowConfirmDeleteModal(!showConfirmDeleteModal)
    }

    const handleProfileUpdate = (value) => {
                if (value) {
            deleteNewUser(currentUserId._id);
        }
        setShowConfirmDeleteModal(false)
    }

    return (
        <>
            <div className='p-2'>
                <div className='overflow-auto'>
                    <table className="w-full member-table">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id} className='border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]'>
                                    {headerGroup.headers.map(header => {
                                        const { column } = header
                                        return (
                                            <th key={header.id} className='text-left font-semibold p-2 last:text-center last:sticky last:w-[180px] last:z-10 last:right-0 bg-[#F2F2F2] last:shadow-[gray_4px_0px_4px_-4px_inset]'>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </th>

                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id} className='border-b border-stone-200 text-sm bg-white' onClick={() => {
                                    handleCollapsePofile(row?.original);
                                }}>
                                    {row.getVisibleCells().map(cell => {
                                        const { column } = cell
                                        return (
                                            <td key={cell.id} className='p-2 last:text-center last:sticky last:w-[180px] last:z-10 last:right-0 bg-white last:shadow-[gray_4px_0px_4px_-4px_inset]'>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>

                                        )
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-center justify-end gap-2 mt-3">
                    <button
                        className="border rounded w-8 h-8 grid place-items-center"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <Icon icon="lucide:chevron-left" className="text-xl" />
                    </button>
                    <button
                        className="border rounded w-8 h-8 grid place-items-center"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <Icon icon="lucide:chevron-right" className="text-xl" />
                    </button>
                    <span className="flex items-center gap-1">
                        <div>Page</div>
                        <strong>
                            {table.getState().pagination.pageIndex + 1} of{" "}
                            {table.getPageCount().toLocaleString()}
                        </strong>
                    </span>
                    <span className="flex items-center gap-1">
                        | Go to page:
                        <input
                            type="number"
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                table.setPageIndex(page);
                            }}
                            className="border p-1 rounded w-16 outline-none"
                        />
                    </span>
                    <select
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                        className="outline-none p-1 border rounded "
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <AlertModal
                open={showConfirmDeleteModal}
                handleCloseModal={handleProfileUpdate}
                handleAction={deleteNewUser}
                messageText="Are you sure you want to delete this entry?"
                submitBtnText="Delete"
                buttonType="danger"
                icon="material-symbols:delete-outline"
            />
            {isAddMemberModalOpen && (
                <NewMember
                    setIsAddMemberModalOpen={setIsAddMemberModalOpen}
                    userDetail={userDetail}
                    isNewUser={true}
                />
            )}
        </>
    )
}

export default NewEntryList