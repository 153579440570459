import { api } from "../../../services/api.service";

export const getLiveVideoList = async () => {
  const response = await api.get("admin/all-katha-video-list");
  return response;
};

export const createLiveVideo = async (data) => {
  const response = await api.post("admin/live-katha/create", data);
  return response;
};

export const updateLiveVideo = async (data) => {
  const response = await api.post("admin/live-katha/update", data);
  return response;
};

export const deleteLiveVideo = async (id) => {
  const params = { _id: id };
  const response = await api.post("admin/live-katha/delete", params);
  return response;
};

export const changeLiveVideoVisibility = async (params) => {
  const response = await api.post("admin/live-katha/change-visibility", params);
  return response;
};
