import { api } from "../../services/api.service";

export const getForm = async () => {
  const response = await api.get("admin/forms");
  return response;
};
  
export const createForm = async (data) => {
  const response = await api.post("admin/forms", data);
  return response;
};

export const updateForm = async (id, data) => {
  const response = await api.put(`admin/forms/${id}`, data);
  return response;
};

export const updateFormFile = async (id, data) => {
  const response = await api.put(`admin/forms/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
  return response;
};

export const deleteForm = async (id) => {
  const response = await api.delete(`admin/forms/${id}`);
  return response;
};

export const getUsersSubmittedForm = async (id) => {
  const response = await api.get(`admin/forms/${id}`);
  return response;
};

export const submitUserForm = async (data) => {
  const response = await api.post("forms/submit", data);
  return response;
};

export const updateUserForm = async (data) => {
  const response = await api.post("forms/update", data);
  return response;
};

export const deleteUserSubmittedForm = async (id) => {
  const response = await api.delete(`admin/forms/submissions/${id}`);
  return response;
};