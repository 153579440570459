import { Typography } from "@material-tailwind/react";
import React from "react";
import { s3Url } from "../../services/api.service";

export default class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { mantraJapData } = this.props;

    const mantraJapRow = () => {
      return mantraJapData?.map((item, index) => {
        return ( 
            <tr key={item.id} className='text-center font-semibold p-2'>
                <td class="p-2 border-b border-blue-gray-50">
                  <Typography variant="medium" color="blue-gray" className="font-medium text-stone-400 text-sm font-[500] capitalize">
                    {item?.rank}
                  </Typography>
                </td>
                <td class="p-2 border-b border-blue-gray-50">
                  <Typography variant="medium" color="blue-gray" className="font-medium text-stone-400 text-sm font-[500] capitalize">
                    {item?.SMVNo}
                  </Typography>
                </td>
                <td class="p-2 border-b border-blue-gray-50">
                  <img
                      className=" min-w-[48px] max-w-[48px] h-12 rounded-full mr-3 object-cover"
                      alt="profile"
                      src={
                        item.profileImgUrl
                          ? `${s3Url}${item.profileImgUrl}`
                          : item?.gender === 'female' ? "/assets/image/female-avatar.jpg" : "/assets/image/profilepicture.png"
                      }
                    />
                </td>
                <td class="p-2 border-b border-blue-gray-50 text-left">
                  <Typography variant="medium" color="blue-gray" className="font-medium text-stone-400 text-sm font-[500] capitalize">
                    {item?.firstName} <span>  </span>  {item?.lastName }
                  </Typography>
                  </td>
                <td class="p-2 border-b border-blue-gray-50 text-center">
                  <Typography variant="medium" color="blue-gray" className="font-medium text-stone-400 text-sm font-[500] capitalize">
                    {item?.mantraCount}
                  </Typography>
                </td>
                <td class="p-2 border-b border-blue-gray-50 text-center">
                  <Typography variant="medium" color="blue-gray" className="font-medium text-stone-400 text-sm font-[500] capitalize">
                    {item?.gender}
                  </Typography>
                </td>
                <td class="p-2 border-b border-blue-gray-50 text-center">
                  <Typography variant="medium" color="blue-gray" className="font-medium text-stone-400 text-sm font-[500] capitalize">
                    {item?.city}
                  </Typography>
                </td>
                <td class="p-2 border-b border-blue-gray-50 text-center">
                  <Typography variant="medium" color="blue-gray" className="font-medium text-stone-400 text-sm font-[500] capitalize">
                    {item?.mobile}
                  </Typography>
                </td>
            </tr>
        )
    })}

    return (
      <div className="p-1">
        <h2 style={{color: "orange"}}> શ્રી સ્વામિનારાયણ મહામંત્ર લેખન સંખ્યા </h2>
        <table className="table-auto">
          {/* <caption class="caption-top">
            Date: 
          </caption> */}
          <thead>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Rank No</th>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">SMV. No</th>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Photo</th>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Name</th>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Total Mantra Lekhan</th>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Gender</th>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">City</th>
            <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Mobile No</th>
          </thead>
          <tbody>
            { mantraJapRow() }
          </tbody>
        </table>
      </div>
    );
  }
}
  