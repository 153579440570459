import { Radio } from "@material-tailwind/react";
import { mdiTrayArrowUp } from "@mdi/js";
import { mdiTrashCan } from "@mdi/js";
import { mdiSquareEditOutline } from "@mdi/js/mdi";
import Icon from "@mdi/react";
import React, { useState } from "react";
import EventUploadModal from "./EventUploadModal";
const imglist = [
  {
    id: 1,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 2,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 3,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 4,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 5,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 6,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 7,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 8,
    path: "/assets/image/raksha.jpg",
  },
];
const EventPhoto = () => {
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <React.Fragment>
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Event Photos</div>
          <div className="flex items-center">
            <div className="flex gap-10 mr-10">
              <Radio name="type" label="Saint" />
              <Radio name="type" label="Devotee" defaultChecked />
            </div>
            <a className="w-10 h-10 bg-white border p-1.5 rounded-[50%] border-solid border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045]" onClick={handleOpenModal}>
              <Icon path={mdiTrayArrowUp} size={0.9} />
            </a>
          </div>
        </div>
        <div className="bg-white w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="flex items-top justify-between mb-[26px]">
            <div>
              <div className="text-[20px] text-[#212121]">Rakshabandhan</div>
              <div className="text-[#A4A4A4] text-[11px] mt-0.5 mp-[2px]">
                Date :- <span>00-00-2023</span>
              </div>
            </div>
            <div className="flex items-center gap-[18px]">
              <a className="w-8 h-8 bg-[#F8F8F8] flex items-center justify-center border transition-all duration-300 ease-[ease] p-[5px] rounded-[50%] border-solid border-transparent hover:shadow-[0px_2px_4px_0px_#00000045] hover:border-[black]">
                <Icon path={mdiSquareEditOutline} size={0.9} />
              </a>
            </div>
          </div>
          <div className="flex flex-wrap -m-2.5">
            {imglist.map((item, index) => {
              return (
                <div key={index} className="w-1/4 h-full p-2.5">
                  <div className="rounded-[10px] bg-[#ffa8a8] overflow-hidden h-full relative">
                    <img
                      src={item.path}
                      alt="profile-img"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full flex items-center justify-center p-3.5 gap-x-3 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                      <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                        <Icon path={mdiTrashCan} size={1} />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <EventUploadModal open={openModal} onClose={handleClose} />
    </React.Fragment>
  );
};

export default EventPhoto;
