import React, { useState, useRef, Fragment, useEffect } from 'react'
import { Dialog, Transition, TransitionChild } from '@headlessui/react'
import Cropper from 'react-easy-crop'
import Slider from 'rc-slider'
import getCroppedImg from './CropImage'
import { api, s3Url } from '../../../services/api.service'
import { toastr } from '../../../services/common.service'


const ImageCropModal = ({ isOpen, setOpenImageCropModal, profileImage, setMemberDetails, isNewUser }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [previewImage, setPreviewImage] = useState(null);

    const handleImgUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleCloseModal = () => {
        setPreviewImage(null);
        setCroppedAreaPixels(null);
        setZoom(1);
        setRotation(0);
        setCrop({ x: 0, y: 0 });
        setOpenImageCropModal(false);
    }

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                previewImage ? previewImage : profileImage,
                croppedAreaPixels,
                rotation
            )
            fetch(croppedImage)
                .then(response => response.blob())
                .then(blob => {
                    const filename = 'cropped_image.jpg';
                    const file = new File([blob], filename, { type: blob.type });
                    handleFileUpload(file)
                })
                .catch(error => {
                    console.error('Error fetching blob:', error);
                });
        } catch (e) {
            console.error(e)
        }
        handleCloseModal();
    }
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleFileUpload = (file) => {
        const reader = new FileReader();

        reader.onloadend = async () => {
            const formData = new FormData();
            formData.append('profileImg', file);
            try {
                const folderName = isNewUser ? "/new-user/profile" : "/user/profile"
                const profile = await api.post("/admin" + folderName, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setMemberDetails('profileImgUrl', profile.data.profileImgUrl);
            } catch (error) {
                toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
            }
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }


    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => { }}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[70vw] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div>
                                        <label className="block mb-3 w-fit">
                                            <span className="sr-only">Choose profile photo</span>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImgUpload}
                                                className="block w-full text-sm outline-none text-slate-500 file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-primary file:text-white"
                                            />
                                        </label>
                                    </div>
                                    <div className="mt-2 min-h-[450px] relative">
                                        <div className="absolute inset-x-0 top-0  h-full">
                                            <Cropper
                                                image={previewImage ? previewImage : profileImage}
                                                crop={crop}
                                                zoom={zoom}
                                                rotation={rotation}
                                                aspect={1 / 1}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                                onRotationChange={setRotation}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-center gap-10 mt-10'>
                                        <div className='w-1/3'>
                                            <div className='text-lg'>Zoom</div>
                                            <div className="controls ml-2 h-10 flex items-center">
                                                <Slider
                                                    defaultValue={zoom}
                                                    railStyle={{ backgroundColor: '#e0e1dd', height: 10 }}
                                                    trackStyle={{ backgroundColor: '#778da9', height: 10 }}
                                                    handleStyle={{
                                                        borderColor: '#0d1b2a',
                                                        height: 20,
                                                        width: 20,
                                                        marginLeft: 0,
                                                        marginTop: -5,
                                                        backgroundColor: '#0d1b2a',
                                                        opacity: 1
                                                    }}
                                                    min={1}
                                                    max={3}
                                                    step={0.1}
                                                    onChange={(value) => {
                                                        setZoom(value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='w-1/3'>
                                            <div className='text-lg'>Rotate</div>
                                            <div className="controls ml-2 h-10 flex items-center">
                                                <Slider
                                                    defaultValue={rotation}
                                                    railStyle={{ backgroundColor: '#e0e1dd', height: 10 }}
                                                    trackStyle={{ backgroundColor: '#778da9', height: 10 }}
                                                    handleStyle={{
                                                        borderColor: '#0d1b2a',
                                                        height: 20,
                                                        width: 20,
                                                        marginLeft: 0,
                                                        marginTop: -5,
                                                        backgroundColor: '#0d1b2a',
                                                        opacity: 1
                                                    }}
                                                    min={0}
                                                    max={360}
                                                    step={1}
                                                    onChange={(value) => {
                                                        setRotation(value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 text-right flex items-center justify-end gap-5">
                                        <button
                                            type="button"
                                            className={`mr-3 inline-flex justify-center rounded-md font-semibold bg-white px-4 py-2 text-sm text-red-600 hover:text-red-800 focus:outline-none`}
                                            onClick={handleCloseModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={showCroppedImage}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default ImageCropModal