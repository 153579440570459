import React from "react";
import { useRoutes } from "react-router-dom";
import Newentry from "../../../Components/newentry/Newentry";
import Photo from "../../../Components/newentry/photo";

export default function NewentryRouter() {
    const NewentryRoute = [
        {
            path: "memberList",
            element: (
                <>
                    <Newentry />
                </>
            )
        },
        {
            path: "photo",
            element: (
                <>
                    <Photo />
                </>
            )
        },
    ];

    const NewentryRouter = useRoutes(NewentryRoute);
    return NewentryRouter;
}
