import React, { useState } from 'react';
import lodash from "lodash";
import { useSelector, useDispatch } from 'react-redux';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import FormStepper from '../stepper/Stepper';
import BasicInfo from './basicInfo';
import OtherInfo from './OtherInfo';
import ImageUpload from './ImageUpload';
import GroupInfo from './GroupInfo';
import { toastr } from '../../../services/common.service';
import { ACTION_TYPES } from '../../../redux/constants';
import { createNewMember, createUser, updateNewMember, updateUser } from './actionCreator';

const options = [
  {value: 'one', label : "One"},
  {value: 'two', label : "Two"},
  {value: 'three', label : "Three"}
]

const AddMember = (props) => {
  const dispatch = useDispatch();
  const { setIsAddMemberModalOpen, userDetail, isNewUser } = props;

  const { societyList, streetNameList, cityList, stateList, countryList, villageList, occupationList, educationList } = useSelector((state) => state.MembersReducer);

  const [activeStep, setActiveStep] = useState(0);
  const [isEditUser, setIsEditUser] = useState(userDetail?._id ? true : false);
  const [memberDetails, setMemberDetails] = useState(userDetail?._id ? userDetail : {});
  const [diabledCreateButton, setDisabledCreateButton] = useState(false);

  const _SetMemberDetails = (field, value) => {
    const _memberDetails = lodash.cloneDeep(memberDetails);
    if(["mobile", "mobile2", "referrerMobile"].includes(field)) {
      if(value.length > 0) {
        let mobileValue = Number(value)
        if(!mobileValue) return
        else value = mobileValue
      }
    }
    _memberDetails[field] = value;
    setMemberDetails(_memberDetails);
  }

  const _addSabhaListToMember = (sabhaDetail, field, value) => {
    const _memberDetails = lodash.cloneDeep(memberDetails);
    const isAttendingSabha = _memberDetails.attendeeOf?.find((d) => d.sabhaId === sabhaDetail._id);
    if (!isAttendingSabha) {
      if (!_memberDetails.attendeeOf) _memberDetails.attendeeOf = [];
      _memberDetails.attendeeOf.push({
        sabhaId: sabhaDetail._id,
        sabhaName: sabhaDetail.name
      })
    }
    _memberDetails.attendeeOf.forEach((d) => {
      if (d.sabhaId === sabhaDetail._id) {
        d[field] = value;
      }
    });
    const finalSabhaDetail = _memberDetails.attendeeOf?.filter((d) => !!d?.groupNo);
    _memberDetails.attendeeOf = finalSabhaDetail;
    setMemberDetails(_memberDetails);
  }

  const goToNextPage = async () => {
    let nextPage = true
    if(activeStep === 0) {
      if(!memberDetails.firstName || memberDetails?.firstName.trim().length === 0) {
        toastr.show('Please enter First Name', 'error');
        nextPage = false
      }
      if(!memberDetails.middleName || memberDetails?.middleName.trim().length === 0) {
        toastr.show('Please enter Middle Name', 'error');
        nextPage = false
      }
      if(!memberDetails.lastName || memberDetails?.lastName.trim().length === 0) {
        toastr.show('Please enter Last Name', 'error');
        nextPage = false
      }
      if(!memberDetails.mobile || memberDetails?.mobile.length === 0) {
        toastr.show('Please enter Mobile Number 1', 'error');
        nextPage = false
      }
    }
    if(nextPage) {
      setActiveStep(activeStep + 1)
    };
    
  }

  const createMemberEntry = async () => {
    if (!isNewUser) {
      memberDetails.address = {
        blockNo: memberDetails.blockNo || memberDetails?.address?.blockNo,
        streetName: memberDetails.streetName || memberDetails?.address?.streetName,
        society: memberDetails.society || memberDetails?.address?.society,
        landmark: memberDetails.landmark || memberDetails?.address?.landmark,
        city: memberDetails.city || memberDetails?.address?.city,
        state: memberDetails.state || memberDetails?.address?.state,
        postalCode: memberDetails.postalCode || memberDetails?.address?.postalCode,
        country: memberDetails.country || memberDetails?.address?.country,
      }
      // memberDetails.attendeeOf = [];
      // memberDetails.attendeeOf =
      //   memberDetails.attendeeOf?.filter(
      //       (sabha) => sabha?.sabhaId && sabha?.sabhaName && sabha?.groupNo
      //   ) || [];
    }
    const userData = lodash.cloneDeep(memberDetails);
    userData.mobile = userData.mobile.toString();
    if (userData?.mobile2) userData.mobile2 = userData.mobile2.toString();
    if (userData?.referrerMobile)
        userData.referrerMobile = userData.referrerMobile.toString();
    let updateData = "";
    if (isNewUser) {
      if (isEditUser) {
        userData._id = memberDetails._id
        updateData = await updateNewMember(userData);
        dispatch({
          type: ACTION_TYPES.UPDATE_NEW_MEMBER,
          payload: updateData.data,
        })
      } else {
        if (userData?.attendeeOf?.value) {
          userData.sabhaId = userData?.attendeeOf?.value;
          delete userData?.attendeeOf;
        }
        updateData = await createNewMember(userData);
        setMemberDetails({});
        dispatch({
          type: ACTION_TYPES.ADD_NEW_MEMBER,
          payload: updateData.data,
        })
      }
    } else {
      delete userData?.blockNo;
        delete userData?.streetName;
        delete userData.blockNo
        delete userData.streetName
        delete userData.society
        delete userData.landmark
        delete userData.city
        delete userData.state
        delete userData.postalCode
        delete userData.country
        if (userData?.address?.postalCode) userData.address.postalCode = userData.address.postalCode.toString();
      if (isEditUser) {
        userData._id = memberDetails._id
        updateData = await updateUser(userData);
        dispatch({
          type: ACTION_TYPES.UPDATE_MEMBER,
          payload: updateData.data,
        })
      } else {
        updateData = await createUser(userData);
        dispatch({
          type: ACTION_TYPES.ADD_MEMBER,
          payload: updateData.data,
        })
      }
    }
    setDisabledCreateButton(false);
    if(updateData?.data) {
      toastr.show(`Successfully ${isEditUser ? "Updated" : "Added"}`, 'success');
      setIsAddMemberModalOpen(false);
    } else {
      toastr.show(`Something went wrong`, 'error');
    }
  }

  const closeMemeberModel = () => {
    setIsAddMemberModalOpen(false)
  }

  return (
    <div>
      <div className=" fixed right-2.5 top-2.5">
        <button type='button' onClick={() => closeMemeberModel()}>
          <Icon
            path={mdiClose}
            size={1}
          />
        </button>
      </div>
      <div className='max-w-[700px] mx-auto pb-10'>
        <FormStepper
          activeStepProps={activeStep}
          isEditUser={isEditUser}
          isNewUser={isNewUser}
        />
      </div>
      <div className='p-2.5 custom-scroll'>
        <div className='max-h-[calc(100vh_-_215px)]  overflow-auto'>
          <div className='border border-stone-200 rounded-lg p-10 min-h-[520px] flex flex-col justify-between'>
            <div>
              {activeStep === 0 &&
                <BasicInfo
                  memberDetails={memberDetails}
                  setMemberDetails={_SetMemberDetails}
                  streetNameList={streetNameList}
                  societyList={societyList}
                  cityList={cityList}
                  stateList={stateList}
                  countryList={countryList}
                  isNewUser={isNewUser}
                />
              }
              {activeStep === 1 &&
                <OtherInfo
                  memberDetails={memberDetails}
                  setMemberDetails={_SetMemberDetails}
                  options={options}
                  occupationList={occupationList}
                  educationList={educationList}
                  villageLis={villageList}
                />
              }
              {activeStep === 2 &&
                <ImageUpload 
                  setMemberDetails={_SetMemberDetails}
                />
              }
              {(isEditUser || !isNewUser) && activeStep == 3 &&
                <GroupInfo
                  memberDetails={memberDetails}
                  setMemberDetails={_SetMemberDetails}
                  addSabhaListToMember={_addSabhaListToMember}
                />
              }
            </div>
            <div>
              <div className='px-3 py-1 mt-14 text-center'>
                {activeStep > 0 &&
                  <button type='button' className='inline-flex mr-3 items-center bg-[#F2F2F2] min-w-[90px] justify-center text-black900 text-sm px-2 py-2 rounded-lg'
                    onClick={() => {
                      if (activeStep < 0) {
                        setActiveStep(0)
                      } else {
                        setActiveStep(activeStep - 1)
                      }
                    }}
                  > Back</button>
                }
                {((isEditUser !== true || !isNewUser) && activeStep === 2) ?
                  <button type='button' className='inline-flex items-center bg-primary min-w-[90px] justify-center text-white text-sm px-2 py-2 rounded-lg'
                    disabled={diabledCreateButton}
                    onClick={(e) => { 
                    setDisabledCreateButton(true);
                    createMemberEntry() }}
                  > Finish</button>
                  :
                  <button 
                    className='inline-flex items-center bg-primary min-w-[90px] justify-center text-white text-sm px-2 py-2 rounded-lg'
                    onClick={() => { goToNextPage() }}
                  >{isEditUser === true ? "Update" : "Save"} & Next</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddMember