import { Description, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { Icon } from '@iconify/react';

const Modal = ({ open, setOpen, title = '', description = '', children, className }) => {

    return (
        <>
            <Transition
                show={open}
            >
                <Dialog open={open} onClose={() => { }} className="relative z-50 transition">
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <TransitionChild
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                            <DialogPanel className={`max-w-sm w-full relative space-y-4 bg-white p-8 ${className}`}>
                                <div className='absolute top-2 right-3 inline-block cursor-pointer' onClick={() => { setOpen(false) }}>
                                    <Icon icon="carbon:close" fontSize="30px" className='text-gray300' />
                                </div>
                                {
                                    title && <DialogTitle className="font-semibold text-gray300 text-lg">{title}</DialogTitle>
                                }
                                {
                                    description && <Description>{description}</Description>
                                }
                                <div>{children}</div>
                            </DialogPanel>
                        </div>
                    </TransitionChild>
                </Dialog>
            </Transition>
        </>
    )
}

export default Modal