import React, { useEffect, useMemo, useState } from "react";
import LoaderBar from "../../Common/LoaderBar";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { s3Url } from "../../../services/api.service";
import moment from "moment";
import { Icon } from "@iconify/react";
import * as XLSX from 'xlsx';

import { toastr } from "../../../services/common.service";
import MemberSearchPannel from "../../attendance/memberlist/MemberSearchPannel";
import { downloadCsvFile } from "../../attendance/memberlist/actionCreator";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const visibility = {
  'SMVNo': true,
  'profileImgUrl': true,
  'fullName': true,
  'yuvaAttd': true,
  'atmiyaAttd': true,
  'address': false,
  'mobile': true,
  'city': false,
  'state': false,
  'country': false,
  'birthDate': false,
  'bloodGroup': false,
  'gender': false,
  'village': true,
}



const MemberTable = (props) => {
  const {
    isLoading,
    filteredMemberList,
    handleCollapsePofile,
    totalPages,
    memberDetails,
    setTableData,
    setPageSize,
    pageSize,
    currentPage,
    setCurrentPage,
    _memberFilters,
    setMemberFilters,
    toggleSort,
    downloadProfilePhotos,
    disablePaginationButton,
  } = props;
  const [data, setData] = useState([]);
  const [columnPinning, setColumnPinning] = useState({
    right: ["action"],
  });

  const generateQueryParams = (filter) => {
    const queryParams = {};
    for (const key in filter) {
      if (key === 'gender') {
        queryParams[key] = filter[key].value;
      } else if (key === 'isActive') {
        if (filter[key] === 'Active') {
          queryParams[key] = true;
        } else if (filter[key] === 'InActive') {
          queryParams[key] = false;
        }
      }
      else if (Array.isArray(filter[key])) {
        let arrVal = filter[key].map(item => item.value)
        if (arrVal.length > 0) {
          queryParams[key] = arrVal.join(',');
        }
      }
      else if (key === 'birthMonth') {
        queryParams[key] = filter[key]?.value;
      } 
      else {
        queryParams[key] = filter[key];
      }
    }
    return queryParams;
  }
  const [columnVisibility, setColumnVisibility] = useState(visibility);

  const getDownloadCsvFile = async (downloadType = 'XLSX') => {
    try {
      const queryParams = generateQueryParams(_memberFilters);
      const response = await downloadCsvFile(queryParams);
      if (response?.data?.length) {
        const filteredMembers = response?.data;
        const visibleFields = Object.keys(columnVisibility).filter(field => columnVisibility[field]);
        const filterFields = filteredMembers.map(member => {
          let filteredMember = {};
            visibleFields.forEach(field => {
                // if (['yuvaAttd', 'atmiyaAttd', 'action'].includes(field)) {
                if ([ 'atmiyaAttd', 'action'].includes(field)) {
                    return;
                }
                
                if (field === 'fullName') {
                    filteredMember[field] = (member.firstName ? member.firstName : "") +
                                             (member.middleName ? " " + member.middleName : "") +
                                             (member.lastName ? " " + member.lastName : "");
                } else if (field === 'birthDate') {
                  filteredMember[field] = moment(member[field]).format("DD-MM-YYYY");
                } else if (field === 'profileImgUrl') {
                  filteredMember['Profile Image'] = member[field] ? `${s3Url}${member[field]}` : "";
                } else if (field === 'city') {
                  filteredMember[field] = member['address']?.city ?? "";
                } else if (field === 'state') {
                  filteredMember[field] = member['address']?.state ?? "";
                } else if (field === 'country') {
                  filteredMember[field] = member['address']?.country ?? "";
                } else if (field === 'address') {
                  filteredMember[field] = (member['address']?.blockNo ? (member['address'].blockNo + ", ") : "") + "" + (member['address']?.society ?? "");
                } else if(field === 'yuvaAttd' && member.attendeeOf) {
                    const yuvaSabha = member.attendeeOf.find(sabha => sabha.sabhaName === 'Yuva Sabha');
                    if (yuvaSabha && yuvaSabha.recentAttendance.length > 0) {
                        yuvaSabha.recentAttendance.forEach(att => {
                            const attDate = moment(att.date).format("DD-MM-YYYY");
                            filteredMember[attDate] = att.present ? 'Present' : 'Absent';
                          });
                    }
                } else {
                  filteredMember[field] = member[field];
                }
            });
            return filteredMember;
        });

        if (downloadType === "pdf") {
          const doc = new jsPDF({orientation: 'landscape'});

          // Prepare the table data
          const tableColumn = visibleFields.filter(field => field !== 'profileImgUrl');
          const tableRows = [];
          
          // return;
          filterFields.forEach(item => {
            const rowData = [];
            tableColumn.forEach((column) => {
              rowData.push(item[column] ?? "");
            });
            tableRows.push(rowData);
          });
  
          // Add the table to the PDF
          doc.autoTable(tableColumn, tableRows, { startY: 5 });
  
          // Generate the PDF as a Blob and create a URL
          const pdfBlob = doc.output('blob');
          const url = URL.createObjectURL(pdfBlob);
  
          // Create an anchor element and trigger a download
          const a = document.createElement('a');
          a.href = url;
          a.download = 'sabhy.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  
          // Revoke the URL after the download
          URL.revokeObjectURL(url);
        } else {
          const worksheet = XLSX.utils.json_to_sheet(filterFields);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Members');
          XLSX.writeFile(workbook, 'members_list.xlsx');
        }
        toastr.show("Successfully Download", "success");
      } else {
        toastr.show("No data found", "warning");
      }
    } catch (err) {
      console.log("err===========", err)
      toastr.show("Failed to download Excel Data.", "error");
    }
  };

  const DragAlongCell = ({ cell, trID }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
      id: cell.column.id,
    });

    const style = {
      opacity: isDragging ? 0.8 : 1,
      position: "relative",
      transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
      transition: "width transform 0.2s ease-in-out",
      width: cell.column.getSize(),
      zIndex: isDragging ? 1 : 0,
      padding: "8px",
    };

    return (
      <td
        style={style}
        ref={setNodeRef}
        className={`${trID === memberDetails?._id ? 'bg-[#edf2fb] ' : ''} `}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "SMVNo",
        header: () => <span>SMV NO.</span>,
        cell: (info) => info.getValue(),
        id: "SMVNo",
        size: 150,
        maxSize: 150,

      },
      {
        accessorKey: "profileImgUrl",
        header: () => <span>Photo</span>,
        cell: (info) => {
          return (
            <img
              className=" min-w-[48px] max-w-[48px] h-12 rounded-full mr-3 object-cover"
              alt="profile"
              src={
                info.row.original.profileImgUrl
                  ? `${s3Url}${info.row.original.profileImgUrl}`
                  : info.row.original?.gender === 'female' ? "/assets/image/female-avatar.jpg" : "/assets/image/profilepicture.png"
              }
            />
          )
        },
        id: "profileImgUrl",
        size: 150,
      },
      {
        accessorKey: "fullName",
        header: () => <span>Name.</span>,
        cell: (info) => (
          <div className="">
            {info.row.original?.firstName} {info.row.original?.middleName}{" "}
            {info.row.original?.lastName}
          </div>
        ),
        id: "fullName",
      },
      {
        accessorKey: "mobile",
        cell: (info) => info.getValue(),
        id: "mobile",
        size: 150,
      },
      {
        accessorKey: "yuvaAttd",
        header: () => <span>Yuvasabha Attd.</span>,
        cell: (info) => {
          const yuvaSabhaDetails = info.row.original?.attendeeOf?.find((sabha) => sabha.sabhaName === "Yuva Sabha");
          return (
            <>
              {
                yuvaSabhaDetails?.recentAttendance?.map((_attn, index) => {
                  return (
                    <>
                      <Icon key={"yuva" + info.row.original?._id + index} size={"10px"} className={`inline mx-0.5 ${_attn?.present ? "text-[#16C046]" : "text-red-600"}`} icon="material-symbols:circle" />
                    </>
                  )
                })
              }
            </>
          )
        },
        id: "yuvaAttd",
        size: 150,
      },
      {
        accessorKey: "atmiyaAttd",
        header: () => <span>Atmiyasabha Attd.</span>,
        cell: (info) => {
          const atmiyaSabhaDetails = info.row.original?.attendeeOf?.find((sabha) => sabha.sabhaName === "Atmiya Sabha");

          return (
            <>
              {
                atmiyaSabhaDetails?.recentAttendance?.map((_attn, index) => {
                  return (
                    <>
                      <Icon key={"yuva" + info.row.original?._id + index} size={"10px"} className={`inline mx-0.5 ${_attn?.present ? "text-[#16C046]" : "text-red-600"}`} icon="material-symbols:circle" />

                    </>
                  )
                })
              }
            </>
          )
        },
        id: "atmiyaAttd",
        size: 150,
      },
      {
        accessorKey: "address",
        cell: (info) => info.row.original.address?.society,
        id: "address",
        size: 150,
      },
      {
        accessorKey: "city",
        cell: (info) => info.row.original?.address?.city,
        id: "city",
        size: 150,
      },
      {
        accessorKey: "state",
        cell: (info) => info.row.original?.address?.state,
        id: "state",
        size: 150,
      },
      {
        accessorKey: "country",
        cell: (info) => info.row.original?.address?.country,
        id: "country",
        size: 150,
      },
      {
        accessorKey: "birthDate",
        cell: (info) => info.getValue() ? moment(info.getValue()).format("DD-MM-YYYY") : '-',
        id: "birthDate",
        size: 150,
      },
      {
        accessorKey: "bloodGroup",
        cell: (info) => info.row.original?.bloodGroup,
        id: "bloodGroup",
        size: 150,
      },
      {
        accessorKey: "gender",
        cell: (info) => info.row.original?.gender,
        id: "gender",
        size: 150,
      },
      {
        accessorKey: "village",
        cell: (info) => info.row.original?.village,
        id: "village",
        size: 150,
      },
    ],
    []
  );

  const [columnOrder, setColumnOrder] = useState(() =>
    columns?.map((c) => c.id)
  );

  const getTable = async () => {
    if (table !== undefined) {
      setTableData(table);
    }
  };

  useEffect(() => {
    setData(filteredMemberList || []);
    getTable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMemberList]);

  const handlePageChange = (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
        break;

      case "next":
        if (currentPage < totalPages) {
          setCurrentPage((prev) => prev + 1);
        }
        break;

      default:
        break;
    }
  }

  const DraggableTableHeader = ({ header }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } =
      useSortable({
        id: header.column.id,
      });

    const style = {
      opacity: isDragging ? 0.8 : 1,
      position: "relative",
      transform: CSS.Translate.toString(transform),
      transition: "width transform 0.2s ease-in-out",
      whiteSpace: "nowrap",
      width: header.column.getSize(),
      zIndex: isDragging ? 1 : 0,
    };


    return (
      <th
        colSpan={header.colSpan}
        className="text-left font-semibold p-2 last:text-center last:!sticky last:w-[180px] last:z-10 last:!right-0 bg-[#F2F2F2]"
        ref={setNodeRef}
        style={style}
      >
        {header.isPlaceholder ? null : (
          <div className={`flex items-center ${header?.id === "action" ? 'justify-center' : ''}`}>
            <span {...attributes} {...listeners} className="cursor-grab">
              {flexRender(header.column.columnDef.header, header.getContext())}
            </span>
            {
              header.column.columnDef.id === "SMVNo"
                ? <div onClick={() => { toggleSort('SMVNo') }}>
                  <Icon icon="mdi:sort" className="ml-2 text-lg cursor-pointer" />
                </div>
                : header.column.columnDef.id === "birthDate" ? (
                  <div onClick={() => { toggleSort('birthDate') }}>
                    <Icon icon="mdi:sort" className="ml-2 text-lg cursor-pointer" />
                  </div>
                ) 
                : ""
            }
          </div>
        )}
      </th>
    );
  };

  const table = useReactTable({
    data,
    columns,
    initialState: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
    },
    // getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
  });

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  // const generatePDF = () => { 
  //   const visibleColumns = table.getVisibleFlatColumns().map(item => item)
  //   console.log("🚀 ~ generatePDF ~ visibleColumns:", visibleColumns)

  //   // let headers = columns.map((column) => column.id);
  //   const visibleData = table.getRowModel().rows.map((row) => {
  //     const rowObject = {}; // Create an object for each row
  
  //     visibleColumns.forEach((column) => {
  //       rowObject[column.id] = row.original[column.id]; // Get value for each visible column
  //     });
  
  //     return rowObject;
  //   });
  //   console.log(visibleData,"visibleDatavisibleData")
  //   PdfGenerator(visibleColumns,visibleColumns)
  //  }

  return (
    <>
      {isLoading && <LoaderBar />}
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div className="px-2 pb-0">
          <div className={`flex`}>
            <MemberSearchPannel _memberFilters={_memberFilters} table={table} setMemberFilters={setMemberFilters}  downloadProfilePhotos={downloadProfilePhotos} downloadCsvFile={getDownloadCsvFile} isDownloadOption={true} />
          </div>
          {/* <button onClick={generatePDF}>Export to PDF</button> */}
          <div className="h-4" />
          <div className="overflow-auto h-[calc(100vh_-_275px)]">
            <table className="w-full member-table">
              <thead className="sticky top-0 bg-white z-10">
                {table.getHeaderGroups()?.map((headerGroup) => (
                  <tr
                    className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]"
                    key={headerGroup.id}
                  >
                    <SortableContext
                      items={columnOrder}
                      strategy={horizontalListSortingStrategy}
                    >
                      {headerGroup.headers?.map((header) => (
                        <DraggableTableHeader key={header.id} header={header} />
                      ))}
                    </SortableContext>
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel()?.rows?.map((row) => {
                  const yuvaSabhaDetails = row?.original?.attendeeOf?.find(
                    (sabha) => sabha.sabhaName === "Yuva Sabha"
                  );
                  const atmiyaSabhaDetails = row?.original?.attendeeOf?.find(
                    (sabha) => sabha.sabhaName === "Atmiya Sabha"
                  );

                  return (
                    <tr
                      key={row.id}
                      className={`border-l-4 border-b-gray-200 text-sm ${yuvaSabhaDetails?.isLeader || atmiyaSabhaDetails?.isLeader
                        ? "!bg-orange-200 !bg-opacity-20"
                        : "bg-white"
                        }
                        ${memberDetails?._id === row?.original?._id ? ' border-[#abc4ff]' : 'border-l-white'}
                        `}
                      onClick={() => {
                        handleCollapsePofile(row?.original);
                      }}
                    >
                      {row.getVisibleCells()?.map((cell) => (
                        <SortableContext
                          key={cell.id}
                          items={columnOrder}
                          strategy={horizontalListSortingStrategy}
                        >
                          <DragAlongCell key={cell.id} cell={cell} trID={row?.original?._id} />
                        </SortableContext>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-end gap-2 mt-2 sticky bottom-0 p-3 bg-white">
            <button
              className="border rounded w-8 h-8 grid place-items-center"
              onClick={() => handlePageChange('prev')}
              disabled={currentPage === 1 || disablePaginationButton}
            >

              <Icon icon="lucide:chevron-left" className="text-xl" />
            </button>
            <button
              className="border rounded w-8 h-8 grid place-items-center"
              onClick={() => handlePageChange('next')}
              disabled={currentPage === totalPages || disablePaginationButton}
            >
              <Icon icon="lucide:chevron-right" className="text-xl" />
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {currentPage} of{" "}
                {totalPages}
              </strong>
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="outline-none p-1 border rounded "
            >
              {[10, 20, 30, 40, 50].map((page) => (
                <option key={page} value={page}>
                  Show {page}
                </option>
              ))}
            </select>
          </div>
        </div>
      </DndContext>

    </>
  );
};

export default MemberTable;
