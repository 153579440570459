import { Step, Stepper, Typography } from "@material-tailwind/react";
import React from "react";
 
const  FormStepper = (props) => {
  const {activeStepProps=0, isEditUser, isNewUser = false} = props
  const [activeStep, setActiveStep] = React.useState(activeStepProps);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
 
  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);
 
  return (
    <div className="w-full px-24 py-4">
      {isEditUser === true || !isNewUser ? 
        <Stepper
        activeStep={activeStepProps}
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
      >
        <Step onClick={() => setActiveStep(0)}>
          1
          <div className="absolute -bottom-[2.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 0 ? "blue-gray" : "gray"}
            >
              Basic Information
            </Typography>
          </div>
        </Step>
        <Step onClick={() => setActiveStep(1)}>
          2
          <div className="absolute -bottom-[2.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 1 ? "blue-gray" : "gray"}
            >
             Other Info
            </Typography>

          </div>
        </Step>
        <Step onClick={() => setActiveStep(2)}>
         3
          <div className="absolute -bottom-[2.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 2 ? "blue-gray" : "gray"}
            >
              Upload Photo
            </Typography>
          </div>
        </Step>
        {/* <Step onClick={() => setActiveStep(3)}>
         4
          <div className="absolute -bottom-[2.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 3 ? "blue-gray" : "gray"}
            >
              Group Info
            </Typography>
          </div>
        </Step> */}
      </Stepper>
      : <Stepper
        activeStep={activeStepProps}
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
      >
        <Step onClick={() => setActiveStep(0)}>
          1
          <div className="absolute -bottom-[2.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 0 ? "blue-gray" : "gray"}
            >
              Basic Information
            </Typography>
          </div>
        </Step>
        <Step onClick={() => setActiveStep(1)}>
          2
          <div className="absolute -bottom-[2.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 1 ? "blue-gray" : "gray"}
            >
             Other Info
            </Typography>

          </div>
        </Step>
        <Step onClick={() => setActiveStep(2)}>
         3
          <div className="absolute -bottom-[2.5rem] w-max text-center">
            <Typography
              variant="h6"
              color={activeStep === 2 ? "blue-gray" : "gray"}
            >
              Upload Photo
            </Typography>
          </div>
        </Step>
      </Stepper>}
    </div>
  );
}

export default FormStepper