import React, { useState } from "react";
import Icon from "@mdi/react";
import { mdiDelete, mdiContentSave } from "@mdi/js";

import DeleteModal from "../../Common/DeleteModal/DeleteModal";
import DeptGroupSelection from "./DeptGroupSelection";
import { deleteDepartment, updateDepartment } from "./actionCreator";
import { toastr } from "../../../services/common.service";

const DepartmentTable = (props) => {
  const {
    indx,
    department,
    getDepartmentsFun,
    fullDepartmentList,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [departmentGroups, setDepartmentGroups] = useState([]);

  const handleCloseModal = (isRemove) => {
    if (isRemove) {
      removeDepartmentEntry(department._id);
    }
    setShowDeleteModal(false);
  };

  const handleOpenDeleteModal = (e) => {
    e.stopPropagation();
    setShowDeleteModal(!showDeleteModal);
  };

  const removeDepartmentEntry = async (saintId) => {
    const result = await deleteDepartment(saintId);
    if (result?.data) {
      toastr.show("Success !!", "success");
      getDepartmentsFun();
    }
  };

  const updateDepartmentEntry = async (e) => {
    e.stopPropagation();
    try {
      const departmentData = {
        _id: department._id,
        departmentGroups: departmentGroups,
      }
      const result = await updateDepartment(departmentData);
      if (result.data) {
        toastr.show("Success !!", "success");
        setDepartmentGroups(result.data.departmentGroups || []);
      }
      getDepartmentsFun();
    } catch (err) {
      toastr.show("Failed !!", "error");
    }
  };

  return (
    <>
      <tr
        key={indx}
        className="border-b border-stone-200 text-sm"
      >
        <td className="p-2">{department?.departmentName || "-"}</td>
        <td className="p-2">{department?.sabhaName || "-"}</td>
        <td className="p-2" onClick={(e) => e.stopPropagation()}>
          <DeptGroupSelection
            department={department}
            setDepartmentGroups={setDepartmentGroups}
            departmentGroups={departmentGroups}
            fullDepartmentList={fullDepartmentList}
          />
        </td>
        <td className="p-2 text-center">
          <div className="flex justify-center items-center gap-2">
            <button className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer hover:border-black900 group hover:text-black900" onClick={(e) => updateDepartmentEntry(e)}>
              <Icon
                path={mdiContentSave}
                size={1}
                className="inline mx-0.5 text-[#000000]"
              />
            </button>
            <button
              className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center cursor-pointer hover:border-black900 group hover:text-black900"
              onClick={(e) => handleOpenDeleteModal(e)}
            >
              <Icon
                path={mdiDelete}
                size={1}
                className="inline mx-0.5 text-[#000000]"
              />
            </button>
          </div>
        </td>
      </tr>
      <DeleteModal open={showDeleteModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default DepartmentTable;
