import React, { Fragment, useState } from "react";
import Icon from "@mdi/react";
import {
  Button,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import { mdiClose } from "@mdi/js";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import moment from "moment-timezone";
import DateField from "../Common/Input/DateField";
import UploadItems from "../Common/UploadModal/UploadItems";
import DatePickerField from "../Common/Rsuite-Datepicker";

const DailyDarshanUpload = (props) => {
  const { open, setOpen, uploadFiles, selectedDate, setImageType, imageType } =
    props;
  const [activeTab, setActiveTab] = useState(0);
  const [files, setFiles] = useState([]);
  const [darshanDate, setDarshanDate] = useState(
    moment(selectedDate).startOf("day").toISOString()
  );

  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          open={open}
          as="div"
          className="relative z-40"
          onClose={() => {}}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      Upload Daily Darshan
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setFiles([]);
                        setImageType("hd");
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow">
                          <div className="">
                            <div className="flex items-start flex-col mb-6">
                              <div className="w-full">
                                <Tabs
                                  value={activeTab}
                                  onChange={handleTabChange}
                                >
                                  <div className="flex justify-between items-center">
                                    <TabsHeader className="w-fit">
                                      <Tab
                                        value={0}
                                        className="w-[130px]"
                                        onClick={() => setImageType("hd")}
                                      >
                                        HD
                                      </Tab>
                                      <Tab
                                        value={1}
                                        className="max-w-[130px]"
                                        onClick={() => setImageType("compress")}
                                      >
                                        Compresed
                                      </Tab>
                                    </TabsHeader>
                                    <div>
                                      {/* <DateField
                                        id="13"
                                        className="!h-9 placeholder:text-black900 outline-none"
                                        placeholder="Darshan Date"
                                        value={
                                          darshanDate
                                            ? moment(darshanDate).format(
                                                "DD/MM/YYYY"
                                              )
                                            : ""
                                        }
                                        onChange={(_date) =>
                                          setDarshanDate(
                                            moment(_date)
                                              .startOf("day")
                                              .toISOString()
                                          )
                                        }
                                        timeFormat={false}
                                      /> */}
                                      <DatePickerField
                                        placeholder="Darshan Date"
                                        value={
                                          darshanDate
                                            ? new Date(darshanDate)
                                            : ""
                                        }
                                        onChange={(_date) =>
                                          setDarshanDate(
                                            moment(_date)
                                              .startOf("day")
                                              .toISOString()
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <TabsBody className="w-full">
                                    <TabPanel value={0} className="px-0">
                                      <UploadItems
                                        setFiles={setFiles}
                                        files={files}
                                      />
                                    </TabPanel>
                                    <TabPanel value={1} className="px-0">
                                      <UploadItems
                                        setFiles={setFiles}
                                        files={files}
                                      />
                                    </TabPanel>
                                  </TabsBody>
                                </Tabs>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => {
                            setFiles([]);
                            setOpen(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() => {
                            setFiles([]);
                            uploadFiles && uploadFiles(files, darshanDate);
                          }}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DailyDarshanUpload;
