import React from 'react';
import { mdiArrowCollapseRight, mdiTune } from '@mdi/js'
import Icon from '@mdi/react'
import { FilterAccordion } from './FilterAccordion'
import { FILTER_TYPES } from '../../../constants/filter-constants';
import { toastr } from '../../../services/common.service';

const Filterbar = (props) => {
    const { handleClose, _memberFilters, setMemberFilters, setFilteredMemberList, setTotalPages, fetchMember, setApplyFilter } = props;

    const handleApplyFilter = () => {
        const AppliedFilters = Object.keys(_memberFilters).filter((d) => {
          if (FILTER_TYPES[d] && Array.isArray(_memberFilters[d])) {
            return _memberFilters[d].length
          }
          else if (FILTER_TYPES[d] && _memberFilters[d] instanceof Object) {
            return _memberFilters[d].value
          } else {
            return _memberFilters[d]
          }
        }
        );
        setApplyFilter(AppliedFilters)
      }

    const handleApply = async () => {
        if (_memberFilters.minAge < 0 || _memberFilters.maxAge < 0) {
          toastr.show('Please enter positive integer', 'error');
        } else if ((_memberFilters.minAge && _memberFilters.maxAge) && (_memberFilters.minAge > _memberFilters.maxAge)) {
          toastr.show('Min age must be loawer than Max age', 'error');
        } else {
          await fetchMember(_memberFilters);
          handleApplyFilter();
          handleClose();
    
        }
      }

    return (
      <>
        <div
          className={`right-0 border-l z-30 border-stone-200 fixed inset-y-0 w-2/4 bg-white ease-out duration-[0.3s]`}
        >
          <div className="h-full">
            <div className="border-b border-stone-200 py-1.5 px-3 items-center flex justify-between h-12 sticky shadow-lg">
              <div>
                <span className="inline-flex items-center text-lg  px-2 py-1 rounded">
                  <Icon path={mdiTune} size={0.8} className="inline mr-1.5" />{" "}
                  Filter{" "}
                </span>
              </div>
              <div className='flex items-center'>
                <div className="flex justify-end py-3">
                    <button
                    className="text-white/90 group inline-flex items-center rounded-md bg-primary px-3 py-1.5 text-sm font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
                    onClick={handleApply}
                    >
                    Apply
                    </button>
                </div>
                <div className="px-2 select-none ml-3" onClick={() => handleClose()}>
                    <Icon
                    path={mdiArrowCollapseRight}
                    size={0.9}
                    className="text-stone-600 cursor-pointer"
                    />
                </div>
              </div>
            </div>
            <div className="p-4 h-[calc(100vh_-_50px)] grid grid-cols-2 gap-x-4 gap-y-0 overflow-auto">
              <FilterAccordion
                _memberFilters={_memberFilters}
                setMemberFilters={setMemberFilters}
                setFilteredMemberList={setFilteredMemberList}
                handleClose={handleClose}
                setTotalPages={setTotalPages}
                fetchMember={fetchMember}
                setApplyFilter={setApplyFilter}
                handleApply={handleApply}
              />
            </div>
          </div>
        </div>
        <div
          className="fixed inset-0 bg-black  z-20 bg-opacity-50 backdrop-blur-[2px]"
          onClick={() => handleClose()}
        ></div>
      </>
    );
}

export default Filterbar