import React, { useEffect, useState } from "react";
import { Checkbox } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import lodash from "lodash";
import { useSelector } from "react-redux";

import {
  GROUP_NUMBERS,
  LEADER_PRIORITY,
  SABHA_TYPES,
} from "../../../../constants/common.constants";
import CreatableMultiselectMain from "../../../Common/Input/CreatableMultiselectMain";
import { toastr } from "../../../../services/common.service";
import { updateAttendeeOf } from "../actionCreator";
import { useDispatch } from "react-redux";
import { ACTION_TYPES } from "../../../../redux/constants";

const GroupInfo = (props) => {
  const { attendeeOf, profileData, isReadable = false } = props;
  const dispatch = useDispatch();

  const [isCollapsed, setIsCollapsed] = useState({});
  const [sabhaGroups, setSabhaGroups] = useState(attendeeOf);
  const { HODList: Departments } = useSelector(
    (state) => state.SaintLeaderReducer
  );

  useEffect(() => {
    setSabhaGroups(attendeeOf);
  }, [attendeeOf]);

  const DepartmentsList = Departments.map((dep) => ({
    label: dep.departmentName,
    value: dep._id,
    sabhaId: dep.sabhaId,
  }));

  const handleCollapse = (tabKey) => {
    setIsCollapsed((prevState) => ({
      ...prevState,
      [tabKey]: !prevState[tabKey],
    }));
  };

  const handleSabhaAddRemove = (sabhaDetail) => {
    if (!sabhaDetail) return;

    let _sabhaGroups = lodash.cloneDeep(sabhaGroups);
    let isAttendingSabha = sabhaGroups?.find(
      (sabha) => sabha.sabhaId === sabhaDetail._id
    );

    if (!isAttendingSabha) {
      _sabhaGroups.push({
        sabhaId: sabhaDetail._id,
        sabhaName: sabhaDetail.name,
      });
    } else {
      _sabhaGroups = _sabhaGroups.filter(
        (sabha) => sabha.sabhaId !== sabhaDetail._id
      );
    }
    setSabhaGroups(_sabhaGroups);
  };

  const handleSabhaEdits = (sabhaDetail, field, value) => {
    const _sabhaGroups = lodash.cloneDeep(sabhaGroups);

    if (field) {
      _sabhaGroups.forEach((d) => {
        if (d.sabhaId === sabhaDetail._id) {
          d[field] = value;
        }
      });
      setSabhaGroups(_sabhaGroups);
    }
  };

  const handleSabhaUpdate = async (attendeeDetails, eachSabha) => {
    try {
      let reqPayload = { userId: profileData._id };

      if (attendeeDetails) {
        reqPayload = { ...reqPayload, ...attendeeDetails };
      }
      if (!attendeeDetails) {
        reqPayload = {
          ...reqPayload,
          sabhaId: eachSabha._id,
          sabhaName: eachSabha.name,
          action: "REMOVE",
        };
      }

      const response = await updateAttendeeOf(reqPayload);

      dispatch({
        type: ACTION_TYPES.UPDATE_NEW_MEMBER,
        payload: response.data,
      });

      toastr.show("Success!!", "success");
    } catch (err) {
      toastr.show(
        err?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-3">
        {SABHA_TYPES.map((eachSabha, indx) => {
          const attendeeDetails = sabhaGroups.find(
            (d) => d.sabhaId === eachSabha._id
          );
          const DepartmentsOptions = DepartmentsList.filter(
            (dept) => dept.sabhaId === eachSabha._id
          );

          return (
            <div>
              <div
                className="px-2 py-0.25 flex items-center justify-between bg-gray200 rounded-lg cursor-pointer select-none"
                onClick={() => handleCollapse(eachSabha._id)}
              >
                <div className="flex items-center">
                  <div
                    className="inline-block ml-3"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Checkbox
                      id={eachSabha.name + indx}
                      checked={attendeeDetails ? true : false}
                      onChange={() => handleSabhaAddRemove(eachSabha)}
                    />
                  </div>
                  <div className="font-semibold ml-2">{eachSabha.name}</div>
                </div>
                <Icon
                  icon={
                    isCollapsed[eachSabha?._id]
                      ? "mdi:chevron-down"
                      : "mdi:chevron-up"
                  }
                  size="32px"
                  className={`text-black900 transition-all duration-150 mr-3 ${
                    isCollapsed ? "rotate-180" : "rotate-0"
                  }`}
                  fontSize={20}
                />
              </div>

              <div className={`p-4 ${!isCollapsed[eachSabha._id] && "hidden"}`}>
                <div className="flex items-center">
                  <CreatableMultiselectMain
                    id="2"
                    minWidth="180px"
                    maxWidth="180px"
                    className="!h-9 placeholder:text-black900"
                    required={true}
                    placeholder="Group Number"
                    options={GROUP_NUMBERS}
                    value={createSelectValueFromString(
                      attendeeDetails?.groupNo
                    )}
                    isDisabled={!attendeeDetails || isReadable}
                    onChange={(value) =>
                      handleSabhaEdits(eachSabha, "groupNo", value?.value)
                    }
                  />
                  <button
                    onClick={() =>
                      handleSabhaUpdate(attendeeDetails, eachSabha)
                    }
                  >
                    <a className="rounded-xl bg-transparent border-blue-500 border text-blue-500 px-4 py-1.5">
                      save
                    </a>

                    {/* <Icon
                      icon="mdi:content-save"
                      className="cursor-pointer text-primary mx-2"
                      fontSize={25}
                    /> */}
                  </button>
                </div>
                <div className="grid grid-cols-2 pt-2">
                  <div>
                    <Checkbox
                      id={"leader" + indx}
                      label="Group Leader"
                      checked={attendeeDetails?.isLeader || false}
                      disabled={!attendeeDetails || isReadable}
                      onChange={(value) =>
                        handleSabhaEdits(
                          eachSabha,
                          "isLeader",
                          value?.target?.checked
                        )
                      }
                    />
                  </div>
                  <div className="p-1">
                    <CreatableMultiselectMain
                      minWidth="175px"
                      id="2"
                      className="!h-9 placeholder:text-black900"
                      required={true}
                      placeholder="Leader Priority"
                      options={LEADER_PRIORITY}
                      value={createSelectValueFromString(
                        attendeeDetails?.priority
                      )}
                      isDisabled={!attendeeDetails?.isLeader || isReadable}
                      onChange={(value) =>
                        handleSabhaEdits(eachSabha, "priority", value?.value)
                      }
                    />
                  </div>
                  <div>
                    <Checkbox
                      id={"holleader" + indx}
                      label="Head of Leader (HOL)"
                      checked={attendeeDetails?.isHeadOfLeaders || false}
                      disabled={!attendeeDetails || isReadable}
                      onChange={(value) =>
                        handleSabhaEdits(
                          eachSabha,
                          "isHeadOfLeaders",
                          value?.target?.checked
                        )
                      }
                    />
                  </div>
                  <div className="p-1">
                    <CreatableMultiselectMain
                      minWidth="175px"
                      id="2"
                      className="!h-9 placeholder:text-black900"
                      required={true}
                      placeholder="Department"
                      options={DepartmentsOptions}
                      value={
                        DepartmentsOptions.find(
                          (opt) =>
                            attendeeDetails?.isHeadOfLeaders &&
                            opt.value === attendeeDetails?.department
                        ) || null
                      }
                      isDisabled={
                        !attendeeDetails?.isHeadOfLeaders || isReadable
                      }
                      onChange={(value) =>
                        handleSabhaEdits(eachSabha, "department", value?.value)
                      }
                    />
                  </div>
                  <div>
                    <Checkbox
                      id={"hodleader" + indx}
                      label="Head Of Department (HOD)"
                      checked={attendeeDetails?.isHeadOfDepartment || false}
                      disabled={!attendeeDetails || isReadable}
                      onChange={(value) =>
                        handleSabhaEdits(
                          eachSabha,
                          "isHeadOfDepartment",
                          value?.target?.checked
                        )
                      }
                    />
                  </div>
                  <div className="p-1">
                    <CreatableMultiselectMain
                      minWidth="175px"
                      id="2"
                      className="!h-9 placeholder:text-black900"
                      required={true}
                      placeholder="Department"
                      options={DepartmentsOptions}
                      value={
                        DepartmentsOptions.find(
                          (opt) =>
                            attendeeDetails?.isHeadOfDepartment &&
                            opt.value === attendeeDetails?.department
                        ) || null
                      }
                      isDisabled={
                        !attendeeDetails?.isHeadOfDepartment || isReadable
                      }
                      onChange={(value) =>
                        handleSabhaEdits(eachSabha, "department", value?.value)
                      }
                    />
                  </div>
                  <div>
                    <Checkbox
                      id={"hosleader" + indx}
                      label="Head of Sabha (HOS)"
                      checked={attendeeDetails?.isHeadOfSabha || false}
                      disabled={!attendeeDetails || isReadable}
                      onChange={(value) =>
                        handleSabhaEdits(
                          eachSabha,
                          "isHeadOfSabha",
                          value?.target?.checked
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const createSelectValueFromString = (stringVal) => {
  return stringVal
    ? {
        label: stringVal,
        value: stringVal,
      }
    : null;
};

export default GroupInfo;
