import { useRoutes } from "react-router-dom"
import { Album, EditAlbum } from "../../../Components"

export default function AudioRouter() {
    const audioRouter = [
        
        {
            path: "/album/*",
            element: (
                <>
                    <Album />
                </>
            )
        },
        {
            path: "/album/:id/*",
            element: (
                <>
                    <EditAlbum />
                </>
            )
        },
    ]

    const AudioRouter = useRoutes(audioRouter)

    return AudioRouter
}