import { api } from "../../../services/api.service";

export const getPlayList = async () => {
    const response = await api.get("admin/album/allAlbumlist");
    return response;
}

export const createAlbum = async (formData) => {
    const response = await api.post("admin/album/create", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }});
    return response;
}

export const updateAlbum = async (data) => {
    const response = await api.post("admin/album/update", data);
    return response;
}

export const updateAlbumWithMedia = async (formData) => {
    const response = await api.post("admin/album/update", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }});
    return response;
}

export const deleteAlbum = async (id) => {
    const response = await api.post("admin/album/delete", { id });
    return response;
}

export const getAlbumListByID = async (id) => {
    const response = await api.get(`admin/album/${id}`);
    return response;
}

export const addAudio = async (formData) => {
    const response = await api.post(`admin/album/add-audio`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        maxContentLength: 100000,
        maxBodyLength: 100000,
    });

    return response;
}

export const updateAlbumAudio = async (data) => {
    const response = await api.post(`admin/album/update-audio`, data);
    return response;
}

export const updateAlbumAudioMedia = async (formData) => {
    const response = await api.post("admin/album/update-audio", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }});
    return response;
}

export const deleteAlbumAudio = async (data) => {
    const response = await api.post(`admin/album/delete-audio`, data);
    return response;
}

export const reorderAudio = async (data) => {
    const response = await api.post(`admin/album/reorder-audio`, data);
    return response;
}

export const changePlaylistVisibility = async (data) => {
    const response = await api.post(`admin/album/visibility`, data);
    return response;
}

export const changeAudioVisibility = async (data) => {
    const response = await api.post(`admin/album/audio/visibility`, data);
    return response;
}