import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import Textarea from "../../Common/Input/Textarea";
import { mdiClose } from "@mdi/js";
import DateField from "../../Common/Input/DateField";
import lodash from "lodash";
import { toastr } from "../../../services/common.service";
import { addVideoPlaylist, updateVideoPlaylist } from "./actionCreator";
import moment from "moment"

const AddVideoModal = ({ open, setOpen, playlistId, videos, setVideos, selectedVideo }) => {
  const [enabled, setEnabled] = useState(false);
  const [description, setDescription] = useState("");
  const [videoDetail, setVideoDetail] = useState(selectedVideo || {});
  const [tags, setTags] = useState("");
  const [date, setDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const setCurrentDetail = (key, value) => {
    const newVideoDetail = lodash.cloneDeep(videoDetail);
    newVideoDetail[key] = value;
    setVideoDetail(newVideoDetail);
  }

  const createVideo = async () => {
    setIsLoading(true);
    if (!videoDetail?.url_key) {
      toastr.show("Please enter Playlist Name", "error");
      return;
    }
    if (!videoDetail?.title) {
      toastr.show("Please enter Title", "error");
      return;
    }
    let newData = {
      url_key: videoDetail.url_key || null,
      title: videoDetail?.title || null,
      vakta: videoDetail?.vakta || "",
      tags: tags.split(','),
      description,
      visibility: enabled,
      playlistId,
    }
    if (date) {
      newData.date = moment(date, "DD/MM/YYYY").toISOString();
    }
    const response = await addVideoPlaylist(newData);
    if (response.data) {
      toastr.show("Successfully created!", "success");
      setVideos((prevVideos) => [...prevVideos, response.data]);
      setOpen(false);
      setCurrentDetail({})
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toastr.show("Something went wrong!", "error");
    }
  }

  const updateVideo = async () => {
    if (!videoDetail?.url_key) {
      toastr.show("Please enter URL key", "error");
      return;
    }
    if (!videoDetail?.title) {
      toastr.show("Please enter Title", "error");
      return;
    }
    let newData = {
      url_key: videoDetail.url_key || null,
      title: videoDetail?.title || null,
      vakta: videoDetail?.vakta || "",
      tags: tags.split(','),
      description,
      visibility: enabled,
      _id: videoDetail._id,
    }
    if (date) {
      newData.date = moment(date, "DD/MM/YYYY").toISOString();
    }
    const response = await updateVideoPlaylist(newData);
    if (response.data) {
      toastr.show("Successfully updated!", "success");
      const newList = videos
        .map(v => {
          if (v._id + "" === selectedVideo._id + "") {
            return { ...v, ...newData }
          }
          return { ...v };
        })
      setVideos(newList);
      setOpen(false);
      setCurrentDetail({})
    } else {
      toastr.show("Something went wrong!", "error");
    }
  }

  const resetForm = () => {
    setVideoDetail({});
    setEnabled(false);
    setTags("")
    setDate("")
    setDescription("");
  }

  useEffect(() => {
    setEnabled(selectedVideo?.visibility || false);
    setVideoDetail(selectedVideo);
    setDescription(selectedVideo?.description || '')
    setTags(selectedVideo.tags?.toString() ?? "")
    if (selectedVideo.date) setDate(moment(selectedVideo.date).format('DD/MM/YYYY'))
  }, [selectedVideo])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      {selectedVideo?._id ? "Update" : "Add"} Video
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="max-w-md w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div className="mb-5">
                                <Input
                                  label="URL"
                                  className=" w-full"
                                  value={videoDetail?.['url_key'] || ""}
                                  onChange={(e) => setCurrentDetail('url_key', e.target.value)}
                                />
                              </div>
                              <div className="mb-5">
                                <Input
                                  label="Title"
                                  className=" w-full"
                                  value={videoDetail?.['title'] || ""}
                                  onChange={(e) => setCurrentDetail('title', e.target.value)}
                                />
                              </div>
                              <div className="mb-5">
                                <Input
                                  label="Vakta"
                                  className=" w-full"
                                  value={videoDetail?.['vakta'] || ""}
                                  onChange={(e) => setCurrentDetail('vakta', e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <Textarea label="Description" className=" w-full" setDescription={setDescription} value={description} />
                              </div>
                              <div className="mb-3">
                                <Textarea label="Tags" className=" w-full" setDescription={setTags} value={tags} />
                              </div>
                              <div className="mb-5">
                                <DateField
                                  id="13"
                                  className="!h-9 placeholder:text-black900 bg-white outline-none"
                                  required={true}
                                  placeholder="Select Date"
                                  value={date}
                                  onChange={setDate}
                                  timeFormat={false}
                                />
                              </div>
                              <div className="flex items-center">
                                <div className="mr-3 text-blue-gray-500">
                                  Visibility
                                </div>
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={`${enabled ? "bg-blue-600" : "bg-gray-200"
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${enabled
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => resetForm()}
                        >
                          Reset
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() => selectedVideo?._id ? updateVideo() : createVideo()}
                          disabled={isLoading}
                        >
                          {selectedVideo?._id ? "Update" : "Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddVideoModal;
