import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "../../../services/common.service";
import { ACTION_TYPES } from "../../../redux/constants";
import { Icon } from "@iconify/react";
import _ from "lodash";
import MemberFilters from "../../attendance/memberlist/MemberFilters";
import {
  downloadProfilePhotos,
  getCommonFieldList,
  getMembersList,
} from "../../attendance/memberlist/actionCreator";
import MemberTable from "./MemberTable";
import MemberProfile from "../../attendance/memberlist/Member-profile/memberProfile";

const Reports = () => {
  const dispatch = useDispatch();
  const { MemberList: MemberMasterList } = useSelector(
    (state) => state.MembersReducer
  );
  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [_memberFilters, _setMemberFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [collapseProfile, setCollapseProfile] = useState(false);
  const [memberDetails, setMemberDetails] = useState();
  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortConfig, setSortConfig] = useState([]);
  const [disablePaginationButton, setDisablePaginationButton] = useState(false);

  const toggleSort = (key) => {
    setSortConfig((prevConfig) => {
      const existingConfig = prevConfig.find((config) => config.key === key);
      if (existingConfig) {
        return prevConfig.map((config) =>
          config.key === key
            ? {
                ...config,
                direction: config.direction === "asc" ? "desc" : "asc",
              }
            : config
        );
      }
      return [{ key, direction: "asc" }];
    });
  };

  const handleCollapseProfile = (memberData) => {
    if (memberDetails?._id === memberData?._id) {
      setCollapseProfile(!collapseProfile);
    } else {
      setMemberDetails(memberData);
      setCollapseProfile(true);
    }
  };

  const handleCloseProfile = () => {
    setCollapseProfile(false);
  };

  useEffect(() => {
    fetchAllMembers(_memberFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortConfig]);

  useEffect(() => {
    const debouncedFetchResults = _.debounce(
      (query) => fetchAllMembers(query),
      2000
    );
    if (_memberFilters.searchTerm) {
      debouncedFetchResults(_memberFilters);
    }
    return () => {
      debouncedFetchResults.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_memberFilters]);

  useEffect(() => {
    if (MemberMasterList && MemberMasterList.length > 0) {
      filterMemberList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MemberMasterList]);

  const fetchCommonFieldList = async () => {
    const response = await getCommonFieldList();
    if (response?.data) {
      dispatch({
        type: ACTION_TYPES.SET_COMMON_FIELDS,
        payload: response.data,
      });
    }
  };

  useEffect(() => {
    fetchCommonFieldList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateQueryParams = (filter) => {
    const queryParams = {};
    for (const key in filter) {
      if (key === "gender") {
        queryParams[key] = filter[key].value;
      } else if (key === "isActive") {
        if (filter[key] === "Active") {
          queryParams[key] = true;
        } else if (filter[key] === "InActive") {
          queryParams[key] = false;
        }
      } else if (Array.isArray(filter[key])) {
        let arrVal = filter[key].map((item) => item.value);
        if (arrVal.length > 0) {
          queryParams[key] = arrVal.join(",");
        }
      } else if (key === "birthMonth") {
        queryParams[key] = filter[key]?.value;
      } else {
        queryParams[key] = filter[key];
      }
    }
    return queryParams;
  };

  const fetchAllMembers = async (filter = _memberFilters) => {
    try {
      setDisablePaginationButton(true);
      const queryParams = generateQueryParams(filter);
      const sortQuery = sortConfig
        .map(
          (config) => `${config.direction === "desc" ? "-" : ""}${config.key}`
        )
        .join(",");

      let response;
      if (Object.keys(filter).length > 0) {
        response = await getMembersList({
          currentPage,
          pageSize,
          queryParams,
          sortQuery,
        });
      } else {
        response = await getMembersList({ currentPage, pageSize, sortQuery });
      }
      if (response?.data) {
        dispatch({
          type: ACTION_TYPES.SET_MEMBERS_LIST,
          payload: response.data.users,
        });
        setFilteredMemberList(response.data.users);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      }
      setIsLoading(false);
      setDisablePaginationButton(false);
    } catch (err) {
      dispatch({ type: ACTION_TYPES.SET_MEMBERS_LIST, payload: [] });
      setIsLoading(false);
      toastr.show("Fetching Members Failed.", "error");
    }
  };

  const filterMemberList = (__memberFilters) => {
    let __filteredMemberList = lodash.cloneDeep(MemberMasterList);
    setFilteredMemberList(__filteredMemberList || []);
  };

  const setMemberFilters = async (key, value, action) => {
    const __memberFilters = lodash.cloneDeep(_memberFilters);
    if (value !== undefined && value !== null) {
      __memberFilters[key] = value;
    } else {
      delete __memberFilters[key];
    }
    if (action === "remove" || (key === "searchTerm" && value === "")) {
      fetchAllMembers(__memberFilters);
    }
    filterMemberList(__memberFilters);
    _setMemberFilters(__memberFilters);
  };

  const resetFilters = async () => {
    try {
      filterMemberList({});
      _setMemberFilters({});
      const response = await getMembersList({ currentPage, pageSize });
      setTotalPages(response.data.totalPages);
      setFilteredMemberList(response.data.users);
    } catch (error) {
      setIsLoading(false);
      toastr.show("Fetching Members Failed.", "error");
    }
  };

  const getDownloadProfilePhotos = async () => {
    try {
      const queryParams = generateQueryParams(_memberFilters);
      const response = await downloadProfilePhotos(queryParams);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "images.zip"); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toastr.show("Failed to download profile photos.", "error");
    }
  };

  return (
    <>
      <MemberFilters
        _memberFilters={_memberFilters}
        setMemberFilters={setMemberFilters}
        resetFilters={resetFilters}
        tableData={tableData}
        setFilteredMemberList={setFilteredMemberList}
        setTotalPages={setTotalPages}
        fetchMember={fetchAllMembers}
      />

      <div className="flex w-full">
        <div className="px-3 flex-grow border border-stone-200 rounded-lg overflow-auto w-full">
          <MemberTable
            isLoading={isLoading}
            downloadProfilePhotos={getDownloadProfilePhotos}
            filteredMemberList={filteredMemberList}
            handleCollapsePofile={handleCollapseProfile}
            memberDetails={memberDetails}
            setTableData={setTableData}
            totalPages={totalPages}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            _memberFilters={_memberFilters}
            setMemberFilters={setMemberFilters}
            toggleSort={toggleSort}
            sortConfig={sortConfig}
            disablePaginationButton={disablePaginationButton}
          />
        </div>
        <div
          className={`max-w-[450px] 2xl:max-w-[600px] w-full relative px-3 py-5 overflow-auto h-[calc(100vh_-_200px)] transition-all ease-out duration-100' ${
            collapseProfile ? "mr-0" : "-mr-[450px] 2xl:-mr-[600px]"
          }`}
        >
          <div className="absolute right-4 top-4">
            <div className="flex items-center">
              <button
                className="p-1.5"
                onClick={() => handleCloseProfile(false)}
              >
                <Icon
                  icon="streamline:move-right-solid"
                  className="cursor-pointer text-primary"
                />
              </button>
            </div>
          </div>
          <MemberProfile
            profileData={memberDetails}
            handleCloseProfile={handleCloseProfile}
            isReadable={true}
          />
        </div>
      </div>
    </>
  );
};

export default Reports;
