import React from "react";
import { DatePicker } from "rsuite";

const DatePickerField = ({
  value,
  disabledDate,
  onChange,
  placeholder,
  format = "dd/MM/yyyy",
  onTrap = true,
  cleanable = false,
  readOnly = false,
  label,
  required
}) => {
  return (
    <React.Fragment>
      {label && (
        <label
          className="text-sm text-gray300 font-semibold mb-2 inline-block"
        >
          {label}
          {required ? <span className="text-red400">*</span> : <></>}
        </label>
      )}
      <DatePicker
        placeholder={placeholder}
        format={format}
        value={value}
        oneTap={onTrap}
        cleanable={cleanable}
        disabledDate={disabledDate}
        onChange={onChange}
        readOnly={readOnly}
      />
    </React.Fragment>
  );
};

export default DatePickerField;
