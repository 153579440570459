import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Icon } from "@iconify/react";
import moment from "moment-timezone";
import SelectField from "../Common/Input/SelectField";
import { Switch } from "@headlessui/react";
import Modal from "../Common/Modal/Modal";
import { Button, Input } from "@material-tailwind/react";
import Datetime from "react-datetime";
import {
  blockMantraLekhanUser,
  getMantraLekhan,
  unBlockMantraLekhanUser,
} from "./actionCreator";
import PrintComponent from "./PrintComponent";
import MemberProfile from "./MemberProfile";
import { getProfileImage, toastr } from "../../services/common.service";
import AlertModal from "../Common/CustomConfirmationModal/CustomConfirmationModal";

moment.tz.setDefault("Asia/Calcutta");

const genderOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const sabhaOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Yuva Sabha",
    value: "Yuva Sabha",
  },
  {
    label: "Atmiya Sabha",
    value: "Atmiya Sabha",
  },
  {
    label: "Kishor Sabha",
    value: "Kishor Sabha",
  },
];

const sourceOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Web",
    value: "web",
  },
  {
    label: "Mobile",
    value: "mobile",
  },
];

const userModeOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Block",
    value: "block",
  },
  {
    label: "Active",
    value: "active",
  },
];

const dateOption = [
  {
    label: "Today's",
    value: "todays",
  },
  {
    label: "Yesterday's",
    value: "yesterdays",
  },
  {
    label: "This Week",
    value: "thisweek",
  },
  {
    label: "Past Week",
    value: "pastweek",
  },
  {
    label: "This Month",
    value: "thisMonth",
  },
  {
    label: "Past Month",
    value: "pastMonth",
  },
];

const MantraLekhanReports = () => {
  const [data, setData] = useState([]);
  const [genderFilter, setGenderFilter] = useState();
  const [sabhaFilter, setSabhaFilter] = useState();
  const [sourceFilter, setSourceFilter] = useState();
  const [smvNoFilter, setSMVNoFilter] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [userModeFilter, setUserModeFilter] = useState();
  const [collapsePersonalDetails, setCollapsePersonalDetails] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [showConfrmationModal, setShowConfirmationModal] = useState(false);
  const [modifyAuthorization, setModifyAuthorization] = useState({
    userId: null,
    isBlockedForMantraLekhan: false,
  });
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [queryParameters] = useSearchParams();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [totalMantraCount, setTotalMantraCount] = useState(0);
  const { pageIndex, pageSize } = pagination;
  const startDateInputRef = useRef();
  const endDateInputRef = useRef();
  const startTimeInputRef = useRef();
  const endTimeInputRef = useRef();

  moment.updateLocale("en", {
    week: {
      dow: 0, // 0 to 6 sunday to saturday
    },
  });

  const handleUserModeFilter = (data) => {
    setUserModeFilter(data);
  };

  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setGenderFilter(null);
    setSMVNoFilter("");
    setSourceFilter("");
    setSabhaFilter("");
    setDateFilter("");
    setUserModeFilter(null);
    setModifyAuthorization({ userId: null, isBlockedForMantraLekhan: null });
    startDateInputRef.current.value = "";
    endDateInputRef.current.value = "";
    startTimeInputRef.current.value = "";
    endTimeInputRef.current.value = "";
  };

  useEffect(() => {
    applyFilter();
  }, [pageIndex, pageSize]);

  useEffect(() => {
    const queryParamPage = Number(queryParameters.get("page"));
    const queryParamPageSize = Number(queryParameters.get("pageSize"));
    if (queryParamPage && queryParamPageSize) {
      setPagination({
        pageIndex: queryParamPage,
        pageSize: queryParamPageSize,
      });
    } else if (queryParamPage) {
      setPagination({ pageIndex: queryParamPage, pageSize: pageSize });
    } else if (queryParamPageSize) {
      setPagination({ pageIndex: pageIndex, pageSize: queryParamPageSize });
    }
  }, []);

  const getMantraLekhanJap = async () => {
    try {
      const result = await getMantraLekhan(
        `limit=${pagination?.pageSize}&skip=${
          pagination?.pageSize * pagination?.pageIndex
        }`
      );
      if (result?.data) {
        setData(result?.data?.items || []);
        setPageCount(Math.ceil(result?.data?.totalCount / pageSize));
        setTotalMantraCount(result?.data?.totalMantraCount);
        setModifyAuthorization({
          userId: null,
          isBlockedForMantraLekhan: false,
        });
        setTotalMantraCount(result?.data?.totalMantraCount);
      }
    } catch (err) {
      toastr.show("Failed !!", "error");
      setModifyAuthorization({ userId: null, isBlockedForMantraLekhan: false });
    }
  };

  const handleGenderFilter = (val) => {
    setGenderFilter(val);
  };

  const handleSabhaFilter = (val) => {
    setSabhaFilter(val);
  };

  const handleSourceFilter = (val) => {
    setSourceFilter(val);
  };

  const handleSMVNoFilter = (val) => {
    setSMVNoFilter(val?.value);
  };

  const handleCloseConfirmationModal = async (status) => {
    if (status && modifyAuthorization) {
      try {
        let result = null;
        if (modifyAuthorization?.isBlockedForMantraLekhan) {
          result = await unBlockMantraLekhanUser(modifyAuthorization?.userId);
        } else {
          result = await blockMantraLekhanUser(modifyAuthorization?.userId);
        }
        if (result?.data) {
          getMantraLekhanJap();
        }
      } catch (err) {
        toastr.show("Failed !!", "error");
      }
    }
    setShowConfirmationModal(false);
  };

  const handleCloseProfile = (value, selectedProfileVal) => {
    if (selectedProfileVal) {
      setSelectedProfile(selectedProfileVal);
    }
    setCollapsePersonalDetails(value);
  };

  const callModifyAuthorization = async (userId, isBlockedForMantraLekhan) => {
    const message = isBlockedForMantraLekhan
      ? "Are you sure you want to unBlock?"
      : "Are you sure you want to Block?";
    setConfirmationMessage(message);
    setShowConfirmationModal(true);
    setModifyAuthorization({
      userId: userId,
      isBlockedForMantraLekhan: isBlockedForMantraLekhan,
    });
  };

  const handleDateFilter = (val) => {
    if (val.value === "todays") {
      setStartDate(moment()?.startOf("day"));
      setEndDate(moment()?.endOf("day"));
    }
    if (val.value === "yesterdays") {
      setStartDate(moment().subtract(1, "days").startOf("day"));
      setEndDate(moment().subtract(1, "days").endOf("day"));
    }
    if (val.value === "thisweek") {
      setStartDate(moment().subtract(0, "weeks").startOf("week"));
      setEndDate(moment().subtract(0, "weeks").endOf("week"));
    }
    if (val.value === "pastweek") {
      setStartDate(moment().subtract(1, "weeks").startOf("week"));
      setEndDate(moment().subtract(1, "weeks").endOf("week"));
    }
    if (val.value === "thisMonth") {
      setStartDate(moment().subtract(0, "month").startOf("month"));
      setEndDate(moment().subtract(0, "month").endOf("month"));
    }
    if (val.value === "pastMonth") {
      setStartDate(moment().subtract(1, "month").startOf("month"));
      setEndDate(moment().subtract(1, "month").endOf("month"));
    }
    setDateFilter(val);
  };

  const applyFilter = async () => {
    try {
      let filterStr = "";
      if (
        genderFilter?.value !== "all" &&
        genderFilter !== undefined &&
        genderFilter
      ) {
        filterStr += `gender=${genderFilter?.value}`;
      }
      if (startDate !== undefined && startDate) {
        filterStr += `&fromDate=${startDate}`;
      }
      if (endDate !== undefined && endDate) {
        filterStr += `&toDate=${endDate}`;
      }
      if (
        sabhaFilter !== undefined &&
        sabhaFilter &&
        sabhaFilter?.value !== "all"
      ) {
        filterStr += `&sabhaType=${encodeURI(`["${sabhaFilter?.value}"]`)}`;
      }
      if (
        sourceFilter !== undefined &&
        sourceFilter &&
        sourceFilter?.value !== "all"
      ) {
        filterStr += `&source=${sourceFilter?.value}`;
      }
      if (
        smvNoFilter !== undefined &&
        smvNoFilter &&
        smvNoFilter?.value !== ""
      ) {
        filterStr += `&SMVNo=${smvNoFilter}`;
      }
      if (
        userModeFilter !== undefined &&
        userModeFilter &&
        userModeFilter?.value !== "all"
      ) {
        if (userModeFilter?.value === "block") {
          filterStr += `&isBlockedForMantraLekhan=true`;
        } else {
          filterStr += `&isBlockedForMantraLekhan=false`;
        }
      }

      filterStr += `&limit=${pagination?.pageSize}&skip=${
        pagination?.pageSize * pagination?.pageIndex
      }`;
      const result = await getMantraLekhan(filterStr);
      if (result?.data) {
        setData(result?.data?.items || []);
        setPageCount(Math.ceil(result?.data?.totalCount / pageSize));
        setTotalMantraCount(result?.data?.totalMantraCount);
      }
    } catch (err) {
      console.log(err);
      toastr.show("Failed !!", "error");
    }
  };

  const handleStartTimeChange = (value) => {
    const todayDate = startDate;
    const [hours, mins] = value?.target?.value?.split(":");
    const dateTimeFormat = moment([
      todayDate?.get("year"),
      todayDate?.get("month"),
      todayDate?.get("date"),
      hours,
      mins,
    ]);
    setStartDate(moment(dateTimeFormat));
  };

  const handleEndTimeChange = (value) => {
    const todayDate = endDate;
    const [hours, mins] = value?.target?.value?.split(":");
    const dateTimeFormat = moment([
      todayDate?.get("year"),
      todayDate?.get("month"),
      todayDate?.get("date"),
      hours,
      mins,
    ]);
    setEndDate(moment(dateTimeFormat));
  };

  const handleStartDate = (value) => {
    let dateValue = value?.target?.value;
    if (startTimeInputRef.current.value) {
      const [hours, mins] = startTimeInputRef.current.value.split(":");
      dateValue = new Date(value?.target?.value);
      const dateTimeFormat = moment([
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDay(),
        hours,
        mins,
      ]);
      setStartDate(dateTimeFormat);
    } else {
      setStartDate(moment(dateValue));
    }
  };

  const handleEndDate = (value) => {
    let dateValue = value?.target?.value;
    if (endTimeInputRef.current.value) {
      const [hours, mins] = endTimeInputRef.current.value.split(":");
      dateValue = new Date(value?.target?.value);
      const dateTimeFormat = moment([
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDay(),
        hours,
        mins,
      ]);
      setEndDate(dateTimeFormat);
    } else {
      setEndDate(moment(dateValue));
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "rank",
      header: () => <span>Rank No.</span>,
      cell: (info, index) => info.getValue(),
      id: "rank",
      size: 30,
      maxSize: 30,
    },
    {
      accessorKey: "SMVNo",
      header: () => <span>SMV No.</span>,
      cell: (info) => info.getValue(),
      id: "SMVNo",
      size: 150,
      maxSize: 150,
    },
    {
      accessorKey: "Photo",
      header: () => <span>Photo</span>,
      cell: (info) => {
        const profilePhoto = getProfileImage(
          info?.row?.original,
          info?.row?.original?.gender
        );
        return (
          <img
            className=" min-w-[48px] max-w-[48px] h-12 rounded-full mr-3 object-cover"
            alt="profile"
            src={profilePhoto}
          />
        );
      },
      id: "profileImgUrl",
      size: 150,
      maxSize: 150,
    },
    {
      accessorKey: "name",
      header: () => <span>Name</span>,
      cell: (info) => (
        <div className="">
          {info.row.original?.firstName} {info.row.original?.middleName}{" "}
          {info.row.original?.lastName}
        </div>
      ),
      id: "name",
      size: 150,
      maxSize: 150,
    },
    {
      accessorKey: "mantraCount",
      header: () => <span>Total Count</span>,
      cell: (info) => info.getValue(),
      id: "totalCount",
      size: 150,
      maxSize: 150,
    },
    {
      accessorKey: "appMantra",
      header: () => <span>Mobile Count</span>,
      cell: (info) => info.getValue(),
      id: "appMantra",
      size: 150,
      maxSize: 150,
    },
    {
      accessorKey: "webMantra",
      header: () => <span>Web Count</span>,
      cell: (info) => info.getValue(),
      id: "webMantra",
      size: 150,
      maxSize: 150,
    },
    {
      accessorKey: "gender",
      header: () => <span>Gender</span>,
      cell: (info) => info.getValue(),
      id: "gender",
      size: 150,
      maxSize: 150,
    },
    {
      accessorKey: "Block",
      header: () => <span>Block</span>,
      cell: (info) => (
        <div className="">
          <Switch
            onChange={() =>
              callModifyAuthorization(
                info.row.original?.userId,
                info.row.original?.isBlockedForMantraLekhan
              )
            }
            className={`${
              info.row.original?.isBlockedForMantraLekhan
                ? "bg-blue-600"
                : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                info.row.original?.isBlockedForMantraLekhan
                  ? "translate-x-6"
                  : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      ),

      id: "Block",
      size: 150,
      maxSize: 150,
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    pageCount: pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.id,
  });
  return (
    <>
      <div className="h-[calc(100vh_-_81px)] p-3 bg-[#F8F8F8] overflow-auto">
        <div className="flex w-full h-[calc(100vh_-135px)]">
          <div className="p-3 flex-grow overflow-auto ">
            <div className="flex justify-between items-center">
              <h1 className="text-lg font-semibold mb-6 text-black900">
                Mantra Lekhan Reports
              </h1>
            </div>
            <div className="flex flex-wrap gap-y-3 items-center mb-4">
              <div className="w-25 relative px-1 mr-1">
                <SelectField
                  required={true}
                  placeholder="Date Filter"
                  defaultOptions={dateOption}
                  value={dateFilter}
                  onChange={(val) => {
                    handleDateFilter(val);
                  }}
                />
              </div>
              <div className="relative px-1 mr-1">
                <div class="relative h-10 w-full min-w-[70px]">
                  <input
                    ref={startDateInputRef}
                    class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={(val) => {
                      handleStartDate(val);
                    }}
                  ></input>
                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Start Date
                  </label>
                </div>
              </div>
              <div className="relative px-1 mr-1">
                <div class="relative h-10 w-full min-w-[70px]">
                  <input
                    ref={startTimeInputRef}
                    placeholder="Select Start Time"
                    type="time"
                    onChange={(evt) => handleStartTimeChange(evt)}
                    class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                  />
                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Select Start Time
                  </label>
                </div>
              </div>
              <div className="relative px-1 mr-1">
                <div class="relative h-10 w-full min-w-[70px]">
                  <input
                    ref={endDateInputRef}
                    class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                    type="date"
                    id="endDate"
                    name="endDate"
                    onChange={(val) => {
                      handleEndDate(val);
                    }}
                  ></input>
                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    End Date
                  </label>
                </div>
              </div>
              <div className="relative px-1 mr-1">
                <div class="relative h-10 w-full min-w-[70px]">
                  <input
                    ref={endTimeInputRef}
                    placeholder="Select End Time"
                    type="time"
                    onChange={(evt) => handleEndTimeChange(evt)}
                    class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                  />
                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    End Time
                  </label>
                </div>
              </div>
              <div className="w-25 relative px-1 mr-1">
                <div class="relative h-10 w-full min-w-[70px]">
                  <SelectField
                    style="{{border: none}}"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                    required={true}
                    defaultOptions={genderOption}
                    value={genderFilter}
                    onChange={(val) => {
                      handleGenderFilter(val);
                    }}
                  />
                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Gender
                  </label>
                </div>
              </div>
              <div className="w-25 relative px-1 mr-1">
                <SelectField
                  required={true}
                  placeholder=" Sabha"
                  defaultOptions={sabhaOption}
                  value={sabhaFilter}
                  onChange={(val) => {
                    handleSabhaFilter(val);
                  }}
                />
              </div>
              <div className="w-25 relative px-1 mr-1">
                <SelectField
                  required={true}
                  placeholder=" Source"
                  defaultOptions={sourceOption}
                  value={sourceFilter}
                  onChange={(val) => {
                    handleSourceFilter(val);
                  }}
                />
              </div>
              <div className="w-25 relative px-1 mr-1">
                <SelectField
                  required={true}
                  placeholder=" UserMode"
                  defaultOptions={userModeOption}
                  value={userModeFilter}
                  onChange={(val) => {
                    handleUserModeFilter(val);
                  }}
                />
              </div>
              <div className="w-25 relative px-1 mr-1">
                <Input
                  className="w-25"
                  placeholder="SMVNo"
                  value={smvNoFilter}
                  onChange={(val) => {
                    handleSMVNoFilter(val?.currentTarget);
                  }}
                />
              </div>
              <div className="relative px-1">
                <Button
                  variant="filled"
                  onClick={() => {
                    applyFilter();
                  }}
                >
                  Apply
                </Button>
              </div>
              <div className="relative px-1">
                <Button
                  variant="filled"
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Clear
                </Button>
              </div>
              <div className="relative px-1">
                <PrintComponent data={data} />
              </div>
              <div className="relative px-1">
                {totalMantraCount > 0 && (
                  <span> Total Mantra Count: {totalMantraCount} </span>
                )}
              </div>
            </div>
            <div className="overflow-auto">
              <table className="w-full member-table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]"
                    >
                      {headerGroup.headers.map((header) => {
                        const { column } = header;
                        return (
                          <th
                            key={header.id}
                            className="text-left font-semibold p-2 bg-[#F2F2F2]"
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className="border-b border-stone-200 text-sm bg-white"
                      onClick={() => handleCloseProfile(true, row?.original)}
                    >
                      {row.getVisibleCells().map((cell) => {
                        const { column } = cell;
                        return (
                          <td key={cell.id} className="p-2 bg-white">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end gap-2 mt-3">
              <button
                className="border rounded w-8 h-8 grid place-items-center"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <Icon icon="lucide:chevron-left" className="text-xl" />
              </button>
              <button
                className="border rounded w-8 h-8 grid place-items-center"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <Icon icon="lucide:chevron-right" className="text-xl" />
              </button>
              <span className="flex items-center gap-1">
                <div>Page</div>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount().toLocaleString()}
                </strong>
              </span>
              <span className="flex items-center gap-1">
                | Go to page:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    table.setPageIndex(page);
                  }}
                  className="border p-1 rounded w-16 outline-none"
                />
              </span>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                className="outline-none p-1 border rounded "
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className={`max-w-[400px] 2xl:max-w-[600px] bg-white w-full relative px-3 py-5 overflow-auto shadow-md transition-all ease-out duration-100' ${
              collapsePersonalDetails ? "mr-0" : "-mr-[400px] 2xl:-mr-[600px]"
            }`}
          >
            <div className="absolute right-4 top-4 z-30">
              <div className="flex items-center">
                <button
                  className="p-2"
                  onClick={() => handleCloseProfile(false)}
                >
                  <Icon
                    icon="streamline:move-right-solid"
                    className="cursor-pointer text-primary"
                  />
                </button>
              </div>
            </div>
            <MemberProfile profileData={selectedProfile}></MemberProfile>
          </div>
        </div>
      </div>
      <AlertModal
        open={showConfrmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        messageText={confirmationMessage}
        submitBtnText="Confirm"
        buttonType="primary"
        icon="akar-icons:block"
      />
    </>
  );
};

export default MantraLekhanReports;
