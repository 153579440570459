import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
const MenuBox = ({ menuButton, menuItems, setSelectOption, selectedOption, isDisabled = false }) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button>
                {menuButton}
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-30 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className='px-1 py-1'>
                        {
                            menuItems.length > 0 &&
                            menuItems.map((item, index) => {
                                return (
                                    <Menu.Item key={index} disabled={isDisabled && index === 0} onClick={() => setSelectOption(index + 1)}>
                                        <div className={`${isDisabled && index === 0 ? 'text-gray300 !cursor-not-allowed hover:text-gray300' : ''} group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer hover:text-primary transition-all duration-100`}>{item}</div>
                                    </Menu.Item>
                                )
                            })
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default MenuBox