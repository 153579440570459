import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose } from "@mdi/js";
import { Typography, Checkbox } from "@material-tailwind/react";
import { toastr } from "../../services/common.service";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import { submitUserForm, updateUserForm } from "./actionCreator";

const generateDateRange = (startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};

const MemberFormModal = ({ openMemberForm, setOpenMemberForm, fromDate, toDate, formIconUrl, formId, willJoinWithDays, selectedForm, userList, setUserList }) => {

  const [formData, setFormData] = useState({});
  const [dates, setDates] = useState([]);
  const [checkedDates, setCheckedDates] = useState({});

  useEffect(() => {
    const start = new Date(fromDate);
    const end = new Date(toDate);
    const rangeDates = generateDateRange(start, end);
    setDates(rangeDates);
    if(!willJoinWithDays && Array.isArray(rangeDates) && rangeDates.length > 0) {
      setCheckedDates((prev) => ({
        ...prev,
        ...rangeDates.reduce((acc, d) => {
          const formattedDate = moment(d).format("DD/MM/YYYY");
          acc[formattedDate] = !prev[formattedDate];
          return acc;
        }, {}),
      }));
    }
  }, [willJoinWithDays]);

  useEffect(() => {
    if(Object.keys(selectedForm).length) {
      setFormData({
        ...formData,
        smvno: selectedForm.SMVNo,
      })
      setCheckedDates((prev) => ({
        ...prev,
        ...selectedForm.selectedDates.reduce((acc, d) => {
          acc[d] = !prev[d];
          return acc;
        }, {}),
      }));
    }
  }, [selectedForm])

  const handleCheckboxChange = (date) => {
    setCheckedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };


  const handleSetFormData = () => {
    setFormData({});
  };

  const submitForm = async() => {
    try {
      if (!formData?.isEditable) {
        toastr.show("Please confirm join", "error");
        return;
      }
      if(Object.keys(checkedDates).length === 0) {
        toastr.show("Please Select range", "error");
        return;
      }
      const selectedDates = Object.entries(checkedDates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key)
      const newData = {
        "SMVNo": Number(formData.smvno),
        // "userId": "65315ff9f08e818c7ae4874c",
        "formId": formId,
        "selectedDates": selectedDates,
        "daysToJoinEvent": selectedDates?.length || 0,
        "formIconUrl": formIconUrl,
        "maleCount": 0,
        "femaleCount": 0,
        "boysCount": 0,
        "girlsCount": 0
      }
      const res = await submitUserForm(newData)
      if(res.data) {
        setOpenMemberForm(false)
        if (!userList?.length) userList = [res.data];
        else userList.push(res.data);
        setUserList(userList);
        toastr.show("Successfully Submitted", "success");
      }
    } catch (error) {
      toastr.show("Something went wrong", "error");
    }
  }

  const updateForm = async() => {
    try {
      if (!formData?.isEditable) {
        toastr.show("Please confirm join", "error");
        return;
      }
      if(Object.keys(checkedDates).length === 0) {
        toastr.show("Please Select range", "error");
        return;
      }
      const selectedDates = Object.entries(checkedDates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key)

      const newData = {
        "SMVNo": Number(formData.smvno),
        // "userId": "65315ff9f08e818c7ae4874c",
        "formId": formId,
        "selectedDates": selectedDates,
        "daysToJoinEvent": selectedDates?.length || 0,
        "maleCount": 0,
        "femaleCount": 0,
        "boysCount": 0,
        "girlsCount": 0,
        "isDelete": false
      }
      const res = await updateUserForm(newData)
      if(res.data) {
        setOpenMemberForm(false)
        const newList = userList
          .map(f => {
            if (f._id + "" === selectedForm._id + "") {
              return { ...f, ...res.data }
            }
            return { ...f };
          })
        setUserList(newList);
        toastr.show("Successfully Submitted", "success");
      }
    } catch (error) {
      toastr.show("Something went wrong", "error");
    }
  }

  return (
    <>
      <Transition appear show={openMemberForm} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-xl font-semibold text-orange-600">
                      Member Form
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpenMemberForm(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div className="mb-5 w-1/2">
                                <Input
                                  label="SMV No."
                                  type="number"
                                  className=" w-full"
                                  value={formData?.smvno || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      smvno: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <div className="text-lg text-gray300 font-semibold mb-2 inline-block">
                                  Available Dates
                                </div>
                                <div className="grid grid-cols-4">
                                  {dates.map((date) => {
                                    const dateString = moment(date).format("DD/MM/YYYY");
                                    return (
                                      <div key={dateString}>
                                        <div className="flex items-center">
                                          <Checkbox
                                            checked={!!checkedDates[dateString]}
                                            onChange={() =>
                                              handleCheckboxChange(dateString)
                                            }
                                            label={<span className="text-lg">{dateString}</span>}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div>
                                <Checkbox
                                color="blue"
                                  label={
                                    <Typography className="poppins text-lg">
                                      Are You Want To Join
                                    </Typography>
                                  }
                                  checked={formData["isEditable"]}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      isEditable: e.target.checked,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => {
                            setOpenMemberForm(false);
                            handleSetFormData();
                          }}
                        >
                          Close
                        </Button>
                      </div>
                      <div>
                        <Button className="capitalize text-base bg-orange-600" onClick={() => selectedForm._id ? updateForm(): submitForm()}>
                          {selectedForm._id ? "Update": "Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default MemberFormModal;
