import React, { useState } from 'react'
import moment from 'moment'

import TextField from '../../Common/Input/TextField'
import DateField from '../../Common/Input/DateField'
import { Input, Radio } from '@material-tailwind/react'
import SelectField from '../../Common/Input/SelectField'
import CreatableMultiselectMain from '../../Common/Input/CreatableMultiselectMain'
import countryCodeData from '../../../constants/CountryCodes.json'

const bloodGroupOptions = [
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
]

const OtherInfo = ({ memberDetails, setMemberDetails, options, occupationList, educationList, villageList, isNewUser }) => {
    const [maritalStatus, setMaritalStatusValue] = useState('Unmarried');
    const handleRadioSelection = (inputValue) => {
        if (inputValue?.target?.value) {
            setMaritalStatusValue(inputValue.target.value)
            setMemberDetails('maritalStatus', inputValue.target.value === "Married" ? true : false)
        }
    };
    let selectedOptions = [];
    if (memberDetails?.skills?.length > 0) {
        selectedOptions = memberDetails.skills.map(member => ({ label: member?.label || member, value: member || member?.label }));
    }
    const defaultSelectedCountry = [
        {
            "label": "India +91",
            "value": "91",
            "code": "IN"
        },]
    return (
        <div className='grid grid-cols-3 gap-x-10 gap-y-5'>
            <div className=''>
                <DateField
                    id="13"
                    className="!h-9 placeholder:text-black900 outline-none"
                    label="Date Of Birth"
                    // required={true}
                    placeholder="Selete DOB"
                    value={memberDetails.birthDate ? moment(memberDetails.birthDate).format('DD/MM/YYYY') : ""}
                    onChange={(e) => setMemberDetails('birthDate', moment(e).toISOString())}
                    timeFormat={false}
                />
            </div>
            <div>
                <div id="14" className='text-sm text-gray300 font-semibold mb-2 inline-block'>Marriage Status
                </div>
                <div className='flex'>
                    <div className='mr-6'>
                        <Radio name='Married' value='Married' label="Married" onChange={(evt) => handleRadioSelection(evt)} checked={maritalStatus === "Married"} />
                    </div>
                    <div>
                        <Radio name='Unmarried' value='Unmarried' label="Unmarried" onChange={(evt) => handleRadioSelection(evt)} checked={maritalStatus === "Unmarried"} />
                    </div>
                </div>
                <div>

                </div>
            </div>
            <div className=''>
                <DateField
                    id="15"
                    className="placeholder:gray100"
                    label="Date Of Marriage"
                    placeholder="Selete Marriage Date"
                    value={memberDetails.marriageDate ? moment(memberDetails.marriageDate).format('DD/MM/YYYY') : null}
                    onChange={(e) => setMemberDetails('marriageDate', moment(e).toISOString())}
                    timeFormat={false}
                    disabled={!memberDetails.maritalStatus}
                />
            </div>
            <div className=''>
                <CreatableMultiselectMain
                    className="placeholder:gray100"
                    label="Occupation"
                    id="16"
                    isMulti={false}
                    placeholder="Select Occupation"
                    value={memberDetails?.occupation ? [{ label: memberDetails?.occupation, value: memberDetails?.occupation }] : []}
                    options={occupationList}
                    onChange={(e) => { setMemberDetails('occupation', e?.value) }}
                />
            </div>
            <div className=''>
                <CreatableMultiselectMain
                    className="placeholder:gray100"
                    label="Education"
                    id="16"
                    isMulti={false}
                    placeholder="Select Education"
                    value={memberDetails?.education ? [{ label: memberDetails?.education, value: memberDetails?.education }] : []}
                    options={educationList}
                    onChange={(e) => { setMemberDetails('education', e?.value) }}
                />
            </div>
            <div className=''>
                <SelectField
                    className="placeholder:gray100"
                    required={false}
                    label="Blood Group"
                    placeholder="Select Blood Group"
                    id="18"
                    defaultOptions={bloodGroupOptions}
                    value={memberDetails?.bloodGroup ? [{ value: memberDetails.bloodGroup, label: memberDetails.bloodGroup }] : []}
                    onChange={(e) => { setMemberDetails('bloodGroup', e?.value) }}
                />
            </div>
            <div className=''>
                <TextField
                    className="placeholder:gray100"
                    label="Referrer Name"
                    placeholder="Enter Referrer Name"
                    id="19"
                    value={memberDetails.referrerName}
                    onChange={(e) => setMemberDetails('referrerName', e?.target?.value)}
                />
            </div>
            <div className='flex'>
                <div className='flex-none'>
                    <SelectField
                        className="placeholder:gray100"
                        label="Code"
                        id="041"
                        isMulti={false}
                        placeholder="Select Country"
                        options={countryCodeData}
                        isSearchable={true}
                        value={{
                            label: `+${memberDetails?.referrerCountryCode || '91'}`,
                            value: memberDetails?.referrerCountryCode
                        }}
                        defaultValue={defaultSelectedCountry}
                        defaultOptions={countryCodeData.map((countyObj) => ({
                            label: `${countyObj.name} +${countyObj.dial_code}`,
                            value: countyObj.dial_code,
                        }))}
                        onChange={(e) => { setMemberDetails('referrerCountryCode', String(e?.value || 91)) }}
                    />
                </div>
                <div className='flex-none pl-1'>
                    <div className="relative max-w-[170px]">
                        <span className='text-sm text-gray300 font-semibold mb-2 inline-block'>Referrer Mobile</span>
                        <input placeholder="Referrer Mobile"
                            className="peer h-10 max-h-[38px] w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 focus:outline-0"
                            value={memberDetails?.referrerMobile}
                            maxLength={10} min={10}
                            onChange={(value) => setMemberDetails('referrerMobile', value?.target?.value)}
                        />

                    </div>
                </div>
            </div>
            <div className=''>
                <CreatableMultiselectMain
                    className="placeholder:gray100"
                    label="Native Village"
                    id="22"
                    isMulti={false}
                    placeholder="Select Native Village"
                    value={memberDetails?.village ? [{ label: memberDetails?.village, value: memberDetails?.village }] : []}
                    options={villageList}
                    onChange={(e) => { setMemberDetails('village', e?.value) }}
                />
                {/* <CreatableField className="placeholder:gray100" label="Native Village" id="12" placeholder="Native Enter Village" /> */}
            </div>
            <div className='col-span-2'>
                <CreatableMultiselectMain
                    className="placeholder:gray100"
                    label="Skills"
                    placeholder="Enter Skills"
                    id="23"
                    isMulti={true}
                    value={selectedOptions || []}
                    setMemberDetails={setMemberDetails}
                    options={options}
                    onChange={(e) => { setMemberDetails('skills', e) }}
                />
            </div>
            {/* <div>
                <div className='text-sm text-gray300 font-semibold mb-2 inline-block'>Activity status</div>
                <div className="flex">
                    <div className="mr-6">
                        <Radio
                            name="active"
                            label="Active"
                            checked={memberDetails.isActive}
                            onChange={() => setMemberDetails("isActive", true)}
                        />
                    </div>
                    <div>
                        <Radio
                            name="inactive"
                            label="Inactive"
                            checked={!memberDetails.isActive}
                            onChange={() => setMemberDetails("isActive", false)}
                        />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default OtherInfo