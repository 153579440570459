import React, { Fragment, useState } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { Typography } from "@material-tailwind/react";
import Icon from "@mdi/react";
import TextField from "../../Common/Input/TextField";
import { toastr } from "../../../services/common.service";
import { api } from "../../../services/api.service";
import { mdiAlertCircle, mdiShieldKey } from "@mdi/js";

const SetPassword = (props) => {
  const { memberId, closeMemeberModel, open } = props;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSetPassword = async () => {
    try {
      if (!password?.length || password?.length <= 7) {
        toastr.show("Please enter 8 digits.", "error");
      } else if (password !== confirmPassword) {
        toastr.show("Password is not matching", "error");
      } else {
        const userData = {
          _id: memberId,
          password: password,
        };
        const res = await api.post("admin/user/set-password", userData);
        toastr.show("Success !!", "success");
        closeMemeberModel();
      }
    } catch (err) {
      toastr.show("Failed !!", "error");
    }
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" open={open} onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Typography className="text-center mb-4">
                    <Icon
                      path={mdiShieldKey}
                      size={2}
                      className="text-orange-600 inline-block"
                    />
                  </Typography>

                  <div className="mt-2">
                    <div>
                      <TextField
                        id="01"
                        type="password"
                        className="placeholder:gray100"
                        label={"Enter Password"}
                        required={true}
                        placeholder="Please enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="mt-3">
                      <TextField
                        id="02"
                        type="password"
                        className="placeholder:gray100"
                        label={"Re-enter Password"}
                        required={true}
                        placeholder="Please confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end">
                    <button
                      type="button"
                      className="mr-3 inline-flex justify-center rounded-md border bg-white hover:bg-red-50 border-orange-900 px-4 py-2 text-sm font-medium text-orange-900  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeMemeberModel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => handleSetPassword()}
                      className="inline-flex justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    >
                      Set Password
                    </button>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SetPassword;
