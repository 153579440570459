import React from "react";
import { useRoutes } from "react-router-dom";
import { Reports } from "../../../Components";

export default function ReportRouter() {
  const ReportRoute = [
    {
      path: "attendance",
      element: <Reports />,
    },
  ];
  const ReportRouter = useRoutes(ReportRoute);
  return ReportRouter;
}
