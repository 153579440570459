import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Radio, Typography, Checkbox } from "@material-tailwind/react";
import CreatableMultiselectMain from "../Input/CreatableMultiselectMain";
import {
  GROUP_NUMBERS,
  MonthOptions,
  SABHA_TYPES,
  genderType,
} from "../../../constants/common.constants";
import SelectField from "../Input/SelectField";

export function FilterAccordion(props) {
  const { _memberFilters, setMemberFilters } = props;
  console.log("🚀 ~ FilterAccordion ~ _memberFilters:", _memberFilters)
  const { villageList, streetNameList, societyList } = useSelector((state) => state.CommonFields);
  const { HODList: Departments } = useSelector((state) => state.SaintLeaderReducer);
  
  const [femalePhotoHide, setFemalePhotoHide] = useState(false);

  const ageHandler = (input, type) => {
    setMemberFilters(type, input);
  }

  const updateFemalPhotoHide = (value) => {
    if (value) {
      window?.localStorage?.setItem('female-hide', 'show');
      setFemalePhotoHide(true);
    } else {
      window?.localStorage?.setItem('female-hide', 'hide');
      setFemalePhotoHide(false);
    }
  }

  const { flagList } = useSelector((state) => state.MemberFlagsReducer);

  useEffect(() => {
    const hideFemailPhoto = window?.localStorage?.getItem('female-hide') === 'show' ? true : false;
      setFemalePhotoHide(hideFemailPhoto);
  }, []);

  return (
    <>
      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        SMV No.
                      </div>
                      <div className="pb-2 pt-0">
                        <input
                          placeholder="SMVNo"
                          className="bg-white text-sm h-9 w-3/4 border-gray-400 border text-[#333333] outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
                          value={_memberFilters?.SMVNo}
                          onChange={(e) => {
                            setMemberFilters("SMVNo", e.target.value);
                          }}
                        />
                      </div>
      </div>
      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
        <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
          Female Photo 
        </div>
          <div className="flex items-center">
             <div className="w-1/2">
              <Checkbox
                 label={<Typography className="poppins">Show</Typography>}
                 checked={ femalePhotoHide }
                 onChange={(value) => updateFemalPhotoHide(value?.target?.checked)}
               />
               </div>
           </div>
      </div>
                    <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        Sabha
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {SABHA_TYPES.map((sabha) => {
                          return (
                            <div
                              className={`px-1.5 py-1.5 text-[12px] ${_memberFilters.sabhas?.find((d) => d.value === sabha.name)
                                ? "bg-blue-600 text-white"
                                : "text-gray300 border-gray300 bg-white"
                                } rounded-md select-none border cursor-pointer text-center`}
                              onClick={() => {
                                let val = [...(_memberFilters.sabhas || [])];
                                if (val.find((d) => d.value === sabha.name)) {
                                  val = val.filter((d) => d.value !== sabha.name);
                                } else {
                                  val.push({ label: sabha.name, value: sabha.name });
                                }
                                setMemberFilters("sabhas", val);
                              }}
                            >
                              {sabha.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        Account Blocked
                      </div>
                      <div className="flex items-center">
                        <div className="w-1/2">
                          <Checkbox
                            label={<Typography className="poppins">Blocked</Typography>}
                            checked={_memberFilters?.blocked || false}
                            onChange={(value) =>
                              setMemberFilters(
                                "blocked",
                                value?.target?.checked
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
      {_memberFilters.sabhas?.length > 0 &&
      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
        <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
          Department
        </div>
        <div className="grid grid-cols-2 gap-2">
          {Departments.filter((dept) => _memberFilters.sabhas.map((d) => d.value).includes(dept.sabhaName)).map((dept) => {
            return (
              <div
                className={`px-1.5 py-1.5 text-[12px] ${_memberFilters.department?.find(
                  (d) => d.value === dept._id
                )
                  ? "bg-blue-600 text-white"
                  : "text-gray300 border-gray300 bg-white"
                  } rounded-md select-none border cursor-pointer text-center`}
                onClick={() => {
                  let val = [...(_memberFilters.department || [])];
                  if (val.find((d) => d.value === dept._id)) {
                    val = val.filter((d) => d.value !== dept._id);
                  } else {
                    val.push({
                      label: dept.departmentName,
                      value: dept._id,
                    });
                  }
                  setMemberFilters("department", val);
                }}
              >
                {dept.departmentName}
              </div>
            );
          })}
        </div>
      </div>
      }
          <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
            <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
              Gender
            </div>
            <div className="pb-2 pt-0 w-3/4">
              <SelectField
                className="!h-9 placeholder:text-black900"
                required={false}
                placeholder="Select Gender"
                isMulti={false}
                defaultOptions={genderType}
                value={_memberFilters.gender || []}
                onChange={(val) => {
                  setMemberFilters("gender", val);
                }}
              />
            </div>
          </div>
          <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
            <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
              Group No
            </div>
            <div className="pb-2 pt-0 w-3/4">
              <CreatableMultiselectMain
                id="16"
                className="!h-9 placeholder:text-black900"
                required={false}
                placeholder="Select Group No"
                isMulti={true}
                options={GROUP_NUMBERS}
                value={_memberFilters.groupNos || []}
                onChange={(val) => {
                  setMemberFilters("groupNos", val);
                }}
              />
            </div>
          </div>
          <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
            <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
            Society
            </div>
            <div className="pb-2 pt-0 w-3/4">
              <CreatableMultiselectMain
                id="17"
                className="!h-9"
                required={false}
                placeholder="Select Society"
                isMulti={false}
                options={[...societyList, ...streetNameList]}
                value={_memberFilters?.address
                    ? [
                        {
                          label:
                          _memberFilters?.address || "",
                          value:
                          _memberFilters?.address || "",
                        },
                      ]
                    : []
                  }
                onChange={(val) => {
                  setMemberFilters("address", val?.value || "");
                }}
              />
            </div>
          </div>
          <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
            <div className="text-sm text-black900 font-semibold border-none py-2">
              Role
            </div>
            <div className="flex items-center mb-2">
              <div className="w-1/3">
                <Checkbox
                  label="All Leaders"
                  checked={_memberFilters?.allLeaders || false}
                  onChange={(value) =>
                    setMemberFilters(
                      "allLeaders",
                      value?.target?.checked
                    )
                  }
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  label="All HOD"
                  checked={_memberFilters?.allHOD || false}
                  onChange={(value) =>
                    setMemberFilters(
                      "allHOD",
                      value?.target?.checked
                    )
                  }
                />
              </div>
              <div className="w-1/3">
                <Checkbox
                  label="All HOL"
                  checked={_memberFilters?.allHOL || false}
                  onChange={(value) =>
                    setMemberFilters(
                      "allHOL",
                      value?.target?.checked
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
            <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
              Native Village
            </div>
            <div className="pb-2 pt-0 w-3/4">
              <CreatableMultiselectMain
                id="18"
                className="!h-9"
                required={false}
                placeholder="Select Village"
                isMulti={true}
                options={villageList}
                value={_memberFilters.village || []}
                onChange={(val) => {
                  setMemberFilters("village", val);
                }}
              />
            </div>
          </div>
          <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
            <div className="text-sm text-black900 font-semibold border-none py-2">
              Active
            </div>
            <div>
              <div className="flex">
                <div className="mr-3">
                  <Radio
                    name="Both"
                    value="Both"
                    label="Both"
                    onChange={() => {
                      setMemberFilters("isActive", null);
                    }}
                    checked={!_memberFilters.isActive}
                  />
                </div>
                <div className="mr-3">
                  <Radio
                    name="Active"
                    value="Active"
                    label="Active"
                    onChange={(e) => {
                      setMemberFilters("isActive", e.target.value);
                    }}
                    checked={_memberFilters.isActive === "Active"}
                  />
                </div>
                <div>
                  <Radio
                    name="Inactive"
                    value="Inactive"
                    label="Inactive"
                    onChange={(e) => {
                      setMemberFilters("isActive", e.target.value);
                    }}
                    checked={_memberFilters.isActive === "InActive"}
                  />
                </div>
              </div>
            </div>
          </div>
      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
        <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
          Age
        </div>
        <div>
          {/* <TooltipSlider
            value={age ? Object.values(age) : [0, 0]}
            range
            min={13}
            max={100}
            defaultValue={[0, 0]}
            tipFormatter={(value) => `${value}`}
            onChange={(value) => handleAgeFilter(value)}
            /> */}
          <div className="flex gap-2 w-full">
            <input type="number" min={0} max={100} value={_memberFilters?.minAge} placeholder="MIN" onChange={(e) => { ageHandler(e.target.value, 'minAge') }} className="max-w-[80px] h-9 p-1.5 outline-none border rounded-md border-gray-400 appearance-none placeholder:text-gray-700" />
            <input type="number" min={0} max={100} value={_memberFilters?.maxAge} placeholder="MAX" onChange={(e) => { ageHandler(e.target.value, 'maxAge') }} className="max-w-[80px] h-9 p-1.5 outline-none border rounded-md border-gray-400 appearance-none placeholder:text-gray-700" />
          </div>
        </div>
      </div>

      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
        <div className="text-sm text-black900 font-semibold border-none py-2">
          I-Card
        </div>
        <div>
          <div className="flex">
            <div className="mr-3">
              <Radio
                name="given"
                value="Both"
                label="Both"
                onChange={(e) => {
                  setMemberFilters("icardGiven", null);
                }}
                checked={!_memberFilters.icardGiven}
              />
            </div>
            <div className="mr-3">
              <Radio
                name="given"
                value="Given"
                label="Given"
                onChange={(e) => {
                  setMemberFilters("icardGiven", e.target.value);
                }}
                checked={_memberFilters.icardGiven === "Given"}
              />
            </div>
            <div>
              <Radio
                name="notGiven"
                value="Not Given"
                label="Not Given"
                onChange={(e) => {
                  setMemberFilters("icardGiven", e.target.value);
                }}
                checked={_memberFilters.icardGiven === "Not Given"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
        <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
          Mantra lekhan Users
        </div>
        <div className="flex items-center">
          <div className="w-1/2">
            <Checkbox
              label={<Typography className="poppins">Show</Typography>}
              checked={_memberFilters?.isMantraLekhanUser || false}
              onChange={(value) =>
                setMemberFilters(
                  "isMantraLekhanUser",
                  value?.target?.checked
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
        <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
          Birth Month
        </div>
        <div className="w-3/4">
          <CreatableMultiselectMain
            className="!h-9 placeholder:text-black900"
            required={false}
            placeholder="Select Month"
            isMulti={true}
            options={MonthOptions}
            value={_memberFilters.birthMonth}
            onChange={(val) => {
              setMemberFilters("birthMonth", val);
            }}
          />
        </div>
      </div>
      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md mb-4">
        <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
          Members of.
        </div>
        <div className="w-full">
          {flagList.map(flag => {
            if (flag?.isShow) {
              return (
                <div className="">
                  <Checkbox
                    label={<Typography className="poppins">{flag.name}</Typography>}
                    checked={_memberFilters?.[flag?.referenceField] || false}
                    onChange={(value) => setMemberFilters(flag?.referenceField || false, value?.target?.checked)}
                  />
                </div>
              )
            }
          })}
          <div className="">
            <Checkbox
              label={<Typography className="poppins">Foreigner</Typography>}
              checked={ _memberFilters?.foreigner || false }
              onChange={(value) => setMemberFilters("foreigner", value?.target?.checked)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
