import lodash from "lodash";
import { ACTION_TYPES } from "../constants";

const INITIAL_STATE = {
    MemberList: [],
    streetNameList: [],
    societyList: [],
    cityList: [],
    stateList: [],
    countryList: [],
    villageList: [],
    occupationList: [],
    educationList: [],
};

const getList = ({ state, MemberList }) => {
    const streetNameList = lodash.uniq(MemberList.map((d) => d?.address?.streetName)).filter((d) => d);
    const societyList = lodash.uniq(MemberList.map((d) => d?.address?.society)).filter((d) => d);
    const cityList = lodash.uniq(MemberList.map((d) => d?.address?.city)).filter((d) => d);
    const stateList = lodash.uniq(MemberList.map((d) => d?.address?.state)).filter((d) => d);
    const countryList = lodash.uniq(MemberList.map((d) => d?.address?.country)).filter((d) => d);
    const villageList = lodash.uniq(MemberList.map((d) => d?.village)).filter((d) => d);
    const occupationList = lodash.uniq(MemberList.map((d) => d?.occupation)).filter((d) => d);
    const educationList = lodash.uniq(MemberList.map((d) => d?.education)).filter((d) => d);
    return {
        ...state,
        MemberList: MemberList,
        streetNameList: streetNameList.map((_val) => ({ label: _val, value: _val })),
        societyList: societyList.map((_val) => ({ label: _val, value: _val })),
        cityList: cityList.map((_val) => ({ label: _val, value: _val })),
        stateList: stateList.map((_val) => ({ label: _val, value: _val })),
        countryList: countryList.map((_val) => ({ label: _val, value: _val })),
        villageList: villageList.map((_val) => ({ label: _val, value: _val })),
        occupationList: occupationList.map((_val) => ({ label: _val, value: _val })),
        educationList: educationList.map((_val) => ({ label: _val, value: _val })),
    };
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ACTION_TYPES.SET_MEMBERS_LIST: {
            const MemberList = action.payload;
            return getList({ state, MemberList: MemberList });
        }

        case ACTION_TYPES.ADD_MEMBER: {
            const MemberList = [action.payload, ...state.MemberList];
            return getList({ state, MemberList: MemberList });
        }

        case ACTION_TYPES.UPDATE_MEMBER: {
            const updatedDetail = action.payload || {};
            const MemberList = state.MemberList.map((eachMember) => {
                if (eachMember._id + "" === updatedDetail._id + "") {
                    return updatedDetail;
                }
                return eachMember
            })
            return getList({ state, MemberList: MemberList });
        }

        case ACTION_TYPES.REMOVE_MEMBER: {
            const { _id } = action.payload || {};
            const MemberList = state.MemberList.filter((eachMember) => {
                return eachMember._id !== _id
            })
            return getList({ state, MemberList: MemberList });
        }
    }
    return state;
}
