import React from 'react'
import { useRoutes } from 'react-router-dom'
import MantraLekhan from '../../../Components/MantraLekhan/MantraLekhan';
import MantraLekhanReports from '../../../Components/MantraLekhan/MantraLekhanReports';
export default function MantraLekhanRouter() {
    const MantraLekhanRoute = [
        {
            path: "audit",
            element: (
                <>
                    <MantraLekhan />
                </>
            )
        },
        {
            path: "reports",
            element: (
                <>
                    <MantraLekhanReports />
                </>
            )
        },
    ]
    const MantraLekhanRouter = useRoutes(MantraLekhanRoute);
    return MantraLekhanRouter
}
