import React, { Fragment, useState } from "react";
import Icon from "@mdi/react";
import { Button, Radio } from "@material-tailwind/react";
import { mdiClose, mdiImagePlus, mdiTrashCan } from "@mdi/js";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import EventUpload from "../Common/UploadModal/UploadContent";

const EventUploadModal = (props) => {
  const { open, onClose } = props;

  const [files, setFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleFileChange = (_files) => {
    const selectedFiles = _files;
    setFiles([...files, ...selectedFiles]);

    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previews.push(e.target.result);
        if (previews.length === selectedFiles.length) {
          setImagePreviews([...imagePreviews, ...previews]);
        }
      };
      reader.readAsDataURL(selectedFiles[i]);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    const updatedPreviews = [...imagePreviews];
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setFiles(updatedFiles);
    setImagePreviews(updatedPreviews);
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" open={open} className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      Upload Events
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setFiles([]);
                        setImagePreviews([]);
                        onClose()
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div>
                      <div className="flex gap-10 mb-3 justify-center">
                        <Radio name="type" color="orange" label="General" />
                        <Radio name="type" color="orange" label="Devotee" defaultChecked />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div>
                        {imagePreviews.length > 0 && (
                          <div className="grid grid-cols-6 gap-4">
                            {imagePreviews.map((preview, index) => {
                              return (
                                <div
                                  key={"img" + index}
                                  className=" rounded-lg relative"
                                >
                                  <img
                                    src={preview}
                                    className="block rounded-lg w-full h-full object-contain object-center"
                                  />
                                  <div className="absolute rounded-lg w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.50)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                                    <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease]">
                                      <button
                                        onClick={() => handleRemoveFile(index)}
                                      >
                                        <Icon path={mdiTrashCan} size={1} />
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                            <label
                              htmlFor="more-upload"
                              className="w-[160px] h-[200px] cursor-pointer flex justify-center bg-gray-50 hover:bg-gray-100 flex-col items-center m-1 rounded-lg"
                            >
                              <div
                                htmlFor="2"
                                className="w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
                              >
                                <Icon
                                  path={mdiImagePlus}
                                  size={3}
                                  color={"#A5A5A5"}
                                  className="cursor-pointer"
                                />
                              </div>
                              <div className="text-sm text-center max-w-[100px] mt-2">
                                Upload more images
                              </div>
                            </label>
                            <input
                              type="file"
                              id="more-upload"
                              onChange={(e) => handleFileChange(e.target.files)}
                              multiple={true}
                              hidden
                            />
                          </div>
                        )}
                        {imagePreviews.length === 0 && (
                          <EventUpload
                            handleFileChange={handleFileChange}
                            multiple={true}
                          />
                        )}
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => {
                            setFiles([]);
                            setImagePreviews([]);
                            onClose();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button className="capitalize text-base bg-orange-600">
                          Upload
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EventUploadModal;
