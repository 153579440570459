import { useLocation, useNavigate } from "react-router-dom"

export const useReactNavigator = () => {

    const navigation = useNavigate()
    const location = useLocation()


    let historyState = (location.state ?? window.history.state?.usr) || ''

    const navigate = (path, state) => {
        navigation(path, { state: { ...state } })
    }

    const goBack = (id) => {
        navigation(id ?? -1)
    }

    const replace = (path, state) => {
        navigation(path, { state: { ...state }, replace: true })
    }

    return {
        historyState,
        navigate,
        goBack,
        replace,
    }
}

export const getBasePath = (string = '') => {
    let basePath = string.slice(0, string.lastIndexOf('/'))
    return basePath
}

