import { ACTION_TYPES } from "../constants";

const INITIAL_STATE = {
    Sabha: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        
        case ACTION_TYPES.SABHA: {
            return { ...state, Sabha: action.payload };
        }

        case ACTION_TYPES.ADD_SABHA: {
            return { ...state, Sabha: [action.payload, ...state.Sabha] };
        }

        case ACTION_TYPES.UPDATE_SABHA: {
            const updatedDetail = action.payload || {};
            const _SaintLeaders = state.Sabha.map((eachSabha) => {
                if (eachSabha._id + "" === updatedDetail._id + "") return updatedDetail;
                return eachSabha
            })
            return { ...state, Sabha: _SaintLeaders };
        }

        case ACTION_TYPES.DELETE_SABHA: {
            const updatedDetail = action.payload || {};
            const _SaintLeaders = state.Sabha.filter((eachSabha) => {
                return eachSabha._id + "" !== updatedDetail._id + ""
            })
            return { ...state, Sabha: _SaintLeaders };
        }
    }
    return state;
}
