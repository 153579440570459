import React, { Fragment, useEffect, useState } from "react";
import Icon from "@mdi/react";
import { Button } from "@material-tailwind/react";
import { mdiClose, mdiTrashCan } from "@mdi/js";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import moment from "moment-timezone";
import DateField from "../Common/Input/DateField";
import TextField from "../Common/Input/TextField";
import EventUpload from "../Common/UploadModal/UploadContent";
import { s3Url } from "../../services/api.service";
import DatePickerField from "../Common/Rsuite-Datepicker";

const EventBannerUpload = (props) => {
  const { open, setOpen, uploadEventBanner, selectedEvent, setSelectedEvent } =
    props;

  const [files, setFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [uploadedFile, setUploadedFile] = useState("");
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    setTitle(selectedEvent?.title || "");
    setStartDate(moment(selectedEvent).tz("utc").startOf("day").toISOString());
    setEndDate(selectedEvent?.effectiveEnd || null);
    if (selectedEvent) {
      setImagePreviews([`${s3Url}` + selectedEvent?.imageUrl]);
      setUploadedFile(selectedEvent?.imageUrl);
    }
  }, [selectedEvent]);

  const handleFileChange = (selectedFiles) => {
    selectedFiles = [selectedFiles[0]];
    setFiles(selectedFiles);

    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previews.push(e.target.result);
        if (previews.length === selectedFiles.length) {
          setImagePreviews([...imagePreviews, ...previews]);
        }
      };
      reader.readAsDataURL(selectedFiles[i]);
    }
  };

  const handleRemoveFile = (index) => {
    setUploadedFile("");
    const updatedFiles = [...files];
    const updatedPreviews = [...imagePreviews];
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setFiles(updatedFiles);
    setImagePreviews(updatedPreviews);
  };

  const disablePreviousDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to compare only dates
    return date.getTime() < today.getTime();
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          open={open}
          as="div"
          className="relative z-40"
          onClose={() => {}}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      Upload Event Banner
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow">
                          <div className="">
                            <div className="flex items-center mb-6">
                              <div className="mr-3">
                                <TextField
                                  id="banner-title"
                                  className="placeholder:gray100"
                                  placeholder="Banner Title"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </div>
                              <div className="mr-3">
                                <DatePickerField
                                  placeholder="Start Date"
                                  value={startDate ? new Date(startDate) : ""}
                                  onChange={(_date) =>
                                    setStartDate(
                                      moment(_date).startOf("day").toISOString()
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <DatePickerField
                                  placeholder="End Date"
                                  value={endDate ? new Date(endDate) : ""}
                                  onChange={(_date) =>
                                    setEndDate(
                                      moment(_date).startOf("day").toISOString()
                                    )
                                  }
                                  disabledDate={disablePreviousDates}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {imagePreviews.length > 0 && (
                          <div className="grid grid-cols-1 justify-items-center">
                            {imagePreviews.map((preview, index) => {
                              return (
                                <div
                                  key={"img" + index}
                                  className="max-w-md h-[200px] rounded-lg relative"
                                >
                                  <img
                                    src={preview}
                                    className="block rounded-lg w-full h-full object-contain object-center"
                                  />
                                  <div className="absolute rounded-lg w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.50)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                                    <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease]">
                                      <button
                                        onClick={() => handleRemoveFile(index)}
                                      >
                                        <Icon path={mdiTrashCan} size={1} />
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {imagePreviews.length === 0 && (
                          <EventUpload
                            handleFileChange={handleFileChange}
                            multiple={false}
                          />
                        )}
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => {
                            setOpen(false);
                            setSelectedEvent(false);
                            setImagePreviews([]);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() => {
                            if (uploadEventBanner) {
                              const updatedData = {
                                title,
                                effectiveStart: startDate,
                                effectiveEnd: endDate,
                                uploadedFile,
                              };
                              if (selectedEvent)
                                updatedData._id = selectedEvent._id;
                              uploadEventBanner(files, updatedData);
                            }
                          }}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EventBannerUpload;
