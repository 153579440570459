import React from "react";
import { Icon } from "@iconify/react";
import ColumnVisibilityMenu from "./ColumnVisibilityMenu";

const MemberSearchPannel = (props) => {
    const { _memberFilters, setMemberFilters, table, setIsAddMemberModalOpen, downloadProfilePhotos, downloadCsvFile, isDownloadOption, setDownloadType } = props;
    return (
        <>
            <div className="border-b border-stone-200 py-1.5 flex items-center justify-between w-full">
                <div className="w-60 relative">
                    <input
                        id="search-members"
                        type="text"
                        placeholder="Search Member"
                        autoComplete="off"
                        className="bg-gray200 text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
                        value={_memberFilters?.searchTerm}
                        onChange={(e) => { setMemberFilters('searchTerm', e.target.value) }}
                    />
                    <Icon icon="iconamoon:search-light" className="absolute right-2 top-[50%] translate-y-[-50%] text-lg text-[#969696]" />
                </div>
                <div className='flex gap-2'>
                    {
                        isDownloadOption &&
                        <div className='flex gap-2'>
                            <div className="py-1 flex items-center">
                            <button
                                className="inline-flex items-center text-white text-sm px-2 py-2 rounded-lg"
                                onClick={() => downloadCsvFile("XLSX")}
                            >
                                <Icon icon="vscode-icons:file-type-excel" />
                            </button>
                        </div>
                        <div className="py-1 flex items-center">
                            <button
                                className="inline-flex items-center text-white text-sm px-2 py-2 rounded-lg"
                                onClick={() => downloadCsvFile("pdf")}
                            >
                                <Icon icon="vscode-icons:file-type-pdf2" />
                            </button>
                        </div>
                        <div className="py-1 flex items-center">
                            <button
                                className="inline-flex items-center bg-primary text-white text-sm px-2 py-2 rounded-lg"
                                onClick={() => downloadProfilePhotos()}
                            >
                                Download Profile Photos
                            </button>
                        </div>
                        </div>
                    }
                    <div className="py-1 flex items-center gap-3">
                        <ColumnVisibilityMenu table={table} />
                        {setIsAddMemberModalOpen && <button
                            className="inline-flex items-center bg-primary text-white text-sm px-2 py-1.5 rounded-lg"
                            onClick={() => setIsAddMemberModalOpen(true)}
                        >
                            <Icon icon="ic:baseline-plus" className="inline mr-1" />

                            Add New Member
                        </button>}
                    </div>

                </div>
                </div>
                </>
                )
};

export default MemberSearchPannel;
