import axios from "axios";
import { api, domain } from "../../../services/api.service";

export const getMembersList = async ({ currentPage = 1, pageSize, queryParams, sortQuery }) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const response = await api.get(`/admin/users?page=${currentPage}&pageSize=${pageSize}&sort=${sortQuery}&${queryString}`);
    return response;
}

export const getCommonFieldList = async () => {
    const response = await api.get(`/admin/users/commonfields`);
    return response
}

export const unblockUserAccount = async (userId) => {
    const response = await api.post(`/admin/user/unblock/${userId}`);
    return response;
}

export const updateAttendeeOf = async (attendeeDetails) => {
    const response = await api.post(`/admin/user/update-sabha`, attendeeDetails);
    return response;
}

export const updateUserType = async (userId) => {
    const response = await api.post(`/admin/user/user-type`, {_id : userId});
    return response;
}

export const downloadProfilePhotos = async (queryParams) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const response = await axios.get(`${domain}report/images?${queryString}`, {
        headers: {
            "authorization": localStorage.getItem('token')
        },
        "responseType": 'blob',
    });
    return response;
}

export const downloadCsvFile = async (queryParams) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const response = await api.get(`report/user-details?${queryString}`);
    return response;
}