// config.js file

const SystemConfig = {
  baseUrl: "https://smvdev.swaminarayansurat.com/",
  devUrl: "https://smvtest.swaminarayansurat.com/",
  localUrl: "http://localhost:8081/",
  S3Url: "https://smvdocuments.s3.amazonaws.com/",
  devS3Url: "https://smvdocuments.s3.amazonaws.com/test/",
};

export default SystemConfig;
