import React, { useRef } from "react";
import { Button } from "@material-tailwind/react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";

export default function PrintComponent(props) {
  const { data } = props;
  let componentRef = useRef();

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <Button>Print!</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentToPrint mantraJapData={data} ref={(el) => (componentRef = el)} />
        </div>
      </div>
    </>
  );
}