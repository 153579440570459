import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose, mdiTrashCan } from "@mdi/js";
import lodash from "lodash";
import {
  toastr,
  uploadDataOnGoDaddyServer,
} from "../../../services/common.service";
import {
  addAudio,
  updateAlbumAudio,
  updateAlbumAudioMedia,
} from "./actionCreator";
import { s3Url } from "../../../services/api.service";

const AddVideoModal = ({
  open,
  setOpen,
  albumId,
  selectedAudio,
  refreshData,
}) => {
  const [audioDetail, setAudioDetail] = useState(selectedAudio || {});
  const [enabled, setEnabled] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [audioFile, setAudioFile] = useState("");
  const [imagePreviewFile, setImagePreviewFile] = useState("");
  const [audioPreviewFile, setAudioPreviewFile] = useState("");
  const [newImageUpload, setNewImageUpload] = useState(false);
  const [newAudioUpload, setNewAudioUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setCurrentDetail = (key, value) => {
    const newAudioDetail = lodash.cloneDeep(audioDetail);
    newAudioDetail[key] = value;
    setAudioDetail(newAudioDetail);
  };

  const handleAudioFileChange = (vfile) => {
    const file = vfile?.[0];
    if (file) {
      const fileExtension = file?.name.substring(
        file?.name.lastIndexOf(".") + 1,
        file?.name?.length
      );
      const validataionMessage = "Please choose correct audio format";
      if (file?.name?.length == 0) {
        setAudioFile("");
        toastr.error(validataionMessage);
        return;
      }
      if (fileExtension === "mp3") {
        setAudioFile(vfile);
        // preview audio
        const reader = new FileReader();
        reader.onload = (e) => {
          setAudioPreviewFile(e?.target?.result);
        };
        reader.readAsDataURL(file);
      } else {
        setAudioFile("");
        toastr.error(validataionMessage);
        return;
      }
    }
  };

  const handleImageFileChange = (vfile) => {
    const file = vfile?.[0];
    if (file) {
      const fileExtension = file?.name.substring(
        file?.name.lastIndexOf(".") + 1,
        file.name.length
      );
      const validataionMessage = "Please choose correct image format";
      if (file?.name?.length == 0) {
        setImageFile("");
        toastr.show(validataionMessage);
        return;
      }
      if (
        fileExtension === "jpg" ||
        fileExtension === "webp" ||
        fileExtension === "png" ||
        fileExtension === "jpeg" ||
        fileExtension === "gif" ||
        fileExtension === "bmp"
      ) {
        setImageFile(vfile);
        // preview image
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreviewFile(e?.target?.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImageFile("");
        toastr.show(validataionMessage);
        return;
      }
    }
  };

  const createAudio = async () => {
    setIsLoading(true);
    if (!audioDetail?.title) {
      toastr.show("Please enter title", "error");
      return;
    }
    if (!audioDetail?.singer) {
      toastr.show("Please enter singer", "error");
      return;
    }

    if (!imageFile?.[0]) {
      toastr.show("Please upload image", "error");
      return;
    }

    if (!audioFile?.[0]) {
      toastr.show("Please upload audio", "error");
      return;
    }

    const formData = new FormData();
    formData.append("title", audioDetail.title);
    formData.append("singer", audioDetail.singer);
    formData.append("albumId", albumId);
    formData.append("imagefile", imageFile?.[0]);
    formData.append("visibility", enabled);

    const newFormData = new FormData();
    newFormData.append("audios[0]", audioFile?.[0]);
    const audioUrl = await uploadDataOnGoDaddyServer(
      newFormData,
      "https://www.swaminarayansurat.com/api/upload-audio"
    );

    formData.append("audioURL", audioUrl?.[0]);
    const response = await addAudio(formData);

    if (response.data) {
      toastr.show("Successfully created!", "success");
      setOpen(false);
      setCurrentDetail({});
      setIsLoading(false);
      refreshData();
      resetForm();
    } else {
      setIsLoading(false);
      toastr.show("Something went wrong!", "error");
    }
  };

  const updateAudio = async () => {
    setIsLoading(true);
    if (!audioDetail?.title) {
      toastr.show("Please enter Title", "error");
      return;
    }

    if (!audioDetail?.singer) {
      toastr.show("Please enter Singer", "error");
      return;
    }

    let newData = {
      title: audioDetail.title || "",
      singer: audioDetail?.singer || "",
      _id: audioDetail?._id || "",
      visibility: enabled,
    };

    let response = null;
    const formData = new FormData();
    if (newImageUpload) {
      if (!imageFile?.[0]) {
        toastr.show("Please upload image", "error");
        return;
      }
      formData.append("imagefile", imageFile?.[0]);
    }

    if (newAudioUpload) {
      if (!audioFile?.[0]) {
        toastr.show("Please upload audio", "error");
        return;
      }
      formData.append("audiofile", audioFile?.[0]);
    }

    if (newImageUpload || newAudioUpload) {
      formData.append("title", audioDetail.title);
      formData.append("singer", audioDetail.singer);
      formData.append("visibility", enabled);
      formData.append("_id", audioDetail?._id || "");
      response = await updateAlbumAudioMedia(formData);
    } else {
      response = await updateAlbumAudio(newData);
    }

    if (response.data) {
      toastr.show("Successfully updated!", "success");
      setCurrentDetail({});
      refreshData();
      resetForm();
    } else {
      toastr.show("Something went wrong!", "error");
    }
    setOpen(false);
    setIsLoading(false);
  };

  const resetForm = () => {
    setOpen(false);
    setCurrentDetail({});
    setAudioDetail({});
    setEnabled(false);
    setIsLoading(false);
    setImageFile("");
    setAudioFile("");
    setImagePreviewFile("");
    setAudioPreviewFile("");
    setNewImageUpload(false);
    setNewAudioUpload(false);
  };

  useEffect(() => {
    setEnabled(selectedAudio?.visibility || false);
    if (selectedAudio?._id) {
      setAudioDetail(selectedAudio);
      setNewImageUpload(false);
      setNewAudioUpload(false);
    } else {
      setNewImageUpload(true);
      setNewAudioUpload(true);
    }
  }, [selectedAudio]);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      {selectedAudio?._id ? "Update" : "Add"} Audio
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                        resetForm();
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="max-w-md w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div className="mb-5">
                                <Input
                                  label="Title"
                                  className=" w-full"
                                  value={audioDetail?.["title"] || ""}
                                  onChange={(e) =>
                                    setCurrentDetail("title", e.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <Input
                                  label="Singer"
                                  className=" w-full"
                                  value={audioDetail?.["singer"] || ""}
                                  onChange={(e) =>
                                    setCurrentDetail("singer", e.target.value)
                                  }
                                />
                              </div>
                              {newImageUpload && (
                                <>
                                  <div className="mb-5">
                                    <Input
                                      label="Image Upload"
                                      accept=".jpg, .png, .jpeg, .gif, .webp, .bmp, .tif, .tiff|image/*"
                                      className="w-full"
                                      id="file"
                                      type="file"
                                      onChange={(e) =>
                                        handleImageFileChange(e?.target?.files)
                                      }
                                    />
                                  </div>
                                  <div className="mb-5">
                                    <img
                                      src={
                                        imagePreviewFile
                                          ? imagePreviewFile
                                          : "/assets/image/no-thumbnail.png"
                                      }
                                      className="w-[100px]"
                                    />
                                  </div>
                                </>
                              )}
                              {selectedAudio?._id && !newImageUpload && (
                                <div className="mb-5">
                                  <span>Image URL: </span>
                                  <a
                                    target="_blank"
                                    href={`${s3Url}${selectedAudio?.imageURL}`}
                                  >
                                    {" "}
                                    {selectedAudio?.imageURL}{" "}
                                  </a>
                                  <button
                                    className="capitalize text-base"
                                    onClick={() => setNewImageUpload(true)}
                                  >
                                    <Icon path={mdiTrashCan} size={1} />
                                  </button>
                                </div>
                              )}
                              {newAudioUpload && (
                                <>
                                  <div className="mb-5">
                                    <Input
                                      label="Audio File Upload"
                                      accept=".mp3,audio/*"
                                      className="w-full"
                                      id="file"
                                      type="file"
                                      onChange={(e) =>
                                        handleAudioFileChange(e?.target?.files)
                                      }
                                    />
                                  </div>
                                  <div className="mb-5">
                                    <audio src={audioPreviewFile} controls />
                                  </div>
                                </>
                              )}
                              {selectedAudio?._id && !newAudioUpload && (
                                <div className="mb-5">
                                  <span>Audio URL: </span>
                                  <a
                                    target="_blank"
                                    href={`${s3Url}${selectedAudio?.audioURL}`}
                                  >
                                    {" "}
                                    {selectedAudio?.audioURL}{" "}
                                  </a>
                                  <button
                                    className="capitalize text-base"
                                    onClick={() => setNewAudioUpload(true)}
                                  >
                                    <Icon path={mdiTrashCan} size={1} />
                                  </button>
                                </div>
                              )}
                              <div className="flex items-center">
                                <div className="mr-3 text-blue-gray-500">
                                  Visibility
                                </div>
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={`${
                                    enabled ? "bg-blue-600" : "bg-gray-200"
                                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${
                                      enabled
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => resetForm()}
                        >
                          Reset
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() =>
                            selectedAudio?._id ? updateAudio() : createAudio()
                          }
                          disabled={isLoading}
                        >
                          {selectedAudio?._id ? "Update" : "Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddVideoModal;
