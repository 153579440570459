import { mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { s3Url } from "../../../services/api.service";

const DesktopWallpapers = (props) => {
  const { wallpaperImages, removeWallpaperImage } = props;

  return (
    <div className="bg-white w-full h-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
      <div>
        <div className="flex-grow">
          <div className="flex-grow">
            <div className="-m-1 grid grid-cols-4 md:-m-2">
              {
                wallpaperImages?.webFileUrls?.length > 0 &&
                wallpaperImages?.webFileUrls?.map((eachUrl, index) => {
                  return (
                    <div className="flex md:p-2" key={index}>
                      <div className="w-full rounded-[10px] overflow-hidden h-full relative">
                        <img
                          alt="gallery"
                          className="block h-full w-full rounded-lg object-cover object-center"
                          src={`${s3Url}${eachUrl}`}
                        />
                        <div className="absolute w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                          <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                            <button onClick={() => removeWallpaperImage(eachUrl, 'desktop')}>
                              <Icon path={mdiTrashCan} size={1} />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopWallpapers;
