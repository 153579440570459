import React, { useCallback, useEffect, useMemo, useState } from "react";
import LoaderBar from "../../Common/LoaderBar";
import NewMember from "../add-member/NewMember";
import SetPassword from "./SetPassword";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { s3Url } from "../../../services/api.service";
import moment from "moment";
import { Icon } from "@iconify/react";
import MemberSearchPannel from "./MemberSearchPannel";
import * as XLSX from "xlsx";
import { downloadCsvFile } from "./actionCreator";
import { getProfileImage, toastr } from "../../../services/common.service";
import { SABHA_TYPES } from "../../../constants/common.constants";
import clsx from "clsx";
import { CircularProgressbar } from "react-circular-progressbar";

const visibility = {
  SMVNo: true,
  profileImgUrl: true,
  fullName: true,
  yuvaAttd: true,
  atmiyaAttd: true,
  address: false,
  mobile: true,
  city: false,
  state: false,
  country: false,
  birthDate: false,
  bloodGroup: false,
  gender: false,
  village: true,
};

const MemberTable2 = (props) => {
  const {
    isLoading,
    filteredMemberList,
    handleCollapsePofile,
    totalPages,
    memberDetails,
    setTableData,
    setPageSize,
    pageSize,
    currentPage,
    setCurrentPage,
    _memberFilters,
    setMemberFilters,
    toggleSort,
    downloadProfilePhotos,
    disablePaginationButton,
    membersWithoutPagination,
  } = props;
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isAddPasswordModalOpen, setIsAddPasswordModalOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [data, setData] = useState([]);
  const [columnPinning, setColumnPinning] = useState({
    right: ["action"],
  });

  const generateQueryParams = (filter) => {
    const queryParams = {};
    for (const key in filter) {
      if (key === "gender") {
        queryParams[key] = filter[key].value;
      } else if (key === "isActive") {
        if (filter[key] === "Active") {
          queryParams[key] = true;
        } else if (filter[key] === "InActive") {
          queryParams[key] = false;
        }
      } else if (Array.isArray(filter[key])) {
        let arrVal = filter[key].map((item) => item.value);
        if (arrVal.length > 0) {
          queryParams[key] = arrVal.join(",");
        }
      } else if (key === "birthMonth") {
        queryParams[key] = filter[key]?.value;
      } else {
        queryParams[key] = filter[key];
      }
    }
    return queryParams;
  };

  const [columnVisibility, setColumnVisibility] = useState(visibility);

  const DragAlongCell = ({ cell, trID }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
      id: cell.column.id,
    });

    const style = {
      opacity: isDragging ? 0.8 : 1,
      position: "relative",
      transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
      transition: "width transform 0.2s ease-in-out",
      width: cell.column.getSize(),
      zIndex: isDragging ? 1 : 0,
      padding: "8px",
    };

    return (
      <td
        style={style}
        ref={setNodeRef}
        className={`${
          trID === memberDetails?._id ? "bg-[#edf2fb] " : ""
        } last:text-center last:!sticky last:!w-[180px] last:bg-white last:z-10 last:!right-0`}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  const editUserPassword = useCallback(
    (_id) => {
      const user = filteredMemberList?.find(
        (member) => member?._id + "" === _id + ""
      );
      setUserDetail(user);
      setIsAddPasswordModalOpen(true);
    },
    [filteredMemberList, setUserDetail, setIsAddPasswordModalOpen]
  );

  const editUserDetail = useCallback(
    (_id) => {
      const user = filteredMemberList.find(
        (member) => member._id + "" === _id + ""
      );
      setUserDetail(user);
      setIsAddMemberModalOpen(true);
    },
    [filteredMemberList, setUserDetail, setIsAddMemberModalOpen]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "SMVNo",
        header: () => <span>SMV NO.</span>,
        cell: (info) => info.getValue(),
        id: "SMVNo",
        size: 150,
        maxSize: 150,
      },
      {
        accessorKey: "profileImgUrl",
        header: () => <span>Photo</span>,
        cell: (info) => {
          const profilePhoto = getProfileImage(
            info?.row?.original,
            info?.row?.original?.gender
          );
          return (
            <img
              className=" min-w-[48px] max-w-[48px] h-12 rounded-full mr-3 object-cover"
              alt="profile"
              src={profilePhoto}
            />
          );
        },
        id: "profileImgUrl",
        size: 150,
      },
      {
        accessorKey: "fullName",
        header: () => <span>Name.</span>,
        cell: (info) => (
          <div className="">
            {info.row.original?.firstName} {info.row.original?.middleName}{" "}
            {info.row.original?.lastName}
          </div>
        ),
        id: "fullName",
        // size: 450,
      },
      {
        accessorKey: "mobile",
        cell: (info) => info.getValue(),
        id: "mobile",
        size: 150,
      },
      {
        accessorKey: "yuvaAttd",
        header: () => <span>Yuvasabha Attd.</span>,
        cell: (info) => {
          const yuvaSabhaDetails = info.row.original?.attendeeOf?.find(
            (sabha) => sabha.sabhaName === "Yuva Sabha"
          );
          return (
            <>
              {yuvaSabhaDetails?.recentAttendance?.map((_attn, index) => {
                return (
                  <>
                    <Icon
                      key={"yuva" + info.row.original?._id + index}
                      size={"10px"}
                      className={`inline mx-0.5 ${
                        _attn?.present ? "text-[#16C046]" : "text-red-600"
                      }`}
                      icon="material-symbols:circle"
                    />
                  </>
                );
              })}
            </>
          );
        },
        id: "yuvaAttd",
        size: 150,
      },
      {
        accessorKey: "atmiyaAttd",
        header: () => <span>Atmiyasabha Attd.</span>,
        cell: (info) => {
          const atmiyaSabhaDetails = info.row.original?.attendeeOf?.find(
            (sabha) => sabha.sabhaName === "Atmiya Sabha"
          );

          return (
            <>
              {atmiyaSabhaDetails?.recentAttendance?.map((_attn, index) => {
                return (
                  <>
                    <Icon
                      key={"yuva" + info.row.original?._id + index}
                      size={"10px"}
                      className={`inline mx-0.5 ${
                        _attn?.present ? "text-[#16C046]" : "text-red-600"
                      }`}
                      icon="material-symbols:circle"
                    />
                  </>
                );
              })}
            </>
          );
        },
        id: "atmiyaAttd",
        size: 150,
      },
      {
        accessorKey: "address",
        cell: (info) => info.row.original.address?.society,
        id: "address",
        size: 150,
      },
      {
        accessorKey: "city",
        cell: (info) => info.row.original?.address?.city,
        id: "city",
        size: 150,
      },
      {
        accessorKey: "state",
        cell: (info) => info.row.original?.address?.state,
        id: "state",
        size: 150,
      },
      {
        accessorKey: "country",
        cell: (info) => info.row.original?.address?.country,
        id: "country",
        size: 150,
      },
      {
        accessorKey: "birthDate",
        cell: (info) =>
          info.getValue() ? moment(info.getValue()).format("DD-MM-YYYY") : "-",
        id: "birthDate",
        size: 150,
      },
      {
        accessorKey: "bloodGroup",
        cell: (info) => info.row.original?.bloodGroup,
        id: "bloodGroup",
        size: 150,
      },
      {
        accessorKey: "gender",
        cell: (info) => info.row.original?.gender,
        id: "gender",
        size: 150,
      },
      {
        accessorKey: "village",
        cell: (info) => info.row.original?.village,
        id: "village",
        size: 150,
      },
      {
        header: () => <span className="text-center">Actions</span>,
        cell: (info) => {
          return (
            <span className="flex items-center justify-center">
              <span className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer hover:border-black900 group hover:text-black900">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    editUserPassword(info.row.original?._id);
                  }}
                >
                  <Icon
                    icon="material-symbols:key"
                    className="text-gray400 text-xl transition-all duration-150 group-hover:text-black900"
                  />
                </button>
              </span>
            </span>
          );
        },

        id: "action",
        size: 150,
        enableHiding: false,
      },
    ],
    [editUserDetail, editUserPassword]
  );

  const [columnOrder, setColumnOrder] = useState(() =>
    columns?.map((c) => c.id)
  );

  const getTable = async () => {
    if (table !== undefined) {
      setTableData(table);
    }
  };

  useEffect(() => {
    setData(filteredMemberList || []);
    getTable();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMemberList]);

  const handlePageChange = (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
        break;

      case "next":
        if (currentPage < totalPages) {
          setCurrentPage((prev) => prev + 1);
        }
        break;

      default:
        break;
    }
  };

  const DraggableTableHeader = ({ header }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } =
      useSortable({
        id: header.column.id,
      });

    const style = {
      opacity: isDragging ? 0.8 : 1,
      position: "relative",
      transform: CSS.Translate.toString(transform),
      transition: "width transform 0.2s ease-in-out",
      whiteSpace: "nowrap",
      width: header.column.getSize(),
      zIndex: isDragging ? 1 : 0,
    };

    return (
      <th
        colSpan={header.colSpan}
        className="text-left font-semibold p-2 last:text-center last:!sticky last:w-[180px] last:z-10 last:!right-0 bg-[#F2F2F2]"
        ref={setNodeRef}
        style={style}
      >
        {header.isPlaceholder ? null : (
          <div
            className={`flex items-center ${
              header?.id === "action" ? "justify-center" : ""
            }`}
          >
            <span {...attributes} {...listeners} className="cursor-grab">
              {flexRender(header.column.columnDef.header, header.getContext())}
            </span>
            {header.column.columnDef.id === "SMVNo" ? (
              <div
                onClick={() => {
                  toggleSort("SMVNo");
                }}
              >
                <Icon icon="mdi:sort" className="ml-2 text-lg cursor-pointer" />
              </div>
            ) : header.column.columnDef.id === "birthDate" ? (
              <div
                onClick={() => {
                  toggleSort("birthDate");
                }}
              >
                <Icon icon="mdi:sort" className="ml-2 text-lg cursor-pointer" />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </th>
    );
  };

  const table = useReactTable({
    data,
    columns,
    initialState: {
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
    },
    // getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
  });

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <>
      {isLoading && <LoaderBar />}
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div>
          <div className={`flex`}>
            <MemberSearchPannel
              _memberFilters={_memberFilters}
              table={table}
              setMemberFilters={setMemberFilters}
              setIsAddMemberModalOpen={setIsAddMemberModalOpen}
              downloadProfilePhotos={downloadProfilePhotos}
            />
          </div>
          <div className="h-4" />
          <div className="overflow-auto h-[calc(100vh_-_225px)]">
            <table className="w-full member-table">
              <thead className="sticky top-0 bg-white z-10">
                {table.getHeaderGroups()?.map((headerGroup, index) => (
                  <tr
                    className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]"
                    key={index}
                  >
                    <SortableContext
                      items={columnOrder}
                      strategy={horizontalListSortingStrategy}
                    >
                      {headerGroup.headers?.map((header, index) => (
                        <DraggableTableHeader key={index} header={header} />
                      ))}
                    </SortableContext>
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows?.map((row, index) => {
                  const yuvaSabhaDetails = row?.original?.attendeeOf?.find(
                    (sabha) => sabha.sabhaName === "Yuva Sabha"
                  );
                  const atmiyaSabhaDetails = row?.original?.attendeeOf?.find(
                    (sabha) => sabha.sabhaName === "Atmiya Sabha"
                  );
                  const kishorSabhaDetails = row?.original?.attendeeOf?.find(
                    (sabha) => sabha.sabhaName === "Kishor Sabha"
                  );
                  const isHOD = row?.original?.attendeeOf?.find(
                    (item) => item.isHeadOfDepartment
                  );
                  const isHOL = row?.original?.attendeeOf?.find(
                    (item) => item.isHeadOfLeaders
                  );
                  console.log(
                    "🚀 ~ {table.getRowModel ~ isHOL:",
                    isHOL?.isHeadOfLeaders
                  );

                  return (
                    <tr
                      key={index}
                      // className={`border-l-4 border-b-gray-200 text-sm ${
                      //   yuvaSabhaDetails?.isLeader ||
                      //   atmiyaSabhaDetails?.isLeader ||
                      //   kishorSabhaDetails?.isLeader
                      //     ? "!bg-orange-200 !bg-opacity-20"
                      //     : "bg-white"
                      // }
                      //   ${
                      //     memberDetails?._id === row?.original?._id
                      //       ? " border-[#abc4ff]"
                      //       : "border-l-white"
                      //   }
                      //   `}

                      className={clsx(
                        (yuvaSabhaDetails?.isLeader ||
                          atmiyaSabhaDetails?.isLeader ||
                          kishorSabhaDetails?.isLeader) &&
                          "!bg-orange-200 !bg-opacity-20",
                        isHOD && "!bg-pink-100 !bg-opacity-20",
                        "border-l-4 border-b-gray-200 text-sm bg-white",
                        isHOL?.isHeadOfLeaders &&
                          "!bg-green-100 !bg-opacity-20",
                        memberDetails?._id === row?.original?._id
                          ? " border-[#abc4ff]"
                          : "border-l-white"
                      )}
                      onClick={() => {
                        handleCollapsePofile(row?.original);
                      }}
                    >
                      {row.getVisibleCells()?.map((cell) => (
                        <SortableContext
                          key={cell.id}
                          items={columnOrder}
                          strategy={horizontalListSortingStrategy}
                        >
                          <DragAlongCell
                            cell={cell}
                            trID={row?.original?._id}
                          />
                        </SortableContext>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-end gap-2 mt-0 sticky p-2 px-4 bg-white border-t-2 border rounded-md">
            <button
              className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
              onClick={() => handlePageChange("prev")}
              disabled={currentPage === 1 || disablePaginationButton}
            >
              <Icon icon="lucide:chevron-left" className="text-xl" />
            </button>
            <button
              className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
              onClick={() => handlePageChange("next")}
              disabled={currentPage === totalPages || disablePaginationButton}
            >
              <Icon icon="lucide:chevron-right" className="text-xl" />
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {currentPage} of {totalPages}
              </strong>
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="outline-none p-1 border-blue-100 border-b-2 border rounded-lg py-0.5 "
            >
              {[10, 20, 30, 40, 50].map((page) => (
                <option key={page} value={page}>
                  Show {page}
                </option>
              ))}
            </select>
          </div>
        </div>
      </DndContext>
      {isAddMemberModalOpen && (
        <NewMember
          setIsAddMemberModalOpen={setIsAddMemberModalOpen}
          userDetail={userDetail}
        />
      )}

      {isAddPasswordModalOpen && (
        <SetPassword
          open={isAddPasswordModalOpen}
          memberId={userDetail._id}
          closeMemeberModel={() => setIsAddPasswordModalOpen(false)}
        />
      )}
    </>
  );
};

export default MemberTable2;
