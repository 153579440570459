import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import lodash from "lodash";
import CreatableMultiselectMain from "../../Common/Input/CreatableMultiselectMain";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import Textarea from "../../Common/Input/Textarea";
import { mdiClose } from "@mdi/js";
import { toastr } from "../../../services/common.service";
import { createPlaylist, updatePlaylist } from "./actionCreator";

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const defaultOptions = [
  createOption("Katha"),
  createOption("Kirtan"),
  createOption("Dhun"),
  createOption("shorts"),
];

const CreatePlaylistModal = ({ open, setOpen, playlist, setPlaylist, selectedPlaylist }) => {
  const [value, setValue] = useState();
  const [options, setOptions] = useState(defaultOptions);
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
    }, 1000);
  };

  const [description, setDescription] = useState("");
  const [playlistDetail, setPlaylistDetail] = useState(selectedPlaylist || {});

  const setCurrentDetail = (key, value) => {
    const newLiveDetail = lodash.cloneDeep(playlistDetail);
    newLiveDetail[key] = value;
    setPlaylistDetail(newLiveDetail);
  }

  const createLive = async () => {
    if (!playlistDetail?.name) {
      toastr.show("Please enter Playlist Name", "error");
      return;
    }
    if (!playlistDetail?.vakta) {
      toastr.show("Please enter Vakta", "error");
      return;
    }
    if (!value) {
      toastr.show("Please enter Category", "error");
      return;
    }
    let newData = {
      ...playlistDetail,
      description,
      category: value?.value ?? '',
      visibility: enabled,
      position: playlist?.length ?? 0,
    }
    setIsLoading(true);
    const response = await createPlaylist(newData);
    if (response.data) {
      toastr.show("Successfully created!", "success");
      setPlaylist((prevValue) => {
        return [...prevValue, response.data]
      });
      setOpen(false);
      setCurrentDetail({});
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toastr.show("Something went wrong!", "error");
    }
  }

  const updateLive = async () => {
    if (!playlistDetail?.name) {
      toastr.show("Please enter Name", "error");
      return;
    }
    if (!playlistDetail?.vakta) {
      toastr.show("Please enter Vakta", "error");
      return;
    }
    let newData = {
      name: playlistDetail.name || null,
      vakta: playlistDetail?.vakta || null,
      description,
      category: value?.label ?? '',
      visibility: enabled,
      _id: selectedPlaylist._id
    }
    const response = await updatePlaylist(newData);
    if (response.data) {
      toastr.show("Successfully updated!", "success");
      const newList = playlist
        .map(live => {
          if (live._id + "" === selectedPlaylist._id + "") {
            return { ...live, ...newData }
          }
          return { ...live };
        })
      setPlaylist(newList);
      setOpen(false);
      setCurrentDetail({})
    } else {
      toastr.show("Something went wrong!", "error");
    }
  }

  const resetForm = () => {
    setPlaylistDetail({});
    setEnabled(false);
    setDescription("");
    setValue()
  }

  useEffect(() => {
    setEnabled(selectedPlaylist?.visibility || false);
    setPlaylistDetail(selectedPlaylist);
    setDescription(selectedPlaylist?.description || '')
    const newOption = createOption(selectedPlaylist?.category ?? '');
    setValue(newOption)
  }, [selectedPlaylist])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" open={open} className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      {selectedPlaylist?._id ? "Update" : "Create"} Playlist
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="max-w-md w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div className="mb-5">
                                <Input
                                  label="Playlist Name"
                                  className=" w-full"
                                  value={playlistDetail?.['name'] || ""}
                                  onChange={(e) => setCurrentDetail('name', e.target.value)}
                                />
                              </div>
                              <div className="mb-5">
                                <Input
                                  label="Vakta"
                                  className=" w-full"
                                  value={playlistDetail?.['vakta'] || ""}
                                  onChange={(e) => setCurrentDetail('vakta', e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <Textarea
                                  label="Description"
                                  setDescription={setDescription}
                                  value={description}
                                />
                              </div>

                              <div className="mb-5">
                                <CreatableMultiselectMain
                                  className="placeholder:gray100"
                                  id="25"
                                  isMulti={false}
                                  placeholder="Category"
                                  isLoading={isLoading}
                                  value={value}
                                  handleCreate={handleCreate}
                                  onChange={(newValue) => setValue(newValue)}
                                  options={options}
                                />
                              </div>
                              <div className="flex items-center">
                                <div className="mr-3 text-blue-gray-500">Visibility</div>
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={`${enabled ? "bg-blue-600" : "bg-gray-200"
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${enabled
                                      ? "translate-x-6"
                                      : "translate-x-1"
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => resetForm()}
                        >
                          Reset
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() => selectedPlaylist?._id ? updateLive() : createLive()}
                          disabled={isLoading}
                        >
                          {selectedPlaylist?._id ? "Update" : "Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CreatePlaylistModal;
