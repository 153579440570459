import React from "react";

export default function AreaField(props) {
  const {
    name,
    id,
    onChange,
    placeholder,
    disabled,
    val,
    className,
    label,
    rows,
  } = props;
  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={id}
          className="text-sm text-gray300 font-semibold mb-2 inline-block"
        >
          {label} <span className="text-red400">*</span>
        </label>
      )}
      <textarea
        name={name}
        id={id}
        rows={rows ? rows : "2"}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        value={val}
        className={`min-h-[80px] text-sm w-full border border-blue-gray-200 text-black900 outline-none px-2.5 py-2 rounded-lg ${className} placeholder:text-black900  placeholder:text-sm caret-stone-400 placeholder:pl-0.5`}
      />
    </div>
  );
}
