import React from "react";
import { Checkbox, Radio } from "@material-tailwind/react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import {
  GROUP_NUMBERS,
  LEADER_PRIORITY,
  SABHA_TYPES,
} from "../../../constants/common.constants";
import CreatableMultiselectMain from "../../Common/Input/CreatableMultiselectMain";

const GroupInfo = (props) => {
  const { memberDetails, setMemberDetails, addSabhaListToMember } = props;
  const [isCollapsed, setIsCollapsed] = useState({});

  const handleCollapse = (tabKey) => {
    setIsCollapsed((prevState) => ({
      ...prevState,
      [tabKey]: !prevState[tabKey],
    }));
  };

  const attendeeOf = memberDetails.attendeeOf || [];

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex">
          <div className="mr-6">
            <Radio
              name="active"
              label="Active"
              checked={memberDetails.isActive}
              onChange={() => setMemberDetails("isActive", true)}
            />
          </div>
          <div>
            <Radio
              name="inactive"
              label="Inactive"
              checked={!memberDetails.isActive}
              onChange={() => setMemberDetails("isActive", false)}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        {SABHA_TYPES.map((eachSabha, indx) => {
          const attendeeDetails = attendeeOf.find(
            (d) => d.sabhaId === eachSabha._id
          );
          return (
            <div className="mt-3">
              <div
                className="px-2 py-3 flex items-center justify-between bg-gray200 rounded-lg cursor-pointer select-none"
              >
                <div className="flex items-center">
                  <div className="inline-block ml-3">
                    <Checkbox
                      id={eachSabha.name + indx}
                      checked={attendeeDetails?.groupNo}
                    />
                  </div>
                  <div className="font-semibold text-lg ml-2">
                    {eachSabha.name}
                  </div>
                </div>
                <div>
                  <Icon
                    path={isCollapsed.tab1 ? mdiChevronDown : mdiChevronUp}
                    size="32px"
                    onClick={() => handleCollapse(eachSabha._id)}
                    className={`text-black900 transition-all duration-150 mr-3 ${isCollapsed ? "rotate-180" : "rotate-0"
                      }`}
                  />
                </div>
              </div>

              <div
                className={`mr-4 p-4 ${isCollapsed[eachSabha._id] ? "flex" : "hidden"
                  }`}
              >
                <div className="w-1/2 p-2 border-r border-gray-200">
                  <div className="">
                    <div className="mt-4 w-[250px]">
                      <CreatableMultiselectMain
                        id="2"
                        className="!h-9 placeholder:text-black900"
                        required={true}
                        placeholder="Select Group Number"
                        options={GROUP_NUMBERS}
                        value={
                          attendeeDetails?.groupNo
                            ? {
                              label: attendeeDetails?.groupNo,
                              value: attendeeDetails?.groupNo,
                            }
                            : null
                        }
                        onChange={(value) =>
                          addSabhaListToMember(
                            eachSabha,
                            "groupNo",
                            value?.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-1/2 p-2">
                  <div>
                    {/* <div className="ml-3">
                      <Checkbox
                        id={"subleader" + indx}
                        label="Sub-Leader"
                        checked={attendeeDetails?.isSubLeader}
                        onChange={() =>
                          addSabhaListToMember(
                            eachSabha,
                            "isSubLeader",
                            !attendeeDetails?.isLeader
                          )
                        }
                      />
                    </div> */}
                    <div className="ml-3">
                      <Checkbox
                        id={"gleader" + indx}
                        label="Group Leader"
                        checked={attendeeDetails?.isLeader}
                        onChange={() =>
                          addSabhaListToMember(
                            eachSabha,
                            "isLeader",
                            !attendeeDetails?.isLeader
                          )
                        }
                      />
                    </div>
                    <div className="ml-3">
                      <CreatableMultiselectMain
                        id="2"
                        className="!h-9 placeholder:text-black900"
                        required={true}
                        placeholder="Select Leader Priotiry"
                        options={LEADER_PRIORITY}
                        value={
                          attendeeDetails?.priority
                            ? {
                              label: attendeeDetails?.priority,
                              value: attendeeDetails?.priority,
                            }
                            : null
                        }
                        onChange={(value) =>
                          addSabhaListToMember(
                            eachSabha,
                            "priority",
                            value?.value
                          )
                        }
                      />
                    </div>
                    <div className="ml-3">
                      <Checkbox
                        id={"HOL" + indx}
                        label="HOL"
                        checked={attendeeDetails?.isHeadOfLeaders}
                        onChange={() =>
                          addSabhaListToMember(
                            eachSabha,
                            "isHeadOfLeaders",
                            !attendeeDetails?.isHeadOfLeaders
                          )
                        }
                      />
                    </div>
                    {/* <div className="ml-3">
                      <Checkbox
                        id={"up-pramukh" + indx}
                        label="Up. Pramukh"
                        checked={attendeeDetails?.isPramukh}
                        onChange={() =>
                          addSabhaListToMember(
                            eachSabha,
                            "isPramukh",
                            !attendeeDetails?.isLeader
                          )
                        }
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupInfo;
