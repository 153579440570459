import lodash from "lodash";
import { ACTION_TYPES } from "../constants";

const INITIAL_STATE = {
    NewMemberList: []
};

const getList = ({state, NewMemberList}) => {
    return {
        ...state,
        NewMemberList: NewMemberList,
    };
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        
        case ACTION_TYPES.SET_NEW_MEMBERS_LIST: {
            const NewMemberList = action.payload;
            return getList({state, NewMemberList : NewMemberList});
        }

        case ACTION_TYPES.ADD_NEW_MEMBER: {
            const NewMemberList = [...state.NewMemberList, action.payload];
            return getList({state, NewMemberList: NewMemberList});
        }

        case ACTION_TYPES.UPDATE_NEW_MEMBER: {
            const updatedDetail = action.payload || {};
            const NewMemberList = state.NewMemberList.map((eachMember) => {
                if (eachMember._id + "" === updatedDetail._id + "") {
                    return updatedDetail;
                }
                return eachMember
            })
            return getList({state, NewMemberList: NewMemberList});
        }

        case ACTION_TYPES.REMOVE_NEW_MEMBER: {
            const { _id } = action.payload || {};
            const NewMemberList = state.NewMemberList.filter((eachMember) => {
                return eachMember._id !== _id
            })
            return getList({ state, NewMemberList: NewMemberList });
        }
    }
    return state;
}
