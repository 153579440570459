import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-tooltip/dist/react-tooltip.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import "./css/toastr.css";
import "react-image-crop/dist/ReactCrop.css";
import "rc-slider/assets/index.css";
import "react-circular-progressbar/dist/styles.css";
import "rsuite/dist/rsuite.min.css";

import moment from "moment-timezone";
moment.tz.setDefault("Asia/Calcutta");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
