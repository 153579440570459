import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import lodash from "lodash";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose } from "@mdi/js";
import { toastr } from "../../../services/common.service";
import { updateFlag } from "./ActionCreater";
import { useDispatch } from "react-redux";
import { ACTION_TYPES } from "../../../redux/constants";

const UpdateMemberOfName = ({ open, setOpen, setLiveList, liveList, currentFlag }) => {
  const dispatch = useDispatch();
  const [currentFlagDetail, setCurrentFlagDetail] = useState(currentFlag || {});

  const setCurrentLiveDetail = (key, value) => {
    const newLiveDetail = lodash.cloneDeep(currentFlagDetail);
    newLiveDetail[key] = value;
    setCurrentFlagDetail(newLiveDetail);
  }

  const updateLive = async () => {
    if (!currentFlagDetail?.name) {
      toastr.show("Please enter Name", "error");
      return;
    }
    let newData = {
      name: currentFlagDetail?.name || null,
      _id: currentFlag._id
    }
    const response = await updateFlag(newData);
    console.log(response.data);
    if (response.data) {
      toastr.show("Successfully updated!", "success");
      const newList = liveList.map(live => {
        if (live._id + "" === currentFlag._id + "") {
          return { ...live, ...newData }
        }
        return { ...live };
      })
      dispatch({ type: ACTION_TYPES.SET_MEMBER_FLAG, payload: newList });
      setLiveList(newList);
      setOpen(false);
      setCurrentLiveDetail({})
    } else {
      toastr.show("Something went wrong!", "error");
    }
  }

  const resetForm = () => {
    setCurrentFlagDetail([]);
    // setEnabled(false);
    // setDescription("");
  }

  useEffect(() => {
    setCurrentFlagDetail(currentFlag);
  }, [currentFlag])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      {currentFlag?._id ? "Update" : "Create"} Live Video Link
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                        setCurrentLiveDetail({});
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="max-w-md w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div className="mb-5">
                                <Input
                                  label="Title Name"
                                  className=" w-full"
                                  value={currentFlagDetail?.['name'] || ""}
                                  onChange={(e) => setCurrentLiveDetail('name', e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        {!currentFlag?._id &&
                          <Button
                            color="red"
                            variant="outlined"
                            className="capitalize text-base"
                            onChange={() => resetForm()}
                          >
                            Reset
                          </Button>}
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() => updateLive()}
                        >
                          {currentFlag?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UpdateMemberOfName;
