import React from "react";

const LoaderBar = () => {
    return (
        <div className="fixed inset-0 bg-black900 w-full h-full bg-opacity-30 backdrop-blur-[2px] z-[5000] transition-all duration-100">
            <div className="h-full w-full grid place-items-center">
                <div className="spinner"></div>
            </div>
        </div>
    )
}

export default LoaderBar;