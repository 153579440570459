import { Icon } from "@iconify/react";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { api, s3Url } from "../../services/api.service";

const Photo = () => {
    const [imglist, setImageList] = useState([]);

    useEffect(() => {
        getProfileImages();
    }, []);

    const getProfileImages = async () => {
        try {
            const res = await api.get("/admin/users/get-profile-img");
            setImageList(res.data);
        } catch (error) {
            setImageList([]);
            console.log(error);
        }
    };

    const deleteImage = async (id) => {
        try {
            const res = await api.delete(`/admin/users/delete-profile-img/${id}`);
            getProfileImages();
        } catch (error) {
            setImageList([]);
            console.log(error);
        }
    }

    return (
        <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
            <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
                <div className="text-[25px] text-[#212121]">Member New Photo</div>
            </div>
            <div className="bg-white w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
                <div className="flex-grow">
                    <div className="flex-grow">
                        <div className="-m-1 grid grid-cols-5 gap-4 md:-m-2">
                            {imglist?.map((item, indx) => {
                                return (
                                    <div className="w-full max-w-sm relative bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="flex flex-col items-center m-4">
                                            <div key={"darshanimg" + indx}>
                                                <div className="w-28 mb-3 rounded-full overflow-hidden h-28 relative">
                                                    <img className="w-28 h-28 mb-3 rounded-full shadow-lg object-cover" src={`${s3Url}${item?.profileImgUrl}`} alt="Bonnie image" />
                                                </div>
                                            </div>
                                            {/* <h5 className="text-sm text-gray-500 dark:text-gray-400">{item.name}</h5> */}
                                            <span className="text-sm text-gray-500 dark:text-gray-400">SMV No. :- {item.SMVNo}</span>
                                        </div>
                                        <div className="absolute w-full rounded-lg h-full flex items-center justify-center gap-x-5 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                                            <a className="w-6 h-6 flex items-center justify-center p-1 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                                                <button onClick={() => saveAs(`${s3Url}${item?.profileImgUrl}`, "new-entry.jpg")}>
                                                    <Icon icon="fe:download" className="text-2xl 2xl:text-4xl" color="#fff" />
                                                </button>
                                            </a>
                                            <a className="w-6 h-6 flex items-center justify-center p-1 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                                                <button
                                                    onClick={() => {
                                                        deleteImage(item._id);
                                                    }}
                                                >
                                                    <Icon icon="material-symbols:delete" className="text-2xl 2xl:text-4xl" color="#fff" />
                                                </button>
                                            </a>
                                        </div>
                                    </div>


                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Photo;