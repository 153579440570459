import { api } from "../../../services/api.service";

export const getPlayList = async () => {
  const response = await api.get("admin/all-playlist");
  return response;
};

export const createPlaylist = async (data) => {
  const response = await api.post("admin/playlist/create", data);
  return response;
};

export const updatePlaylist = async (data) => {
  const response = await api.post("admin/playlist/update", data);
  return response;
};

export const deletePlaylist = async (id) => {
  const response = await api.post("admin/playlist/delete", { id });
  return response;
};

export const getVideoByPlayList = async (id) => {
  const response = await api.get(`admin/playlist/${id}`);
  return response;
};

export const addVideoPlaylist = async (data) => {
  const response = await api.post(`admin/playlist/add-video`, data);
  return response;
};

export const updateVideoPlaylist = async (data) => {
  const response = await api.post(`admin/playlist/update-video`, data);
  return response;
};

export const reorderVideoPlaylist = async (data) => {
  const response = await api.post(`admin/playlist/reorder-video`, data);
  return response;
};

export const deleteVideoPlaylist = async (data) => {
  const response = await api.post(`admin/playlist/delete-video`, data);
  return response;
};

export const changePlaylistVisibility = async (data) => {
  const response = await api.post(`admin/playlist/visibility`, data);
  return response;
};

export const changeVideoVisibility = async (data) => {
  const response = await api.post(`admin/playlist-video/visibility`, data);
  return response;
};

export const updatePlaylistPosition = async (data) => {
  const response = await api.post(
    `admin/playlist/updatePlaylistPosition`,
    data
  );
  return response;
};
