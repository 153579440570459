import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { PAGES } from '../../../constants/common.constants';

export default function MainLayout({children}) {
  const navigate = useNavigate();
  const [toggleSidebar, setToggleSidebar] = useState(true);
  
  useEffect(() => {
    const userToken = localStorage.getItem("token");
    if (!userToken) navigate(PAGES.LOGIN);
  }, []);
  
  return (
    <div className={`mt-[56px] ${toggleSidebar ? 'ml-[223px]' : 'ml-0'} overflow-hidden ease-out duration-[0.3s]`}>
      <Header toggleSidebar={toggleSidebar} setToggleSidebar={setToggleSidebar} />
      <Sidebar toggleSidebar={toggleSidebar} />
      {children}
    </div>
  )
}
