import React from 'react'
import TextField from '../../Common/Input/TextField'
import Icon from '@mdi/react'
import { mdiAccountEdit, mdiBarcodeScan, mdiFileEdit, mdiTrayArrowUp } from '@mdi/js'
import { useState } from 'react'

const Scan = () => {

  return (
    <div className='p-4 h-[calc(100vh_-_81px)]'>
      <div className=' flex flex-col h-full'>
        <div>
          <div className='flex items-center justify-center'>
            <div className='max-w-[200px]'>
              <TextField type="number" placeholder="Enter code" />
            </div>
            <button className='inline-flex items-center ml-3 bg-primary text-white text-sm px-2 py-2 rounded-lg' > <Icon path={mdiBarcodeScan} size={0.7} className='inline mr-1' /> Apply</button>
          </div>
        </div>
        <div className='flex-grow flex flex-col justify-center'>
          <div>
            <div className='max-w-2xl mx-auto'>
              <div className=' w-full mx-auto h-full'>
                <div className='relative w-[250px] h-[250px] rounded-full mx-auto group'>
                  <img src='/assets/image/member.jpg' className='w-full h-full rounded-full mx-auto bg-opacity-5' />
                  <div className='absolute bottom-4 border-[3px] border-white cursor-pointer right-4 w-10 h-10 grid place-items-center rounded-full bg-black'>
                    <Icon path={mdiAccountEdit} size="24px" color={"#fff"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='my-[3%] text-center'>
            <button className='inline-flex items-center bg-primary text-white text-sm px-2 py-2 rounded-lg'>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Scan