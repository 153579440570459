import React from "react";
import { CreateSabha, Members, Scan } from "../../../Components";
import { useRoutes } from "react-router-dom";
import AddLeaderSaint from "../../../Components/attendance/saint";
import Permissions from "../../../Components/permissions";
import MemberOf from "../../../Components/attendance/memberOf";

export default function AttendanceRouter() {
  const AttendanceRoute = [
    {
      path: "members",
      element: <Members />,
    },
    {
      path: "createSabha",
      element: <CreateSabha />,
    },
    {
      path: "scan",
      element: <Scan />,
    },
    {
      path: "saints",
      element: <AddLeaderSaint />,
    },
    {
      path: "permissions",
      element: <Permissions />,
    },
    {
      path: "member-of",
      element: <MemberOf />
    }
  ];
  const AttendanceRouter = useRoutes(AttendanceRoute);
  return AttendanceRouter;
}
