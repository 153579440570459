import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { Checkbox, Typography } from "@material-tailwind/react";
import { mdiAccountKey } from "@mdi/js";
import Icon from "@mdi/react";

import TextField from "../Common/Input/TextField";
import { CONFIGURABLE_PERMISSIONS } from "./constants";
import { updateUserPermission } from "./actionCreator";
import { toastr } from "../../services/common.service";

const AddUserModal = (props) => {
  const { open, handleCloseModal, getUserPermissionFun, selectedUser } = props;

  const [SMVNo, setSMVNo] = useState("");
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    setSMVNo(selectedUser?.SMVNo || "");
    setPermissions(selectedUser?.permissions || []);
  }, [selectedUser]);

  const updateUserPermissionsFun = async () => {
    try {
      if (!SMVNo) {
        toastr.show("Enter SMV No", "error");
        return;
      }

      const permissionsData = {
        SMVNo: Number(SMVNo),
        permissions,
      }
      const result = await updateUserPermission(permissionsData);
      if (result.data) {
        toastr.show("Success !!", "success");
        setSMVNo("");
        setPermissions([]);
      }
      handleCloseModal();
      getUserPermissionFun();
    } catch (err) {
      toastr.show("Failed !!", "error");
    }
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" open={open} className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl py-5 flex items-center font-semibold leading-6 text-gray-900"
                  >
                    <Typography className="text-center">
                      <Icon
                        path={mdiAccountKey}
                        size={"40px"}
                        className="text-primary inline-block"
                      />
                    </Typography>
                    <Typography className="ml-5 text-2xl text-primary font-semibold">Add User</Typography>
                  </Dialog.Title>
                  <div>
                    <div className="grid grid-cols-2 gap-5">
                      <div className="">
                        <TextField
                          id="SMVNo"
                          type="number"
                          className="placeholder:gray100"
                          required={true}
                          label="SMV No"
                          placeholder="Enter SMV No"
                          value={SMVNo}
                          onChange={(e) => setSMVNo(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="my-5">
                      <h2 className="text-sm text-gray300 font-semibold mb-2 inline-block">
                        Role
                      </h2>
                      <div className="grid grid-cols-2">
                        {Object.keys(CONFIGURABLE_PERMISSIONS).map((permissionKey, indx) => {
                          const permissionObj = CONFIGURABLE_PERMISSIONS[permissionKey];
                          return (
                            <Checkbox
                              key={"permission" + indx}
                              id={permissionKey}
                              color="blue"
                              label={permissionObj.title}
                              checked={permissions.includes(permissionKey) || false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setPermissions([...permissions, permissionKey]);
                                } else {
                                  const _permissions = [...permissions];
                                  setPermissions(_permissions.filter((d) => d !== permissionKey));
                                }
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>
                    <div className="mt-5 flex justify-end">
                      <button
                        type="button"
                        className="mr-3 inline-flex justify-center rounded-md font-semibold bg-white px-4 py-2 text-sm  text-red-600 hover:text-red-600 focus:outline-none"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary focus:outline-none"
                        onClick={() => updateUserPermissionsFun()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddUserModal;
