import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react'
import { useReactNavigator } from '../../../utils/reactNavigator';
import { useLocation } from 'react-router-dom';
import { RouteLink } from "../../Data/Routelink"
import { verifyUserPermission } from '../../../services/common.service';

export default function Sidebar(props) {
  const { toggleSidebar } = props;
  const { navigate } = useReactNavigator()
  const [activeAccordion, setActiveAccordion] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const currentRoute = location.pathname;

    RouteLink.map((item, index) => {
      if (item.subMenu.some(subItem => subItem.link === currentRoute)) {
        setActiveAccordion(index + 1)
      }
    })

  }, [])

  return (
    <div className={`fixed inset-y-0 w-56 ${toggleSidebar ? 'left-0' : '-left-56'} bg-white ease-out overflow-auto duration-[0.3s] border-x mt-[73px] border-stone-200`}>
      <div>
        {
          RouteLink.map((item, index) => {
            const subMenus = item.subMenu.filter((menu) => {
              if (!menu?.permission) return true;
              return verifyUserPermission(menu.permission);
            });

            if (!subMenus?.length) return;

            return (
              <div className='mb-2' key={index} >
                <div className={`select-none flex justify-between items-center ${activeAccordion === index + 1 ? 'sidebar-link text-primary bg-menu-gredient' : 'text-stone-600'} relative cursor-pointer py-2 pr-4 pl-5  font-[500] capitalize transition`} onClick={() =>
                  setActiveAccordion((prevActive) =>
                    prevActive === index + 1 ? null : index + 1
                  )
                }>
                  {item.title}
                  <Icon path={activeAccordion === index + 1 ? mdiChevronUp : mdiChevronDown} size={1} color={`${activeAccordion === index + 1 ? '#2196f3' : '#57534e'}`} />
                </div>
                {
                  activeAccordion === index + 1 &&
                  <div>
                    <ul className=' ml-5 mt-3'>
                      {
                        subMenus.map((link, subIndex) => {
                          return (
                            <div className={`px-2 py-1.5 flex items-start mb-2 ${location.pathname.includes(link?.link) ? 'text-primary' : 'text-stone-400'} text-sm font-[500] capitalize cursor-pointer`} key={subIndex} onClick={() => {
                              navigate(link?.link)
                            }}><span className='mr-2'>&#8226;</span>{link.title}</div>
                          )
                        })
                      }
                    </ul>
                  </div>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
