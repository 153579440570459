import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Icon } from "@iconify/react";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { getProfileImage, toastr } from "../../services/common.service";
import MemberFormModal from "./MemberFormModal"
import { deleteUserSubmittedForm, getUsersSubmittedForm } from "./actionCreator";
import DeleteModal from "../Common/DeleteModal/DeleteModal";



const columnHelper = createColumnHelper();

const ViewApplicantUsers = () => {
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [selectedForm, setSelectedForm] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState("")
    const [openMemberForm, setOpenMemberForm] = useState(false);

    const location = useLocation();
    const formData = location.state?.data;

    const openMemberFormLink = () => {
        setOpenMemberForm(!openMemberForm);
    };


    const getFormData = async () => {
        const usersData = await getUsersSubmittedForm(formData?._id)
        setData(usersData.data || []);
    };

    useEffect(() => {
        getFormData();
    }, []);

    const openCreateLinkModal = (_id = null) => {
        if (_id) {
            const form = data.find(f => f._id + "" === _id + "");
            setSelectedForm(form);
        } else {
            setSelectedForm({});
        }
        setOpenMemberForm(!openMemberForm);
    };

    const handleCloseModal = async () => {
        setShowDeleteModal(false);
        try {
            const res = await deleteUserSubmittedForm(deleteId);
            if(res.data) {
                toastr.show("Deleted Successfully", "success");
                const newList = data?.filter(
                    (f) => f._id + "" !== deleteId + ""
                  );
                setData(newList);
            }
        } catch (error) {
            toastr.show("Something Went Wroing", "error")
        }
        setDeleteId("");
    }

    const handleOpenDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(!showDeleteModal);
    };


    const columns = [
        {
            accessorKey: "SMVNo",
            header: () => <span>SMV NO.</span>,
            cell: (info) => info.getValue(),
            id: "SMVNo",
            size: 150,
            maxSize: 150,
        },
        {
            accessorKey: "profileImgUrl",
            header: () => <span>Photo</span>,
            cell: (info) => {
                const profilePhoto = getProfileImage(
                    info?.row?.original,
                    info?.row?.original?.gender
                );
                return (
                    <img
                        className=" min-w-[48px] max-w-[48px] h-12 rounded-full mr-3 object-cover"
                        alt="profile"
                        src={profilePhoto}
                    />
                );
            },
            id: "profileImgUrl",
            size: 150,
        },
        {
            accessorKey: "name",
            header: () => <span>Name.</span>,
            cell: (info) => (
                <div className="">
                    {info.row.original?.userId.firstName} {info.row.original?.userId.middleName}{" "}
                    {info.row.original?.userId.lastName}
                </div>
            ),
            id: "name",
            // size: 450,
        },
        {
            accessorKey: "daysToJoinEvent",
            cell: (info) => info.getValue(),
            id: "daysToJoinEvent",
            size: 150,
        },
        columnHelper.display({
            header: () => <span className="text-center">Actions</span>,
            id: 'actions',
            cell: props => {
                return (
                    <span className="flex items-center justify-center">
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
                            <button>
                                <Icon
                                    icon="ic:baseline-edit"
                                    className="text-2xl transition-all duration-150 text-black900"
                                    onClick={() => openCreateLinkModal(props.row.original?._id)}
                                />
                            </button>
                        </span>
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button>
                                <Icon
                                    icon="material-symbols:delete"
                                    className="transition-all duration-150 text-2xl text-black900"
                                    onClick={() => handleOpenDeleteModal(props.row.original?._id)}
                                />
                            </button>
                        </span>
                    </span>
                )
            }
        }),
    ]

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id
    })

    return (
        <>
            <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
                <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
                    <div className="text-[25px] text-[#212121]">Form Applicants List</div>
                    <Button
                        color="blue"
                        className="flex items-center min-w-[150px]"
                        onClick={openMemberFormLink}
                    >
                        <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> Submit Form
                    </Button>
                </div>

                <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
                    <div className="overflow-auto">
                        <table className="w-full">
                            <thead>
                                {
                                    table.getHeaderGroups().map((headerGroup) => {
                                        return (
                                            <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                                                {
                                                    headerGroup.headers.map(header => {
                                                        return (
                                                            <th key={header.id} colSpan={header.colSpan} className="text-left font-semibold p-2">
                                                                {
                                                                    header.isPlaceholder ? null :
                                                                        (
                                                                            <div
                                                                                {
                                                                                ...{
                                                                                    className: header.column.getCanSort()
                                                                                        ? 'cursor-pointer select-none'
                                                                                        : 'last:text-center'
                                                                                }
                                                                                }
                                                                            >
                                                                                {
                                                                                    flexRender(
                                                                                        header.column.columnDef.header,
                                                                                        header.getContext()
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </thead>
                            <tbody className="last:!border-b-0">
                                {table.getRowModel().rows.map(row => {
                                    return (
                                        <tr key={row.id} className='border-b border-stone-200 text-sm'>
                                            {row.getVisibleCells().map(cell => {
                                                return (
                                                    <td key={cell.id} className='px-2 py-4'>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <DeleteModal open={showDeleteModal} handleCloseModal={handleCloseModal} />
            {openMemberForm && 
                <MemberFormModal 
                    openMemberForm={openMemberForm} 
                    setOpenMemberForm={setOpenMemberForm} 
                    selectedForm={selectedForm}
                    fromDate={formData.fromDate}
                    toDate={formData.toDate}
                    formIconUrl={formData.formIconUrl}
                    formId={formData._id}
                    willJoinWithDays={formData.willJoinWithDays}
                    userList={data}
                    setUserList={setData}
                />}

        </>
    );
};

export default ViewApplicantUsers;
