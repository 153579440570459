import React, { Fragment } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { Typography } from "@material-tailwind/react";
import { mdiDeleteCircle } from "@mdi/js";
import Icon from "@mdi/react";

export default function DeleteModal(props) {
  const { open, handleCloseModal } = props;

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" open={open} className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Typography className="mb-4 text-center">
                    <Icon
                      path={mdiDeleteCircle}
                      size={2}
                      className="text-red-600 inline-block"
                    />
                  </Typography>

                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-medium leading-6 text-gray-900"
                  >
                    Are you sure you want to delete?
                  </Dialog.Title>
                  <div className="mt-5 flex justify-end">
                    <button
                      type="button"
                      className="mr-3 inline-flex justify-center rounded-md font-semibold bg-white px-4 py-2 text-sm  text-blue-900 hover:text-blue-800  focus:outline-none"
                      onClick={() => handleCloseModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600  px-4 py-2 text-sm font-medium text-white hover:bg-red-600 focus:outline-none"
                      onClick={() => handleCloseModal(true)}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};