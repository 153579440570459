import { api } from "../../../services/api.service";
import { toastr } from "../../../services/common.service";
import { ACTION_TYPES } from "../../../redux/constants";

export const getHODUsers = async (type) => {
    try {
        const response = await api.get(`admin/hod-hos?type=${type}`);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const createHODUser = async (userData) => {
    try {
        const response = await api.post('admin/hod-hos/create', userData);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const deleteHODUser = async (userId, type) => {
    try {
        const response = await api.post('admin/hod-hos/delete', { _id: userId, type });
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const getDepartments = async (dispatch) => {
    try {
        const response = await api.get("admin/department");
        
        if (response?.data)
            dispatch({ type: ACTION_TYPES.SET_SAINT_LEADERS, payload: response.data });
        
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const createDepartment = async (departmentData) => {
    try {
        const response = await api.post('admin/department/create', departmentData);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const updateDepartment = async (departmentData) => {
    try {
        const response = await api.post('admin/department/update', departmentData);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const deleteDepartment = async (departmentId) => {
    try {
        const response = await api.post('admin/department/delete', { departmentId });
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}