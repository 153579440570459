import { mdiTrayArrowUp } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useState } from 'react'
import { api } from '../../../services/api.service';
import { toastr } from '../../../services/common.service';

const ImageUpload = ({ setMemberDetails }) => {
  const [imgFile, setImgFile] = useState();
  const [imgBase64, setImgBase64] = useState("");


  const handleChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onloadend = async () => {
      setImgBase64(reader.result);
      const formData = new FormData();
      formData.append('profileImg', file);
      try {
        const profile = await api.post("/admin/user/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setMemberDetails('profileImgUrl', profile.data.profileImgUrl);
      } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
      }
    };

    if (file) {
      reader.readAsDataURL(file);
      setImgFile(file);
    }
  };

  return (
    <div className='w-full'>
      <div className='max-w-3xl mx-auto'>
        <div className=' w-full mx-auto h-full'>
          <div className='relative w-[300px] h-[300px] mx-auto group'>
            {imgBase64 ? (
              <img src={imgBase64} className='w-[300px] object-cover h-[300px] rounded-full mx-auto bg-opacity-5' />
            ) : (
              <img src='/assets/image/profilepicture.png' className='w-[300px] h-[300px] rounded-full mx-auto bg-opacity-5' />
            )}
            <input type='file' id='upload-file' hidden onChange={handleChange} />
            <label htmlFor='upload-file' className='absolute inset-0 bg-gray-900 rounded-full bg-opacity-30  transition-opacity justify-center items-center hidden group-hover:flex'>
              <Icon path={mdiTrayArrowUp} color={"#fff"} size={2} />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
