import React, { useCallback } from "react";
import { mdiImagePlus } from "@mdi/js";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";

const EventUpload = (props) => {
  const { handleFileChange, multiple, icon, title } = props;

  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple,
  });

  return (
    <>
      <div {...getRootProps()} className=" w-full mx-auto">
        <input {...getInputProps()} />
        <div className="h-[300px] mx-auto  block border-dashed border-[2px] rounded-xl border-gray400">
          <div className="h-full flex flex-col justify-center items-center">
            <div className="w-32 h-32 rounded-full bg-gray200 flex justify-center items-center">
              <Icon
                icon={icon ? icon : "mdi:image-plus"}
                className="text-[56px]"
                color={"#A5A5A5"}
              />
            </div>
            {isDragActive ? (
              <div className="text-sm my-4 select-none font-medium">
                Drop the images here ...
              </div>
            ) : (
              <div className="text-sm my-4 select-none font-medium">
                {title ? title : "Drag and drop images to upload"}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventUpload;
