import { ACTION_TYPES } from "../../../redux/constants";
import { api } from "../../../services/api.service";

export const updateFlag = async (data) => {
  const response = await api.post("admin/flag/update", data);
  return response;
};

export const changeFlagVisibility = async (params) => {
  const response = await api.post("admin/flag/change-visibility", params);
  return response;
};
