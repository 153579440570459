import React from 'react'

export default function TextField(props) {
  const {
    name,
    id,
    onChange,
    placeholder,
    disabled,
    value,
    className,
    label,
    required,
    type,
    autoComplete,
    maxLength,
    minLength,
  } = props;
  return (
    <div className='w-full'>
      {
        label &&
        <label htmlFor={id} className='text-sm text-gray300 font-semibold mb-2 inline-block'>
          {label}
          {
            required ?
              <span className='text-red400'>*</span> : <></>
          }
        </label>
      }
      <input
        type={type}
        name={name}
        id={id}
        onChange={onChange ? onChange : () => { }}
        placeholder={placeholder}
        disabled={disabled}
        value={value ?? ""}
        autoComplete={autoComplete || "off"}
        className={`bg-white border border-[#b0bec5] text-lg h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg ${className} placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5`}
        maxLength={maxLength}
        minLength={minLength}
      />
    </div>
  )
}
