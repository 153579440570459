import React, { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { s3Url } from "../../services/api.service";
import { Icon } from "@iconify/react";
import { Disclosure } from "@headlessui/react";

const MemberProfile = (props) => {
  const { profileData } = props;
  const [isCollapsed, setIsCollapsed] = useState({});

  const handleCollapse = (tabKey) => {
    setIsCollapsed((prevState) => ({
      ...prevState,
      [tabKey]: !prevState[tabKey],
    }));
  };

  return (
    <>
      <div className="h-full flex flex-col justify-between relative">
        <div className="py-5 mt-10">
          <div className="w-full flex justify-center">
            <div className="relative w-[130px] h-[130px] 2xl:w-[180px] 2xl:h-[180px] group">
              <img
                src={
                  profileData?.profileImgUrl
                    ? `${s3Url}${profileData?.profileImgUrl}`
                    : profileData?.gender === 'female' ? "/assets/image/female-avatar.jpg" : "/assets/image/profilepicture.png"
                }
                alt="avtar"
                className="w-full h-full rounded-full object-cover"
              />
              <div className="absolute w-full rounded-full h-full flex items-center justify-center xl:gap-x-4 2xl:gap-x-5 bg-[rgba(0,0,0,0.40)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                <button className="w-7 h-7 2xl:w-10 2xl:h-10 flex items-center justify-center p-1.5 rounded-full transition-all duration-300 ease-[ease]">
                  <Icon
                    icon="lucide:eye"
                    className="text-2xl 2xl:text-3xl hover:scale-[1.06]"
                    color="#fff"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow">
          <div className="">
            <div className="mt-3">
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary bg-opacity-10 px-4 py-4 text-left font-medium text-primary-900 focus:outline-none focus-visible:ring">
                      <span className="text-primary">Personal Info.</span>
                      <Icon
                        icon={"mdi:chevron-down"}
                        className={`${open ? "rotate-180 transform" : ""
                          } text-2xl text-primary transition-transform duration-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <div className="grid 2xl:grid-cols-2 gap-3">
                        <div>
                          SMV No. : {profileData?.SMVNo}
                        </div>
                        <div>
                          Name : `{profileData?.firstName} {profileData?.middleName} {profileData?.lastName}`
                        </div>
                        <div>
                          Total Count : {profileData?.mantraCount}
                        </div>
                        <div>
                          Gender: {profileData?.gender}
                        </div>
                        <div>
                          Mobile: {profileData?.mobile}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mt-3">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary bg-opacity-10 px-4 py-4 text-left font-medium text-primary focus:outline-none focus-visible:ring">
                      <span className="text-primary">Mantra Jap</span>
                      <Icon
                        icon={"mdi:chevron-down"}
                        className={`${open ? "rotate-180 transform" : ""} text-2xl text-primary transition-transform duration-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <div className="grid 2xl:grid-cols-2 gap-3">
                        <div>
                            Mantra Jap count
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberProfile;
