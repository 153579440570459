import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import lodash from "lodash";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose, mdiTrashCan } from "@mdi/js";
import { toastr } from "../../../services/common.service";
import { createAlbum, updateAlbum, updateAlbumWithMedia } from "./actionCreator";
import { s3Url } from "../../../services/api.service";

const CreateAlbumModal = ({ open, setOpen, selectedPlaylist, refreshData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [albumFile, setAlbumFile] = useState(null);
  const [albumDetail, setAlbumDetail] = useState(selectedPlaylist || {});
  const [imagePreviewFile, setImagePreviewFile] = useState('');
  const [newImageUpload, setNewImageUpload] = useState(false);

  const setCurrentDetail = (key, value) => {
    const newAlbumDetail = lodash.cloneDeep(albumDetail);
    newAlbumDetail[key] = value;
    setAlbumDetail(newAlbumDetail);
  }

  const createAlubmList = async () => {
    if (!albumDetail?.name) {
      toastr.show("Please enter album name", "error");
      return;
    }
    if (!albumFile?.[0]) {
      toastr.show("Please upload album image", "error");
      return;
    }
    
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", albumDetail.name);
    formData.append("imagefile", albumFile?.[0]);
    formData.append("visibility", enabled);
    const response = await createAlbum(formData);
    if (response.data) {
      toastr.show("Successfully created!", "success");
      resetForm();
      refreshData();
    } else {
      setIsLoading(false);
      toastr.show("Something went wrong!", "error");
    }
  }

  const handleFileChange = (vfile) => {
    const file = vfile?.[0];
    if (file) {
        const fileExtension = file?.name.substring(file?.name.lastIndexOf(".") + 1, file.name.length);
        const validataionMessage = "Please choose correct image format";
        if (file?.name?.length == 0) {
          albumFile('');
          toastr.show(validataionMessage);
          return;
        }
        if (fileExtension === "jpg" || fileExtension === "webp" || fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "gif" || fileExtension === "bmp") {
          setAlbumFile(vfile);
          // preview image
          const reader = new FileReader();
          reader.onload = (e) => {
            setImagePreviewFile(e?.target?.result);
          };
          reader.readAsDataURL(file);
        }
        else {
          albumFile('');
          toastr.show(validataionMessage);
          return;
        }
    }
  };

  const updateAlbumList = async () => {
    if (!albumDetail?.name) {
      toastr.show("Please enter album name", "error");
      return;
    }
    let newData = {
      name: albumDetail?.name || '',
      visibility: enabled,
      _id: selectedPlaylist?._id
    }

    let response = null;
    const formData = new FormData();
    if (newImageUpload) {
      if (!albumFile?.[0]) {
        toastr.show("Please upload image", "error");
        return;
      }
      formData.append("name", albumDetail.name);
      formData.append("imagefile", albumFile?.[0]);
      formData.append("visibility", enabled);
      formData.append("_id", selectedPlaylist?._id);
      response = await updateAlbumWithMedia(formData);
    } else {
      response = await updateAlbum(newData);
    }
    if (response.data) {
      toastr.show("Successfully updated!", "success");
      resetForm();
      refreshData();
    } else {
      toastr.show("Something went wrong!", "error");
    }
  }

  const resetForm = () => {
    setOpen(false);
    setCurrentDetail({});
    setIsLoading(false);
    setEnabled(false);
    setAlbumDetail({});
    setAlbumFile('');
    setNewImageUpload(false);
    setImagePreviewFile('');
  }

  useEffect(() => {
    setEnabled(selectedPlaylist?.visibility || false);
    if (selectedPlaylist?._id) {
      setAlbumDetail(selectedPlaylist);
      setNewImageUpload(false);
    } else {
      setNewImageUpload(true);
    }
  }, [selectedPlaylist])

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" open={open} className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      {selectedPlaylist?._id ? "Update" : "Create"} Album
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="max-w-md w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div className="mb-5">
                                <Input
                                  label="Album Name"
                                  className=" w-full"
                                  value={albumDetail?.['name'] || ""}
                                  onChange={(e) => setCurrentDetail('name', e.target.value)}
                                />
                              </div>
                              { newImageUpload && (
                                <>
                                  <div className="mb-5">
                                    {/* <Input label="Image Upload" accept=".jpg, .png, .jpeg, .gif, .bmp, .webp, .tif, .tiff|image/*" className="w-full" id="file" type="file" onChange={(e) => handleFileChange(e?.target?.files)} /> */}
                                    <input hidden accept=".jpg, .png, .jpeg, .gif, .bmp, .webp, .tif, .tiff|image/*" id="uploadImg" type="file" onChange={(e) => handleFileChange(e?.target?.files)}/>
                                    <label htmlFor="uploadImg">
                                      <a variant="gradient" className="rounded-3xl bg-black900 text-white px-4 py-2">File Uplaod</a>
                                    </label>
                                  </div>
                                  {
                                    imagePreviewFile &&
                                    <div className="mb-5">
                                      <img src={imagePreviewFile ? imagePreviewFile : "/assets/image/no-thumbnail.png"} className="w-[100px]" />
                                    </div>
                                  }
                                </>
                              )}
                              { (selectedPlaylist?._id && !newImageUpload) && (
                                <div className="mb-5">
                                  <span>Image URL: </span>
                                  <a target="_blank" href={`${s3Url}${selectedPlaylist?.imageURL}`} > {selectedPlaylist?.imageURL} </a>
                                  <button
                                    className="capitalize text-base"
                                    onClick={() => setNewImageUpload(true)}
                                  >
                                    <Icon path={mdiTrashCan} size={1} />
                                  </button>
                                </div>
                              )}
                              <div className="flex items-center">
                                <div className="mr-3 text-blue-gray-500">Visibility</div>
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={`${enabled ? "bg-blue-600" : "bg-gray-200"
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${enabled
                                      ? "translate-x-6"
                                      : "translate-x-1"
                                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => resetForm()}
                        >
                          Reset
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() => selectedPlaylist?._id ? updateAlbumList() : createAlubmList()}
                          disabled={isLoading}
                        >
                          {selectedPlaylist?._id ? "Update" : "Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CreateAlbumModal;
