import { ACTION_TYPES } from "../constants";

const INITIAL_STATE = {
    flagList: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        
        case ACTION_TYPES.SET_MEMBER_FLAG: {
            return { ...state, flagList: action.payload || [] };
        }

        case ACTION_TYPES.UPDATE_MEMBER_FLAG: {
            const { flagId, updatedDetail } = action.payload || {};
            const flags = state.flagList.map((eachFlag) => {
                if (eachFlag._id === flagId) return updatedDetail;
                return eachFlag
            })
            return { ...state, flagList: flags };
        }
    }
    return state;
}
