import React from "react";

import DepartmentList from "./DepartmentList";

const DepartmentHome = () => {

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] p-5 bg-[#F8F8F8] overflow-hidden">
        <div className="flex w-full h-[calc(100vh_-135px)]">
          <div className="p-3 flex-grow overflow-auto ">
            <div className="bg-white w-full h-full px-2 py-4 rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
              <DepartmentList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentHome;
