import _ from "lodash";
import { api } from "../../services/api.service";
import { uploadDataOnGoDaddyServer } from "../../services/common.service";

export const postDailyDarshan = async (files, darshanDate, imageType) => {
  try {
    const formData = new FormData();
    const newFormData = new FormData();
    formData.append("imageType", imageType);

    newFormData.append("date", darshanDate);
    newFormData.append("imageType", imageType);

    files.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
      newFormData.append(`images[${index}]`, file);
    });
    if (imageType === "hd") {
      const data = await uploadDataOnGoDaddyServer(
        formData,
        "https://www.swaminarayansurat.com/api/upload-darshan",
        darshanDate
      );
      newFormData.append("imageList", data);
    } else if (imageType === "compress") {
      const data = await uploadDataOnGoDaddyServer(
        formData,
        "https://www.swaminarayansurat.com/api/upload-darshan",
        darshanDate
      );
      newFormData.append("imageList", data);
    } else {
      return null;
    }
    const response = await api.post("admin/daily-darshan/create", newFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const postEventDetails = async (files, params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });

  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  const response = await api.post("admin/events/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const updateEventDetail = async (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });

  const response = await api.post("admin/events/update", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const postBannerDetails = async (files, params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });

  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  const response = await api.post("admin/banner/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const updateBannerDetail = async (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });

  const response = await api.post("admin/banner/update", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const deleteDailyDarshan = async (removeUrl, darshanDate, imageType) => {
  const params = {
    date: darshanDate,
    fileUrl: removeUrl,
    imageType: imageType,
  };
  const response = await api.post("admin/daily-darshan/delete", { ...params });
  return response;
};

export const deleteSabhaPost = async (eventId) => {
  const params = { _id: eventId };
  const response = await api.post("admin/events/delete", { ...params });
  return response;
};

export const deleteEventBanner = async (eventId) => {
  const params = { _id: eventId };
  const response = await api.post("admin/banner/delete", { ...params });
  return response;
};

export const getDailyDarshan = async (params) => {
  let response;
  if (params) {
    response = await api.get(`admin/daily-darshan?date=${params}`);
  } else {
    response = await api.get(`admin/daily-darshan`);
  }
  return response;
};

export const getSabhaPosts = async () => {
  const response = await api.get("admin/events/all");
  return response;
};

export const getEventBanners = async () => {
  const response = await api.get("admin/banner/all");
  return response;
};

export const getMantraLekhanImages = async (params) => {
  const response = await api.get("admin/mantra-lekhan/images", {
    ...(params || {}),
  });
  return response;
};

export const uploadMantraLekhanPhoto = async (files) => {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  const response = await api.post(
    "admin/mantra-lekhan/images/create",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};

export const deleteMontrLekhanImage = async (removeUrl) => {
  const params = {
    fileUrl: removeUrl,
  };
  const response = await api.post("admin/mantra-lekhan/images/delete", {
    ...params,
  });
  return response;
};

//wallpaper api integration :: start

export const getWallpaperData = async (params) => {
  const response = await api.get("admin/wallpaper/images", {
    ...(params || {}),
  });
  return response;
};

export const uploadWallpaperImage = async (files, device) => {
  const formData = new FormData();
  formData.append("device", device || "desktop");

  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  const response = await api.post("admin/wallpaper/images/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const deleteWallpaperImage = async (removeUrl, device) => {
  const params = {
    fileUrl: removeUrl,
    device: device || "desktop",
  };
  const response = await api.post("admin/wallpaper/images/delete", {
    ...params,
  });
  return response;
};

// end :: wallpapper api

// books CRUD
export const getAllBooks = async () => {
  const response = await api.get("admin/books");
  return response;
};

export const createBook = async (formdata) => {
  try {
    const response = await api.post("admin/books/create", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("Error uploading files:", error);
  }
};

export const updateBook = async (formdata) => {
  try {
    const response = await api.post("admin/books/update", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("Error uploading files:", error);
  }
}

export const deleteBook = async (bookId) => {
  const response = await api.post("admin/books/delete", { _id: bookId });
  return response;
}

export const changeBookStatus = async (bookId, status) => {
  const response = await api.post("admin/books/change-status", { _id: bookId, enabled: status });
  return response;
}

export const reorderBooks = async (data) => {
  const response = await api.post("admin/books/reorder", data);
  return response;
}
