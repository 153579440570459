import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

const ColumnVisibilityMenu = ({ table }) => {
  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "text-white" : "text-white/90"}
                group inline-flex items-center rounded-md bg-primary px-3 py-1.5 text-sm font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
            >
              <span>Column Visibility</span>
              {/* <ChevronDownIcon
                className={`${open ? 'text-orange-300' : 'text-orange-300/70'}
                  ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80`}
                aria-hidden="true"
              /> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 z-20 mt-3 bg-white w-screen max-w-[200px]  transform px-4 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 p-3">
                  <div className="inline-block rounded w-full">
                    <div className="px-1 border-b border-black pb-1">
                      <label className="mb-2">
                        <input
                          {...{
                            type: "checkbox",
                            checked: table?.getIsAllColumnsVisible(),
                            onChange:
                              table.getToggleAllColumnsVisibilityHandler(),
                          }}
                        />{" "}
                        Toggle All
                      </label>
                    </div>
                    <div className="mt-2">
                      {table.getAllLeafColumns().map((column) => {
                        return (
                          <div key={column.id} className="px-1">
                            <label>
                              <input
                                {...{
                                  type: "checkbox",
                                  checked: column.getIsVisible(),
                                  onChange: column.getToggleVisibilityHandler(),
                                }}
                              />{" "}
                              {column.id}
                            </label>
                          </div>
                        );
                      })}

                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default ColumnVisibilityMenu;
