import { api } from "../../../services/api.service";

export const getPlayList = async () => {
  const response = await api.get("admin/all-playlist");
  return response;
};

export const getVideoByPlayList = async (id) => {
  const response = await api.get(`admin/playlist/${id}`);
  return response;
};

export const createPlaylistSection = async (data) => {
  const response = await api.post(`admin/playlist-section/add`, data);
  return response;
};

export const updatePlaylistSection = async (data) => {
  const response = await api.post(`admin/playlist-section/update`, data);
  return response;
};

export const deletePlaylistSection = async (data) => {
  const response = await api.post(`admin/playlist-section/delete`, data);
  return response;
};

export const updatePlaylistPositionInSection = async (data) => {
  const response = await api.post(
    `admin/playlist/updatePlaylistVideoPosition`,
    data
  );
  return response;
};

export const getAllSection = async () => {
  const response = await api.get("admin/playlist-section-all");
  return response;
};

export const getLiveNowVideos = async () => {
  const response = await api.get("admin/live-list");
  return response;
};
