import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainRouter from "./routes/main-router";
import { StoreProvider } from "./redux/storeProvider";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@material-tailwind/react";

export const router = createBrowserRouter([{ path: '/*', element: <MainRouter /> }])


export default function App() {

  return (
    <StoreProvider>
      <Provider store={store}>
        <ThemeProvider>
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
    </StoreProvider>
  );
}