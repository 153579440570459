import React, { useEffect, useState } from "react";
import { mdiTrayArrowUp } from "@mdi/js";
import Icon from "@mdi/react";
import { deleteMontrLekhanImage, getMantraLekhanImages, uploadMantraLekhanPhoto } from "./actionCreator";
import { toastr } from "../../services/common.service";
import LoaderBar from "../Common/LoaderBar";
import MantraLekhanGallery from "./MantraLekhanGallery";
import MantraLekhanUpload from "./MantraLekhanUpload";

const MantraJap = () => {
  const [open, setOpen] = useState(false);
  const [mantraLekhanImage, setmantraLekhanImage] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const uploadFiles = async (files) => {
    try {
      if (!files?.length) {
        toastr.show("Please Select Files", "error");
        return;
      }
      setIsLoading(true);
      const response = await uploadMantraLekhanPhoto(files);
      if (response.data) {
        toastr.show("Success !!", "success");
        setmantraLekhanImage(response.data);
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading files:', error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  }

  const removeMontrLekhanImage = async (removeUrl, darshanDate) => {
    try {
      setIsLoading(true);
      const response = await deleteMontrLekhanImage(removeUrl, darshanDate);
      if (response.data) {
        toastr.show("Success !!", "success");
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading files:', error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  }

  const loadDailyDarshan = async () => {
    try {
      const response = await getMantraLekhanImages();
      if (response.data) {
        setmantraLekhanImage(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error Fetching Darshan:', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadDailyDarshan();
  }, []);

  return (
    <>
      {isLoading && <LoaderBar />}
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Mantra Jap</div>
          <button
            className="w-10 h-10 bg-white border p-1.5 rounded-[50%] border-solid border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045]"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Icon path={mdiTrayArrowUp} size={0.9} />
          </button>
        </div>

        {mantraLekhanImage &&
          <MantraLekhanGallery
            mantraLekhanImage={mantraLekhanImage}
            removeMontrLekhanImage={removeMontrLekhanImage}
          />
        }
      </div>
      <MantraLekhanUpload
        open={open}
        setOpen={setOpen}
        uploadFiles={uploadFiles}
      />
    </>
  );
};

export default MantraJap;
