import { Dialog, Transition, TransitionChild } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { Checkbox } from '@material-tailwind/react'
import { Icon } from '@iconify/react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toastr } from '../../../services/common.service';
import { createPlaylistSection, updatePlaylistSection } from './actionCreator';

const DraggableRow = ({ playlist, index, movePlaylist }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'playlist',
        item: { type: 'playlist', index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'playlist',
        hover(item, monitor) {
            if (!drag) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            movePlaylist(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <div ref={node => drag(drop(node))} style={{ opacity }}>
            <div className='flex items-center justify-start gap-3 py-2'>
                <Icon icon="fluent:re-order-16-filled" className='min-w-[16px]' />
                <div className='text-ellipsis overflow-hidden line-clamp-1 text-sm'>{playlist.name}</div>
            </div>
        </div>
    );
};

const MuplitplePlaylistMenu = ({ isOpen, closeModal, selectedSections, setSelectedSections, playlists, sectionData }) => {
    const [selectedPlaylists, setSelectedPlaylists] = useState([]);
    const [sectionInput, setSectionInput] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPlaylists, setFilteredPlaylists] = useState(playlists);

    useEffect(() => {
        const filtered = playlists.filter(playlist =>
            playlist.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredPlaylists(filtered);
    }, [searchQuery, playlists]);

    useEffect(() => {
        if (sectionInput.length > 0 && selectedPlaylists.length > 0) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [sectionData, sectionInput, selectedPlaylists])

    useEffect(() => {
        setSectionInput(sectionData?.name ?? '');
        const indexes = [];
        sectionData?.playlist?.map((playlist) => {
            const index = playlists.findIndex((item) => item._id === playlist._id);
            if (index !== -1) {
                indexes.push(index);
            }
        });
        setSelectedPlaylists(indexes);
    }, [sectionData, playlists]);

    const handleclose = () => {
        setSelectedPlaylists([])
        setSectionInput('')
        closeModal()
    }

    const isValidPayload = () => {
        let filteredSections = selectedSections.filter(item => item._id !== sectionData._id);
        let isTitleExist = filteredSections.some(item => item.name === sectionInput);

        if (isTitleExist) {
            toastr.show("Section with this title already exists", "error");
            return false;

        } else if (!sectionInput) {
            toastr.show("Please select section title", "error");
            return false;
        }
        return true;
    };

    const handleSelectPlaylist = (item, index) => {
        const updatedSelectedPlaylists = [...selectedPlaylists];
        const playlistIndex = updatedSelectedPlaylists.indexOf(index);
        if (playlistIndex === -1) {
            updatedSelectedPlaylists.push(index);
        } else {
            updatedSelectedPlaylists.splice(playlistIndex, 1);
        }

        setSelectedPlaylists(updatedSelectedPlaylists);
    };

    const handleSave = async () => {
        let isValid = await isValidPayload();
        if (isValid) {
            const isSectionEmpty = sectionInput.trim() === '';
            const isNoPlaylistSelected = selectedPlaylists.length === 0;
            if (isSectionEmpty && isNoPlaylistSelected) {
                toastr.show("Section title is required and at least one playlist must be selected.", "error")
                setSectionInput('');

                return;
            }
            if (isSectionEmpty || isNoPlaylistSelected) {
                if (isSectionEmpty) {
                    setSectionInput('');
                    toastr.show("Section title is required.", "error");
                }
                if (isNoPlaylistSelected) {
                    toastr.show("At least one playlist must be selected.", "error");
                }
                return;
            }
            let newData = {
                name: sectionInput,
                type: "multiple",
                playlist: selectedPlaylists.map(index => playlists[index]),
                position: selectedSections.length,
            };
            if (sectionData._id) {
                newData._id = sectionData._id;
            }
            return newData
        }

    }

    const handleDone = async () => {
        try {
            let isValid = await isValidPayload();
            if (isValid) {
                let data = await handleSave();
                let response = await createPlaylistSection(data);
                toastr.show("Section created successfully!", "success");
                let dataWithId = { ...data, _id: response.data }
                setSelectedSections(prevSections => [...prevSections, dataWithId]);
                handleclose();
            }
        } catch (error) {
            toastr.show(error.message, "error");
        }
    };

    const handleUpdat = async () => {
        let data = await handleSave();
        const response = await updatePlaylistSection(data);
        if (response.data) {
            toastr.show("Section updated successfully!", "success");
            setSelectedSections((prevSection) => [...prevSection, data]);
        }
    }

    const handleUpdate = async () => {

        try {
            let isValid = await isValidPayload();
            if (isValid) {
                const data = { ...sectionData };
                // let arrOfIndex = [];
                // let indx = playlists.findIndex(item => item._id === playlistId);
                // arrOfIndex.push(indx);
                data.playlist = selectedPlaylists.map(index => playlists[index]);
                data.name = sectionInput;

                delete data.isDeleted;
                delete data.createdAt;
                delete data.updatedAt;
                delete data.__v;
                delete data.order;
                const response = await updatePlaylistSection(data);
                if (response.data) {
                    toastr.show("Section updated successfully", "success");
                    // Find the index of the section being updated
                    const index = selectedSections.findIndex(item => item._id === sectionData._id);
                    if (index !== -1) {
                        // Replace the old section with the updated data
                        const updatedSections = [...selectedSections];
                        updatedSections[index] = data;
                        setSelectedSections(updatedSections);
                    }
                    setSectionInput('');
                }
                closeModal();
            }
        } catch (error) {
            toastr.show(error.message, "error");
        }

    };

    const movePlaylist = (dragIndex, hoverIndex) => {
        const updatedSelectedPlaylists = [...selectedPlaylists];
        const dragPlaylist = updatedSelectedPlaylists[dragIndex];
        updatedSelectedPlaylists.splice(dragIndex, 1);
        updatedSelectedPlaylists.splice(hoverIndex, 0, dragPlaylist);
        setSelectedPlaylists(updatedSelectedPlaylists);
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" open={isOpen} className="relative z-20" onClose={() => { }}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[80vw] h-[90vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex justify-between flex-col h-full">
                                        <div className='h-[calc(100%_-_65px)]'>
                                            <div className="border rounded-md h-[calc(100%_-_52px)]">
                                                <div className='flex rounded-md w-full h-full'>
                                                    <div className='w-2/3 border-r-2 p-2 flex flex-col justify-start h-full'>
                                                        <div className='flex items-center gap-5'>
                                                            <div className='w-1/2 border border-gray400 max-w-[500px] p-2 rounded-md'>
                                                                <input type='text' className='outline-none w-full' value={sectionInput} placeholder='Enter section title' onChange={(e) => setSectionInput(e.target.value)} />
                                                            </div>
                                                            <div className='w-1/2 flex items-center border border-gray400 max-w-[500px] p-2 rounded-md'>
                                                                <Icon icon="iconoir:search" className='text-xl mr-2 text-gray400' />
                                                                <input type='text' value={searchQuery} className='outline-none flex-grow' placeholder='Your playlists' onChange={(e) => setSearchQuery(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow h-[calc(100%_-_42px)]">
                                                            <div className='relative h-full'>
                                                                <div className='bg-white sticky z-10 top-0 w-full py-3'>Playlist Title</div>
                                                                <div className='h-[calc(100%_-_48px)] overflow-auto'>

                                                                    {
                                                                        filteredPlaylists.map((item, index) => {
                                                                            return (
                                                                                <div className='py-1.5 border-y flex items-center justify-start gap-3' key={index}>
                                                                                    <div className='min-w-[60px] flex items-center justify-center'><Checkbox ripple={false} checked={selectedPlaylists.includes(index)} onChange={() => handleSelectPlaylist(item, index)} /></div>
                                                                                    <div className='flex items-center justify-start gap-3 flex-grow'>
                                                                                        <div className='relative'>
                                                                                            <img src={item.thumbnailImages ? item.thumbnailImages.default : "/assets/image/no-thumbnail.png"} className='min-w-[80px] w-[80px] h-[50px]' />
                                                                                            <div className='absolute inset-y-0 right-0 w-[70%] bg-opacity-80 bg-black'>
                                                                                                <div className='h-full flex flex-col justify-center'>
                                                                                                    <div className='text-white text-sm text-center'>{item.videos.length}</div>
                                                                                                    <Icon icon="solar:playlist-bold" className='text-white text-xl mx-auto' />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='text-ellipsis overflow-hidden line-clamp-1 text-sm'>{item.name}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-1/3 p-2'>
                                                        <div className='min-h-[42px] flex items-center ml-2'>Playlists in section</div>
                                                        {/* change order of the playlist queue from here  */}
                                                        <DndProvider backend={HTML5Backend} >
                                                            <div className='h-[calc(100%_-_42px)] overflow-auto'>
                                                                {selectedPlaylists.map((selectedIndex, index) => {
                                                                    const selectedPlaylist = playlists[selectedIndex];
                                                                    return (
                                                                        <DraggableRow playlist={selectedPlaylist} index={index} movePlaylist={movePlaylist} key={index} />
                                                                    );
                                                                })}
                                                            </div>

                                                        </DndProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 flex justify-end">
                                            <button
                                                type="button"
                                                className="mr-3 inline-flex justify-center uppercase rounded-md font-semibold bg-white px-4 py-2 text-sm  text-red-600 hover:text-red-600 focus:outline-none"
                                                onClick={handleclose}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                disabled={isDisabled}
                                                onClick={() => sectionData._id ? handleUpdate() : handleDone()}
                                                className="inline-flex justify-center uppercase rounded-md border border-transparent bg-primary disabled:bg-gray400 px-4 py-2 text-sm font-medium text-white hover:bg-primary focus:outline-none"
                                            >
                                                {
                                                    sectionData._id ? "Update" : "Done"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default MuplitplePlaylistMenu