import lodash from "lodash";
import moment from "moment/moment";

export const filterSabhaListOnTypeAndDate = ({ _sabhaList, sabhaType, startDateOfSabha, endDateOfSabha }) => {
    let _filterSabha = lodash.cloneDeep(_sabhaList);
    if (sabhaType?.length) {
        const _sabhaType = sabhaType.map((sabha) => sabha.value + "");
        _filterSabha = _filterSabha.filter((sabha) => lodash.isEqual(_sabhaType, sabha.sabhaType));
    }
    if (startDateOfSabha) {
        _filterSabha = _filterSabha.filter((sabha) => moment(sabha.date).toISOString() >= moment(startDateOfSabha).toISOString());
    }
    if (endDateOfSabha) {
        _filterSabha = _filterSabha.filter((sabha) => moment(sabha.date).toISOString() <= moment(endDateOfSabha).toISOString());
    }
    return _filterSabha;
}
