import { Tab } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { mdiCellphone, mdiMonitor, mdiTrayArrowUp } from "@mdi/js";
import Icon from "@mdi/react";
import { toastr } from "../../../services/common.service";
import LoaderBar from "../../Common/LoaderBar";
import DesktopWallpapers from "./DesktopWallpapers";
import MobileWallpapers from "./MobileWallpapers";
import WallpaperUpload from "./WallpaperUpload";
import { deleteWallpaperImage, getWallpaperData, uploadWallpaperImage } from "../actionCreator";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Wallpaper = () => {
  const [open, setOpen] = useState(false);
  const [wallpaperImages, setWallpaperImages] = useState();
  const [device, setDevice] = useState('desktop');
  const [isLoading, setIsLoading] = useState(true);

  const openUploadModal = () => {
    setOpen(!open);
  }

  const uploadFiles = async (files, device_type) => {
    try {
      if (!files?.length) {
        toastr.show("Please Select Files", "error");
        return;
      }
      setIsLoading(true);
      const response = await uploadWallpaperImage(files, device_type || device);
      if (response.data) {
        toastr.show("Success !!", "success");
        setWallpaperImages(response.data);
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading files:', error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  }

  const removeWallpaperImage = async (removeUrl, device) => {
    try {
      setIsLoading(true);
      const response = await deleteWallpaperImage(removeUrl, device);
      if (response.data) {
        toastr.show("Success !!", "success");
        setOpen(false);
        loadWallpaper();
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading files:', error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  }

  const loadWallpaper = async () => {
    try {
      const response = await getWallpaperData();
      if (response.data) {
        setWallpaperImages(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error Fetching Darshan:', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadWallpaper();
  }, []);
  const handleCloseModal = () => {
    setOpen(false)
  }

  return (
    <>
      {isLoading && <LoaderBar />}
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="w-full px-2 sm:px-0 h-full flex flex-col ">
          <Tab.Group defaultIndex={0}
            onChange={(index) => {
              const device = index === 0 ? 'desktop' : 'mobile';
              setDevice(device);
            }}
          >
            <div className="flex items-center justify-between">
              <Tab.List className="flex flex-grow space-x-1 rounded-xl max-w-[280px] bg-white p-2">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                      "ring-white/60 focus:outline-none",
                      selected
                        ? "bg-primary text-white shadow-2xl"
                        : "bg-white text-primary"
                    )
                  }
                >
                  Desktop
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                      "ring-white/60 focus:outline-none",
                      selected
                        ? "bg-primary text-white shadow-2xl"
                        : "bg-white text-primary"
                    )
                  }
                >
                  Mobile
                </Tab>
              </Tab.List>
              <button className="w-10 h-10 bg-white border p-1.5 rounded-full border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045]" onClick={openUploadModal}>
                <Icon path={mdiTrayArrowUp} size={0.9} />
              </button>
            </div>
            <Tab.Panels className="mt-2 flex-grow">
              <Tab.Panel className="h-full">
                <div className="h-full">
                  <DesktopWallpapers
                    wallpaperImages={wallpaperImages}
                    removeWallpaperImage={removeWallpaperImage}
                  />
                </div>
              </Tab.Panel>
              <Tab.Panel className="h-full">
                <div className="h-full">
                  <MobileWallpapers
                    wallpaperImages={wallpaperImages}
                    removeWallpaperImage={removeWallpaperImage}
                  />
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <WallpaperUpload
          open={open}
          device={device}
          setDevice={() => setDevice}
          setOpen={setOpen}
          uploadFiles={uploadFiles}
        />
      </div>

    </>
  );
};

export default Wallpaper;
