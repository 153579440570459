import { ACTION_TYPES } from "../constants";

const INITIAL_STATE = {
    HODList: [],
    HOSList: [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        
        case ACTION_TYPES.SET_SAINT_LEADERS: {
            return { ...state, HODList: action.payload || [] };
        }

        case ACTION_TYPES.ADD_SAINT_LEADER: {
            return { ...state, HODList: [action.payload, ...state.HODList] };
        }

        case ACTION_TYPES.UPDATE_SAINT_LEADER: {
            const { saintId, updatedDetail } = action.payload || {};
            const _SaintLeaders = state.HODList.map((eachSaint) => {
                if (eachSaint._id === saintId) return updatedDetail;
                return eachSaint
            })
            return { ...state, HODList: _SaintLeaders };
        }

        case ACTION_TYPES.REMOVE_SAINT_LEADER: {
            const { saintId } = action.payload || {};
            const _SaintLeaders = state.HODList.filter((eachSaint) => {
                return eachSaint._id !== saintId
            })
            return { ...state, HODList: _SaintLeaders };
        }

        case ACTION_TYPES.SET_HOS_USERS: {
            return { ...state, HOSList: action.payload || [] };
        }

        case ACTION_TYPES.ADD_HOS_USERS: {
            return { ...state, HOSList: [action.payload, ...state.HOSList] };
        }

        case ACTION_TYPES.UPDATE_HOS_USERS: {
            const { saintId, updatedDetail } = action.payload || {};
            const _SaintLeaders = state.HOSList.map((eachSaint) => {
                if (eachSaint._id === saintId) return updatedDetail;
                return eachSaint
            })
            return { ...state, HOSList: _SaintLeaders };
        }

        case ACTION_TYPES.REMOVE_HOS_USERS: {
            const { saintId } = action.payload || {};
            const _SaintLeaders = state.HOSList.filter((eachSaint) => {
                return eachSaint._id !== saintId
            })
            return { ...state, HOSList: _SaintLeaders };
        }
    }
    return state;
}
