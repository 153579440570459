import React from "react";
import { useRoutes } from "react-router-dom";
import { FormList } from "../../../Components";
import ViewApplicantUsers from "../../../Components/form/viewApplicantUsers";
export default function FormRouter() {
  const FormRoute = [
    {
      path: "/list/*",
      element: (
        <>
          <FormList />
        </>
      ),
    },
    {
      path: "/list/:id/*",
      element: (
        <>
          <ViewApplicantUsers />
        </>
      ),
    },
  ];
  const FormRouter = useRoutes(FormRoute);
  return FormRouter;
}
