import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose, mdiTrashCan } from "@mdi/js";
import AreaField from "../Common/Input/AreaField";
import DatePickerField from "../Common/Rsuite-Datepicker";
import { SABHA_TYPES } from "../../constants/common.constants";
import { Radio, Typography, Checkbox } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";
import { toastr } from "../../services/common.service";
import { createForm, updateForm, updateFormFile } from "./actionCreator";
import moment from "moment";
import { s3Url } from "../../services/api.service";

const genderOptions = ["All", "Male", "Female"];
const AddFormModal = ({ open, setOpen, formList, setFormList, selectedForm }) => {
  const [dayCount, setDayCount] = useState(0);
  const [formVisibility, setFormVisibility] = useState({});
  const [formData, setFormData] = useState(selectedForm || { gender: "All" });
  const [infoCollectionType, setInfoCollectionType] = useState("dateSelection")
  const [imagePreviewFile, setImagePreviewFile] = useState(null);
  const [iconPreviewFile, setIconPreviewFile] = useState(null);

  const handleRadioChange = (value) => {
    setFormVisibility({ isActive: value });
    if (value === "Sabha") {
      setFormData({ 
        ...formData, 
        visibility: "Sabha",
        visibilityOptions: { sabhas: [] } 
      });
    } else if (value === "Department") {
      setFormData({ 
        ...formData, 
        visibility: "Department",
        visibilityOptions: { departments: [] } 
      });
    } else {
      setFormData({ 
        ...formData, 
        visibility: "All",
      });
    }
  };

  const { HODList: Departments } = useSelector(
    (state) => state.SaintLeaderReducer
  );

  const setValue = (name, value) => {
    if (value) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const DepartmentsList = Departments.map((dep) => ({
    label: dep.departmentName,
    value: dep._id,
    sabhaId: dep.sabhaId,
  }));

  const handleFileChange = (name,file) => {
    setValue([name], file);
    if (file && name === "formImage") {
      setImagePreviewFile(URL.createObjectURL(file));
    } else if (file && name === "formIcon") {
      setIconPreviewFile(URL.createObjectURL(file))
    }
  };
  
  const handleSabhaChange = (sabha) => {
    const updatedSabhas = [...(formData.visibilityOptions?.sabhas || [])];
    if (updatedSabhas.includes(sabha)) {
      setFormData({ 
        ...formData, 
        visibilityOptions: {
          ...formData.visibilityOptions,
          sabhas: updatedSabhas.filter((s) => s !== sabha)
        }
      });
    } else {
      setFormData({ 
        ...formData, 
        visibilityOptions: {
          ...formData.visibilityOptions,
          sabhas: [...updatedSabhas, sabha]
        }
      });
    }
  };

  const handleDepartmentChange = (dept) => { 
    const updatedDepartment = [...(formData.visibilityOptions?.departments || [])];
    
    if (updatedDepartment.includes(dept)) {
      setFormData({ 
        ...formData, 
        visibilityOptions: {
          ...formData.visibilityOptions,
          departments: updatedDepartment.filter((s) => s !== dept)
        }
      });
    } else {
      setFormData({ 
        ...formData, 
        visibilityOptions: {
          ...formData.visibilityOptions,
          departments: [...updatedDepartment, dept]
        }
      });
    }
  };

  const handleSingleCheckboxChange = (name, isChecked) => {
    setFormData({
      ...formData,
      [name]: isChecked
    });
  };

   // start from here
   useEffect(() => {
    if(Object.keys(selectedForm).length) {
      setFormVisibility({ isActive: selectedForm?.visibility || "All" });
      setFormData({
        title: selectedForm.title,
        description: selectedForm.description,
        isEditable: selectedForm.allowEditingAfterFillup,
        filledUpDateLimit: [new Date(selectedForm.fillupTiming.fromDate), new Date(selectedForm.fillupTiming.toDate)],
        eventDuration: [new Date(selectedForm.fromDate), new Date(selectedForm.toDate)],
        eventEndDate: new Date(selectedForm.eventEndDate),
        visibilityOptions: selectedForm.visibilityOptions,
        visibility: selectedForm.visibility,
        gender: selectedForm.gender,
        willJoinWithDays: selectedForm.willJoinWithDays,
        requiresAttendeeCount: selectedForm.requiresAttendeeCount,
      });
      setDayCount(selectedForm?.days || 0)
      selectedForm.imageUrl && setImagePreviewFile(`${s3Url}${selectedForm.imageUrl}`)
      selectedForm.formIconUrl && setIconPreviewFile(`${s3Url}${selectedForm.formIconUrl}`)
    }
  }, [selectedForm])

  const createFormFunc = async () => {
    try {
      if (!formData?.formIcon) {
        toastr.show("Please upload Form Icon", "error");
        return;
      }
      if (!formData?.formImage) {
        toastr.show("Please upload Form Image", "error");
        return;
      }
      if (!formData?.title) {
        toastr.show("Please enter title", "error");
        return;
      }
      if (!formData?.description) {
        toastr.show("Please enter title", "error");
        return;
      }
      if (!formData?.filledUpDateLimit) {
        toastr.show("Please enter Fillup time", "error");
        return;
      }
      if (!formData?.visibility) {
        toastr.show("Please select Visibility", "error");
        return;
      }
      if (!formData?.gender) {
        toastr.show("Please select Gender", "error");
        return;
      }
      if (!formData?.eventEndDate) {
        toastr.show("Please enter event end date", "error");
        return;
      }
      let newData = {
        title: formData.title,
        description: formData.description,
        allowEditingAfterFillup: formData.isEditable,
        fillupTiming: {
          fromDate: formData.filledUpDateLimit[0],
          toDate: formData.filledUpDateLimit[1]
        },
        days: dayCount,
        fromDate: formData.eventDuration[0],
        toDate: formData.eventDuration[1],
        eventEndDate: formData.eventEndDate,
        visibility: formData.visibility,
        visibilityOptions: formData.visibilityOptions,
        gender: formData.gender,
        willJoinWithDays: formData.willJoinWithDays,
        requiresAttendeeCount: formData.requiresAttendeeCount,
      }
      let response = await createForm(newData);
      if (response.data) {
        toastr.show("Successfully created!", "success");
        const payloadFormData = new FormData();
        formData.formIcon && payloadFormData.append('formIcon', formData.formIcon);
        formData.formImage && payloadFormData.append('formImage', formData.formImage);
        if(formData.formIcon || formData.formImage) {
          response = await updateFormFile(response.data._id, payloadFormData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          });
          if(!response.data) {
            toastr.show("Something went wrong on file upload", "error")
          }
        }
        if (!formList?.length) formList = [response.data];
        else formList.push(response.data);
        setFormList(formList);
        setOpen(false);
      } else {
        toastr.show("Something went wrong!", "error");
      }
    } catch (error) {
      toastr.show("Something went wrong", "error")
      console.log("createFormFunc: ", error)
    }
  }

  const updateFormFunc = async () => {
    try {
      if (!formData?.title) {
        toastr.show("Please enter title", "error");
        return;
      }
      if (!formData?.filledUpDateLimit?.length) {
        toastr.show("Please enter Fillup time", "error");
        return;
      }
      if (!formData?.visibility) {
        toastr.show("Please select Visibility", "error");
        return;
      }
      if (!formData?.gender) {
        toastr.show("Please select Gender", "error");
        return;
      }
      if (!formData?.eventEndDate) {
        toastr.show("Please enter event end date", "error");
        return;
      }
      let newData = {
        title: formData.title,
        description: formData.description,
        allowEditingAfterFillup: formData.isEditable,
        fillupTiming: {
          fromDate: formData.filledUpDateLimit[0],
          toDate: formData.filledUpDateLimit[1]
        },
        days: dayCount,
        fromDate: formData.eventDuration[0],
        toDate: formData.eventDuration[1],
        eventEndDate: formData.eventEndDate,
        visibility: formData.visibility,
        visibilityOptions: formData.visibilityOptions,
        gender: formData.gender,
        willJoinWithDays: formData.willJoinWithDays,
        requiresAttendeeCount: formData.requiresAttendeeCount,
      }
      const payloadFormData = new FormData();
      formData.formIcon && payloadFormData.append('formIcon', formData.formIcon);
      formData.formImage && payloadFormData.append('formImage', formData.formImage);
      if(formData.formIcon || formData.formImage) {
        const response = await updateFormFile(selectedForm._id, payloadFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        });
        if(!response.data) {
          toastr.show("Something went wrong on file upload", "error")
        }
      }
      const response = await updateForm(selectedForm._id, newData);
      if (response.data) {
        toastr.show("Successfully updated!", "success");
        const newList = formList
          .map(f => {
            if (f._id + "" === selectedForm._id + "") {
              return { ...f, ...response.data }
            }
            return { ...f };
          })
        setFormList(newList);
        setOpen(false);
      } else {
        toastr.show("Something went wrong!", "error");
      }
    } catch (error) {
      toastr.show("Something went wrong", "error")
      console.log("updateFormFunc: ", error)
    }
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      New Form
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              {/* {newImageUpload && ( */}
                              <>
                                <div className="mb-5">
                                  <Input
                                    label="Image Upload"
                                    accept=".jpg, .png, .jpeg, .gif, .webp, .bmp, .tif, .tiff|image/*"
                                    className="w-full"
                                    id="file"
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        "formImage",
                                        e?.target?.files[0]
                                      )
                                    }
                                  />
                                </div>
                                <div className="mb-5">
                                  <img
                                    src={
                                      imagePreviewFile
                                        ? imagePreviewFile
                                        : "/assets/image/no-thumbnail.png"
                                    }
                                    // src={"/assets/image/no-thumbnail.png"}
                                    className="w-[100px]"
                                  />
                                </div>
                                <div className="mb-5">
                                  <Input
                                    label="Upload Flag Image"
                                    accept=".jpg, .png, .jpeg, .gif, .webp, .bmp, .tif, .tiff|image/*"
                                    className="w-full"
                                    id="file"
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        "formIcon",
                                        e?.target?.files[0]
                                      )
                                    }
                                  />
                                </div>
                                <div className="mb-5">
                                  <img
                                    src={
                                      iconPreviewFile
                                        ? iconPreviewFile
                                        : "/assets/image/no-thumbnail.png"
                                    }
                                    // src={"/assets/image/no-thumbnail.png"}
                                    className="w-[100px]"
                                  />
                                </div>
                              </>
                              {/* )} */}
                              <div className="mb-5">
                                <Input
                                  label="Form Title"
                                  className=" w-full"
                                  value={formData?.title || ""}
                                  onChange={(e) =>
                                    setValue("title", e.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <AreaField
                                  placeholder="Form Description"
                                  rows="4"
                                  className=" w-full"
                                  val={formData?.description || ""}
                                  onChange={(e) =>
                                    setValue("description", e.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <div className="text-sm mb-5 text-gray300 font-semibold inline-flex items-center">
                                  <span>Event Date</span>
                                </div>
                                <div className="flex">
                                  <div className="flex items-center mr-5">
                                    <Radio
                                      defaultChecked
                                      name="dateSelection"
                                      value="dateSelection"
                                      checked={
                                        infoCollectionType === "dateSelection"
                                      }
                                      label={
                                        <span className="text-sm text-gray300 font-semibold">
                                          Date selection
                                        </span>
                                      }
                                      onChange={() => {
                                        setInfoCollectionType("dateSelection");
                                      }}
                                    />
                                  </div>
                                  {/* <div className="flex items-center">
                                    <Radio
                                      name="dateSelection"
                                      value="approval"
                                      checked={
                                        infoCollectionType === "approval"
                                      }
                                      label={
                                        <span className="text-sm text-gray300 font-semibold">
                                          Approval
                                        </span>
                                      }
                                      onChange={() => {
                                        setInfoCollectionType("approval");
                                      }}
                                    />
                                  </div> */}
                                </div>
                                {infoCollectionType === "dateSelection" && (
                                  <div className="max-w-[300px] mt-3">
                                    <DateRangePicker
                                      format="MM/dd/yyyy"
                                      showMeridian
                                      cleanable={false}
                                      placement="autoVerticalStart"
                                      value={formData.eventDuration}
                                      onChange={(date) => {
                                        setValue("eventDuration", date);
                                        if (date && date.length === 2) {
                                          const diffInDays = moment(date[1]).diff(moment(date[0]), 'days');
                                          setDayCount(diffInDays + 1);
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="mb-5 flex items-center">
                                <div className="text-sm text-gray300 font-semibold block mr-3">
                                  Total Present Days
                                </div>
                                <div className=" appearance-none text-sm text-black900 outline-none rounded-lg ">
                                  {dayCount ? dayCount : 0}
                                </div>
                              </div>
                              <div className="flex items-center">
                                <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                  Form Visibility
                                  <div className="flex items-center">
                                    <div className="w-1/2">
                                      <Radio
                                        name="All"
                                        value="All"
                                        label="All"
                                        onChange={() =>
                                          handleRadioChange("All")
                                        }
                                        checked={
                                          formVisibility.isActive === "All"
                                        }
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <Radio
                                        name="Sabha"
                                        value="Sabha"
                                        label="Sabha"
                                        onChange={() =>
                                          handleRadioChange("Sabha")
                                        }
                                        checked={
                                          formVisibility.isActive === "Sabha"
                                        }
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <Radio
                                        name="Department"
                                        value="Department"
                                        label="Department"
                                        onChange={() =>
                                          handleRadioChange("Department")
                                        }
                                        checked={
                                          formVisibility.isActive ===
                                          "Department"
                                        }
                                      />
                                    </div>
                                  </div>
                                </label>
                              </div>
                              {formVisibility.isActive === "Sabha" ? (
                                <div className="mb-5 ml-5 flex items-center">
                                  {SABHA_TYPES?.map((sabha) => {
                                    const isChecked = formData.visibilityOptions?.sabhas?.includes(sabha._id) || false;
                                    return (
                                      <div className="">
                                        <Checkbox
                                          className="w-4 h-4"
                                          label={
                                            <Typography className="poppins text-sm">
                                              {sabha.name}
                                            </Typography>
                                          }
                                          checked={isChecked}
                                          onChange={() =>
                                            handleSabhaChange(sabha._id)
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}

                              {formVisibility.isActive === "Department" ? (
                                <div className="mb-5 ml-5 flex items-center flex-wrap">
                                  {DepartmentsList?.map((dep) => {
                                    return (
                                      <div className="flex items-center">
                                        <Checkbox
                                          className="h-4 w-4"
                                          label={
                                            <Typography className="poppins">
                                              {dep.label}
                                            </Typography>
                                          }
                                          checked={(
                                            formData.visibilityOptions
                                              ?.departments || []
                                          ).includes(dep.label)}
                                          onChange={() =>
                                            handleDepartmentChange(dep.label)
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="mb-5">
                                <label className="text-sm text-gray300 font-semibold inline-block">
                                  Gender
                                </label>
                                <div>
                                  {genderOptions.map((item) => {
                                    return (
                                      <Radio
                                        key={item}
                                        name="gender"
                                        defaultChecked={
                                          formData.gender === item
                                        }
                                        label={
                                          <Typography className="poppins">
                                            {item}
                                          </Typography>
                                        }
                                        value={item} 
                                        // value={formData.gender}
                                        onChange={(e) =>
                                          setValue("gender", e.target.value)
                                        } // Update formData when a different option is selected
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="mb-5">
                                <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                  Filled up Date Limit
                                </label>
                                <DateRangePicker
                                  format="MM/dd/yyyy"
                                  showMeridian
                                  placement="autoVerticalStart"
                                  value={formData.filledUpDateLimit}
                                  onChange={(date) => {
                                    setValue("filledUpDateLimit", date);
                                  }}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                  Event End Date
                                </label>
                                <div className="flex gap-2">
                                  <DatePickerField
                                    placeholder="Select Start Date"
                                    value={formData.eventEndDate}
                                    cleanable={false}
                                    onChange={(date) => {
                                      setValue("eventEndDate", date);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <Checkbox
                                  label={
                                    <Typography className="poppins">
                                      Editing field after field up by sabhya
                                    </Typography>
                                  }
                                  checked={formData["isEditable"]}
                                  onChange={(e) =>
                                    handleSingleCheckboxChange(
                                      "isEditable",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <Checkbox
                                  label={
                                    <Typography className="poppins">
                                      required Attendee Count
                                    </Typography>
                                  }
                                  checked={formData["requiresAttendeeCount"]}
                                  onChange={(e) =>
                                    handleSingleCheckboxChange(
                                      "requiresAttendeeCount",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <Checkbox
                                  label={
                                    <Typography className="poppins">
                                      will Join With Days
                                    </Typography>
                                  }
                                  checked={formData["willJoinWithDays"]}
                                  onChange={(e) =>
                                    handleSingleCheckboxChange(
                                      "willJoinWithDays",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button className="capitalize text-base bg-orange-600" 
                        onClick={() => selectedForm?._id ? updateFormFunc() : createFormFunc()}
                        >
                          {selectedForm?._id ? "Update":"Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddFormModal;
