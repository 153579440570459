import { api } from "../../../services/api.service";

export const getLiveVideoList = async () => {
  const response = await api.get("admin/all-live-video-list");
  return response;
};

export const createLiveVideo = async (data) => {
  const response = await api.post("admin/live-list/create", data);
  return response;
};

export const updateLiveVideo = async (data) => {
  const response = await api.post("admin/live-list/update", data);
  return response;
};

export const deleteLiveVideo = async (id) => {
  const params = { _id: id };
  const response = await api.post("admin/live-list/delete", params);
  return response;
};

export const changeLiveVideoVisibility = async (params) => {
  const response = await api.post("admin/live-list/change-visibility", params);
  return response;
};

export const updateLiveStreamPosition = async (params) => {
  const response = await api.post(
    "admin/live-list/updateLiveStreamPosition",
    params
  );
  return response;
};
