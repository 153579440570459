import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import CreatePlaylistModal from "./CreatePlaylistModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon } from "@iconify/react";
import { useReactNavigator } from "../../../utils/reactNavigator";
import { motion } from "framer-motion";
import {
  changePlaylistVisibility,
  deletePlaylist,
  getPlayList,
  updatePlaylistPosition,
} from "./actionCreator";
import DeleteModal from "../../Common/DeleteModal/DeleteModal";
import { toastr } from "../../../services/common.service";

const columnHelper = createColumnHelper();

const DraggableRow = ({ row, reorderRow }) => {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: (draggedRow) => reorderRow(draggedRow.index, row.index),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: "row",
  });

  return (
    <motion.tr
      ref={previewRef}
      initial={{ opacity: 1 }}
      animate={{ opacity: isDragging ? 0.5 : 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <td ref={dropRef} className="text-center">
        <button ref={dragRef} className="cursor-grab">
          <Icon icon="grommet-icons:drag" className="text-xl" />
        </button>
      </td>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} className="p-2">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </motion.tr>
  );
};

const Playlist = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(() => []);
  const [selectedPlaylist, setSelectedPlaylist] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { navigate } = useReactNavigator();

  const reorderRow = (draggedRowIndex, targetRowIndex) => {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
    setData([...data]);
  };

  const publishPlaylistPosition = async () => {
    let newList = [...data];
    let updatedItem = [];
    newList.map((item, index) =>
      updatedItem.push({ _id: item._id, position: index })
    );
    const result = await updatePlaylistPosition({ playlists: updatedItem });
    if (result.data) {
      toastr.show(result.data, "success");
    }
  };

  const openCreateLinkModal = (_id = null) => {
    if (_id) {
      const currentDetail = data.find((list) => list._id + "" === _id + "");
      setSelectedPlaylist(currentDetail);
    } else {
      setSelectedPlaylist({});
    }
    setOpen(!open);
  };

  const getAllPlayList = async () => {
    const liveListData = await getPlayList();
    setData(liveListData.data || []);
  };

  useEffect(() => {
    getAllPlayList();
  }, []);

  const handleCloseModal = async (status) => {
    if (status) {
      const updatedData = await deletePlaylist(deleteId);
      if (updatedData?.data) {
        toastr.show("Deleted!!", "success");
        const newList = data.filter((d) => d._id + "" !== deleteId + "");
        setData(newList);
      } else {
        toastr.show("Something went wrong", "error");
      }
    }
    setShowDeleteModal(false);
    setDeleteId("");
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteId(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const changeVisibility = async (_id, visibility) => {
    const changeVisibility = await changePlaylistVisibility({
      _id,
      visibility,
    });
    if (changeVisibility.data) {
      toastr.show("Visibility Updated!", "success");
      const newList = data.map((d) => {
        if (d._id + "" === _id + "") {
          return { ...d, visibility };
        }
        return d;
      });
      setData(newList);
    } else {
      toastr.show("Something went wrong", "error");
    }
  };

  const columns = [
    columnHelper.accessor("thumbnailImages", {
      cell: (info) => (
        <img
          src={
            info.getValue()?.default
              ? info.getValue()?.default
              : "/assets/image/no-thumbnail.png"
          }
          alt="thumbnail"
          className="w-[100px] rounded-lg"
        />
      ),
      header: () => <span>Thumbnail</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("vakta", {
      cell: (info) => info.getValue(),
      header: () => <span>Vakta</span>,
    }),
    columnHelper.accessor("category", {
      cell: (info) => info.getValue(),
      header: () => <span>Category</span>,
    }),
    columnHelper.accessor("visibility", {
      cell: (info) => (
        <Switch
          checked={info.getValue()}
          onChange={() =>
            changeVisibility(info.row.original._id, !info.getValue())
          }
          className={`${
            info.getValue() ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span
            className={`${
              info.getValue() ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      ),
      header: () => <span>Visibility</span>,
    }),
    columnHelper.accessor("videos", {
      cell: (info) => <span>{info.getValue()?.length}</span>,
      header: () => <span>Video Counts</span>,
    }),
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
              <button
                onClick={() => openCreateLinkModal(props.row.original?._id)}
              >
                <Icon
                  icon="ic:baseline-edit"
                  className="text-2xl transition-all duration-150 text-black900"
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button>
                <Icon
                  icon="material-symbols:delete"
                  className="transition-all duration-150 text-2xl text-black900"
                  onClick={() => handleOpenDeleteModal(props.row.original?._id)}
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button
                onClick={() => {
                  navigate(`${props.row.original?._id}`);
                }}
              >
                <Icon
                  icon="ion:enter"
                  className="text-2xl transition-all duration-150 text-black900"
                />
              </button>
            </span>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable(
    {
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.id,
    },
    [data]
  );

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Playlist</div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={() => openCreateLinkModal()}
          >
            <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> Create
            Playlist
          </Button>
        </div>

        <div className="bg-white w-full px-6 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="overflow-auto">
            <DndProvider backend={HTML5Backend}>
              <table className="w-full overflow-auto">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                        <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="text-left font-semibold p-2"
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "last:text-center",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody className="className='last:!border-b-0'">
                  {table.getRowModel().rows.map((row) => (
                    <DraggableRow
                      key={row.id}
                      row={row}
                      reorderRow={reorderRow}
                    />
                  ))}
                </tbody>
              </table>
            </DndProvider>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-3 inline-flex justify-center uppercase rounded-md font-semibold bg-white px-4 py-2 text-sm  text-red-600 hover:text-red-600 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center uppercase rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary focus:outline-none"
              onClick={() => publishPlaylistPosition()}
            >
              Publish
            </button>
          </div>
        </div>
      </div>
      <DeleteModal open={showDeleteModal} handleCloseModal={handleCloseModal} />
      <CreatePlaylistModal
        open={open}
        setOpen={setOpen}
        playlist={data}
        setPlaylist={setData}
        selectedPlaylist={selectedPlaylist}
      />
    </>
  );
};

export default Playlist;
