import { api } from "../../../services/api.service";
import { toastr } from "../../../services/common.service";

export const getSabha = async (userData) => {
    const response = await api.get('admin/sabha', userData);
    return response;
}

export const createSabha = async (userData) => {
    try {
        const response = await api.post('admin/sabha/create', userData);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const updateSabha = async (userData) => {
    try {
        const response = await api.post('admin/sabha/update', userData);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const deleteSabha = async (userData) => {
    const response = await api.post('admin/sabha/delete', userData);
    return response;
}

export const startAttendence = async (params) => {
    const response = await api.post('admin/sabha/start', params);
    return response;
}

export const countAttendence = async (params) => {
    const response = await api.post('admin/sabha/count', params);
    return response;
}

export const completeAttendence = async (params) => {
    const response = await api.post('admin/sabha/complete', {sabhaId : params});
    return response;
}