export const PAGES = {
    LOGIN: '/',
    HOME: 'photo&banner/dailydarshan',
}

export const ROLES = {
    ADMIN: "admin",
    HOS: "hos",
    HOD: "hod",
}

export const ROLES_PERMISSIONS = {
    [ROLES.HOD]: ["MANAGE_USERS"],
    [ROLES.HOS]: ["MANAGE_USERS"],
}

export const SABHA_TYPES = [
    {
        "_id": "6533aa855da1a84b9ad7a706",
        "name": "Yuva Sabha",
        "addedBy": "65315ff9f08e818c7ae4874c",
        "updatedBy": "65315ff9f08e818c7ae4874c",
        "isDeleted": false
    },
    {
        "_id": "6533aaf85da1a84b9ad7a707",
        "name": "Atmiya Sabha",
        "addedBy": "65315ff9f08e818c7ae4874c",
        "updatedBy": "65315ff9f08e818c7ae4874c",
        "isDeleted": false
    },
    {
        "_id": "6533ab025da1a84b9ad7a708",
        "name": "Kishor Sabha",
        "addedBy": "65315ff9f08e818c7ae4874c",
        "updatedBy": "65315ff9f08e818c7ae4874c",
        "isDeleted": false
    },
    {
        "_id": "66b6521400b4f48cec4a0778",
        "name": "Foreigner Sabha",
        "addedBy": "65315ff9f08e818c7ae4874c",
        "updatedBy": "65315ff9f08e818c7ae4874c",
        "isDeleted": false
    }
];

export const GROUP_NUMBERS = [...Array(100).keys()].map((d) => ({
    label: (d + 1).toString(),
    value: (d + 1).toString(),
}));

export const LEADER_PRIORITY = [...Array(5).keys()].map((d) => ({
    label: (d + 1).toString(),
    value: (d + 1).toString(),
}));

export const genderType = [
    {
      label: "male",
      value: "male",
    },
    {
      label: "female",
      value: "female",
    },
];

export const MonthOptions = [
    {
        label: "January",
        value: "1",
    },
    {
        label: "February",
        value: "2",
    },
    {
        label: "March",
        value: "3",
    },
    {
        label: "April",
        value: "4",
    },
    {
        label: "May",
        value: "5",
    },
    {
        label: "June",
        value: "6",
    },
    {
        label: "July",
        value: "7",
    },
    {
        label: "August",
        value: "8",
    },
    {
        label: "September",
        value: "9",
    },
    {
        label: "October",
        value: "10",
    },
    {
        label: "November",
        value: "11",
    },
    {
        label: "December",
        value: "12",
    }
];