import { api } from "./api.service";
import { ACTION_TYPES } from "../redux/constants";

export const fetchData = async (dispatch) => {
    const result = await api.get('/admin/department');
    if (result?.data) {
        dispatch({ type: ACTION_TYPES.SET_SAINT_LEADERS, payload: result.data });
    }

    const flags = await api.get("/admin/all-flags");
    if (flags?.data) {
        dispatch({ type: ACTION_TYPES.SET_MEMBER_FLAG, payload: flags.data });
    }
}