import { api } from "../../../services/api.service";

export const createUser = async (userData) => {
    const response = await api.post('admin/user/create', userData);
    return response;
}

export const updateUser = async (userData) => {
    const response = await api.post('admin/user/update', userData);
    return response;
}

export const createNewMember = async (userData) => {
    const response = await api.post('admin/new-user/create', userData);
    return response;
}

export const updateNewMember = async (userData) => {
    const response = await api.post('admin/new-user/update', userData);
    return response;
}