import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import EventUpload from "./UploadContent";

const UploadItems = (props) => {
  const { files, setFiles, imageType } = props;
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleFileChange = (_files) => {
    const selectedFiles = _files;
    setFiles([...files, ...selectedFiles]);

    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previews.push(e.target.result);
        if (previews.length === selectedFiles.length) {
          setImagePreviews([...imagePreviews, ...previews]);
        }
      };
      reader.readAsDataURL(selectedFiles[i]);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    const updatedPreviews = [...imagePreviews];
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setFiles(updatedFiles);
    setImagePreviews(updatedPreviews);
  };

  return (
    <div>
      {imagePreviews.length > 0 && (
        <div className="grid grid-cols-6 gap-4">
          {imagePreviews.map((preview, index) => {
            return (
              <div key={"img" + index} className=" rounded-lg relative">
                <img
                  src={preview}
                  className="block rounded-lg w-full h-full object-contain object-center"
                />
                <div className="absolute rounded-lg w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.50)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                  <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease]">
                    <button onClick={() => handleRemoveFile(index)}>
                      <Icon icon="ph:trash-fill" />
                    </button>
                  </a>
                </div>
              </div>
            );
          })}
          <label
            htmlFor="more-upload"
            className="w-[160px] h-[200px] cursor-pointer flex justify-center bg-gray-50 hover:bg-gray-100 flex-col items-center m-1 rounded-lg"
          >
            <div
              htmlFor="2"
              className="w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
            >
              <Icon
                icon="mdi:image-add"
                color={"#A5A5A5"}
                className="cursor-pointer text-3xl"
              />
            </div>
            <div className="text-sm text-center max-w-[100px] mt-2">
              Upload more images
            </div>
          </label>
          <input
            type="file"
            id="more-upload"
            onChange={(e) => handleFileChange(e.target.files)}
            multiple={true}
            hidden
          />
        </div>
      )}
      {imagePreviews.length === 0 && (
        <EventUpload handleFileChange={handleFileChange} multiple={true} />
      )}
    </div>
  );
};

export default UploadItems;
