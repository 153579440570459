import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { toastr } from "../../../services/common.service";
import TextField from "../../Common/Input/TextField";
import { SABHA_TYPES } from "../../../constants/common.constants";
import SelectField from "../../Common/Input/SelectField";
import DepartmentTable from "./DepartmentTable";
import {
  createDepartment,
  getDepartments,
} from "./actionCreator";

const DepartmentList = () => {
  const dispatch = useDispatch();

  const [departmentName, setDepartmentName] = useState("");
  const [sabhaId, setSabhaId] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);

  async function getDepartmentsFun() {
    const depResult = await getDepartments(dispatch);
    if (depResult?.data) {
      setDepartmentList(depResult?.data || []);
    }
  }

  useEffect(() => {
    getDepartmentsFun();
  }, []);

  const createDepartmentEntry = async () => {
    if (departmentName && sabhaId) {
      const sabhaDetail = SABHA_TYPES.find((d) => d._id === sabhaId.value);
      if (!sabhaDetail) {
        toastr.show("Invalid Sabha", "error");
        return;
      }
      const result = await createDepartment({
        departmentName,
        sabhaId: sabhaDetail._id,
        sabhaName: sabhaDetail.name,
      });
      if (result?.data) {
        toastr.show("Success !!", "success");
        getDepartmentsFun();
      }

      setDepartmentName("");
      setSabhaId("");
    } else {
      toastr.show("Enter All the Details", "error");
    }
  };

  return (
    <>
      <div className="border-b border-stone-200 pb-3 px-3 flex items-center flex-wrap gap-2">
        <div className="relative">
          <TextField
            placeholder="Add Department Name"
            className="bg-gray200 text-sm h-9 w-full text-gray-400 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
            value={departmentName}
            onChange={(e) => {
              setDepartmentName(e.target.value);
            }}
          />
        </div>
        <div className="mx-1">
          <SelectField
            required={true}
            placeholder={"Select Sabha"}
            defaultOptions={SABHA_TYPES.map((sType) => ({
              label: sType.name,
              value: sType._id,
            }))}
            value={sabhaId}
            onChange={(selectedSabha) => setSabhaId(selectedSabha)}
          />
        </div>
        <div>
          <button
            className="inline-flex items-center bg-primary text-white text-sm px-2 py-2 rounded-lg"
            onClick={() => createDepartmentEntry()}
          >
            Add Department
          </button>
        </div>
      </div>
      <div className="p-2 mt-3 overflow-auto">
        <table className="w-full member-table table-auto">
          {departmentList.length > 0 && (
            <>
              <thead>
                <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                  <th className="text-left font-semibold p-2 whitespace-pre">Department Name</th>
                  <th className="text-left font-semibold p-2">Sabha</th>
                  <th className="text-left font-semibold p-2">Groups</th>
                  <th className="text-center font-semibold p-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {departmentList.map((department, indx) => {
                  return (
                    <DepartmentTable
                      indx={indx}
                      department={department}
                      getDepartmentsFun={getDepartmentsFun}
                      fullDepartmentList={departmentList}
                    />
                  );
                })}
              </tbody>
            </>
          )}
        </table>
      </div>
    </>
  );
};

export default DepartmentList;
