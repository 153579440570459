import { useRoutes } from "react-router-dom"
import { CustomizeLayout, EditPlaylist, Live, Playlist, LiveKatha } from "../../../Components"

export default function VideoRouter() {
    const videoRoute = [
        {
            path: "live/*",
            element: (
                <>
                    <Live />
                </>
            )
        },
        {
            path: "katha-live/*",
            element: (
                <>
                    <LiveKatha />
                </>
            )
        },
        {
            path: "/playlist/*",
            element: (
                <>
                    <Playlist />
                </>
            )
        },
        {
            path: "/playlist/:id/*",
            element: (
                <>
                    <EditPlaylist />
                </>
            )
        },
        {
            path: "/customizePlaylist/*",
            element: (
                <>
                    <CustomizeLayout />
                </>
            )
        },
    ]

    const VideoRouter = useRoutes(videoRoute)

    return VideoRouter
}