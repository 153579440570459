import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import { Icon } from '@iconify/react';
import moment from "moment-timezone";
import SelectField from '../Common/Input/SelectField';
import Modal from '../Common/Modal/Modal';
import { Button, Input } from "@material-tailwind/react";
import Datetime from 'react-datetime';
import { getMantraLekhanAudit } from "./actionCreator";
import { toastr } from "../../services/common.service";

moment.tz.setDefault('Asia/Calcutta');

const dateOption = [
    {
        label: "Today's",
        value: "todays",
    },
    {
        label: "Yesterday's",
        value: "yesterdays",
    },
    {
        label: "This Week",
        value: "thisweek",
    },
    {
        label: "Past Week",
        value: "pastweek",
    },
    {
        label: "This Month",
        value: "thisMonth",
    },
    {
        label: "Past Month",
        value: "pastMonth",
    }
];

const MantraLekhan = () => {
    const [data, setData] = useState([]);
    const [dateFilter, setDateFilter] = useState();
    const [openDateRangeModal, setOpenDateRangeModal] = useState(false);
    const [queryParameters] = useSearchParams();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showStartDatePicker, setShowStartDatePicker] = useState(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [smvNoFilter, setSMVNoFilter] = useState();
    const { pageIndex,  pageSize } = pagination;
    const statTimeInputRef = useRef();
    const endTimeInputRef = useRef();
    const [columnPinning, setColumnPinning] = useState({
        right: ["block"],
    });

    moment.updateLocale('en', {
        week : {
            dow :0  // 0 to 6 sunday to saturday
        }
    });

    
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date?.endOf('day'));
    };

    const resetFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setSelectedDateRange(null);
        setShowStartDatePicker(false);
        setDateFilter('');
        setSMVNoFilter('');
    }

    useEffect(() => {
        const queryParamPage = Number(queryParameters.get("page"));
        const queryParamPageSize = Number(queryParameters.get("pageSize"));
        if (queryParamPage && queryParamPageSize) {
            setPagination({pageIndex: queryParamPage, pageSize: queryParamPageSize});
        } else if (queryParamPage) {
            setPagination({pageIndex: queryParamPage, pageSize: pageSize});
        } else if (queryParamPageSize) {
            setPagination({pageIndex: pageIndex, pageSize: queryParamPageSize});
        }
    }, []);

    useEffect(() => {
        applyFilter();
    }, [pageIndex, pageSize]);
    
    const getMantraLekhanJap = async () => {
        try {
            const result = await getMantraLekhanAudit(`limit=${pagination?.pageSize}&skip=${(pagination?.pageSize)*(pagination?.pageIndex)}`);
            if (result?.data) {
                setData(result?.data?.items || []);
                setPageCount(Math.ceil(result?.data?.totalCount/pageSize));
            }
        } catch (err) {
            toastr.show("Failed !!", "error");
        }
    };

    const handleStartTimeChange = (value) => {
        const todayDate = new Date();
        const [hours, mins] = value?.target?.value?.split(":");
        const dateTimeFormat = moment([todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(), hours, mins]);
        setStartDate(moment(dateTimeFormat));
    }

    const handleEndTimeChange = (value) => {
        const todayDate = new Date();
        const [hours, mins] = value?.target?.value?.split(":");
        const dateTimeFormat = moment([todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(), hours, mins]);
        setEndDate(moment(dateTimeFormat));
    }
    
    const handleDateFilter = (val) => {
        if (val.value === 'todays') {
            setStartDate(moment()?.startOf('day'));
            setEndDate(moment()?.endOf('day'));
        }
        if (val.value === 'yesterdays') {
            setStartDate(moment().subtract(1, 'days').startOf('day'));
            setEndDate(moment().subtract(1, 'days').endOf('day'));
        }
        if (val.value === 'thisweek') {
            setStartDate(moment().subtract(0, 'weeks').startOf('week'));
            setEndDate(moment().subtract(0, 'weeks').endOf('week')); 
        }
        if (val.value === 'pastweek') {
            setStartDate(moment().subtract(1, 'weeks').startOf('week'));
            setEndDate(moment().subtract(1, 'weeks').endOf('week')); 
        } 
        if (val.value === 'thisMonth') {
            setStartDate(moment().subtract(0, 'month').startOf('month'));
            setEndDate(moment().subtract(0, 'month').endOf('month')); 
        }
        if (val.value === 'pastMonth') {
            setStartDate(moment().subtract(1, 'month').startOf('month'));
            setEndDate(moment().subtract(1, 'month').endOf('month')); 
        }
        setDateFilter(val);
    }

    const applyFilter = async() => {
        try {            
            let filterStr = '';
            if (startDate !== undefined && startDate) {
                filterStr += `&fromDate=${startDate}`;
            }             
            if (endDate !== undefined && endDate) {
                filterStr += `&toDate=${endDate}`;
            }
            if (smvNoFilter !== undefined && smvNoFilter && smvNoFilter?.value !== '') {
                filterStr += `&SMVNo=${smvNoFilter}`;
            }
            filterStr += `&limit=${pagination?.pageSize}&skip=${(pagination?.pageSize)*(pagination?.pageIndex)}`;
            const result = await getMantraLekhanAudit(filterStr);
            if (result?.data) {
                setData(result?.data?.items || []);
                setPageCount(Math.ceil(result?.data?.totalCount/pageSize));
            }
        } catch (err) {
            console.log(err);
            toastr.show("Failed !!", "error");
        }
    }

    const handleDateRangeApply = () => {
        if (startDate && endDate) {
            setSelectedDateRange({
                startDate: startDate.format('DD/MM/YYYY'),
                endDate: endDate.format('DD/MM/YYYY')
            })
            setDateFilter("all")
        }
        setOpenDateRangeModal(false)
    }

    const handleSMVNoFilter = (val) => {
        setSMVNoFilter(val?.value);
    }

    const handleResetDateRange = () => {
        setStartDate(null);
        setEndDate(null);
        setSelectedDateRange();
        setOpenDateRangeModal(false)
    }

    const refreshData = () => {
        applyFilter();
    }    

    const columns = useMemo(
        () => [
            {
                accessorKey: "SMVNo",
                header: () => <span>SMV No.</span>,
                cell: (info) => info.getValue(),
                id: "SMVNo",
                size: 150,
                maxSize: 150,
            },
            {
                accessorKey: "mantraCount",
                header: () => <span>Mantra Count</span>,
                cell: (info) => info.getValue(),
                id: "totalCount",
                size: 150,
                maxSize: 150,
            }, 
            {
                accessorKey: "totalmantra",
                header: () => <span>Total Mantra</span>,
                cell: (info) => info.getValue(),
                id: "totalmantra",
                size: 150,
                maxSize: 150,
            },            
            {
                accessorKey: "source",
                header: () => <span>Source</span>,
                cell: (info) => info.getValue(),
                id: "source",
                size: 150,
                maxSize: 150,
            },
            {
                accessorKey: "timestamp",
                header: () => <span>Timestamp</span>,
                cell: (info) => (
                    <div className="">
                      {moment.utc(info.row.original?.timestamp).local().format('DD-MM-YYYY hh:mm:ss A')}
                    </div>
                  ),
                id: "timestamp",
                size: 150,
                maxSize: 150,
            },
            
        ]
    )

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        pageCount: pageCount,
        manualPagination: true,
        onPaginationChange: setPagination,
        state: {
            columnPinning,
            pagination
        },
        onColumnPinningChange: setColumnPinning,
        getPaginationRowModel: getPaginationRowModel(),
        getRowId: row => row.id,
    })
    return (
        <>
            <div className="h-[calc(100vh_-_81px)] p-3 bg-[#F8F8F8] overflow-auto">
                <div className='flex w-full h-[calc(100vh_-135px)]'>
                    <div className='p-3 flex-grow overflow-auto '>
                        <div className='flex justify-between items-center'>
                            <h1 className="text-lg font-semibold mb-6 text-black900">
                                Mantra Lekhan Audit
                            </h1>
                        </div>
                        <div className='flex flex-wrap gap-y-3 items-center mb-4'>
                            <div className='border cursor-pointer border-[#cccccc] text-[#808080] bg-white rounded-md px-2 py-0.5 mr-1.5 h-[38.48px] flex items-center justify-center' onClick={() => setOpenDateRangeModal(true)}>
                                {
                                    selectedDateRange ? `${selectedDateRange?.startDate} - ${selectedDateRange.endDate}` : "Select Date Range"
                                }
                            </div>
                            <div className="w-60 relative px-1 mr-1">
                                <SelectField
                                    required={true}
                                    placeholder="Select Date Filter"
                                    defaultOptions={dateOption}
                                    value={dateFilter}
                                    onChange={(val) => { handleDateFilter(val) }}
                                />
                            </div>
                            <div className="w-60 relative px-1 mr-1">
                                <div class="relative h-10 w-full min-w-[200px]">
                                    <input ref={statTimeInputRef} placeholder="Select Start Time" type="time" onChange={(evt) => handleStartTimeChange(evt)}
                                        class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                                    <label
                                        class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Select End Time
                                    </label>
                                </div>
                            </div>
                            <div className="w-60 relative px-1 mr-1">
                                <div class="relative h-10 w-full min-w-[200px]"> 
                                    <input ref={endTimeInputRef} placeholder="Select End Time" type="time" onChange={(evt) => handleEndTimeChange(evt)}
                                        class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                                    <label
                                        class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                        Select End Time
                                    </label>
                                </div>
                            </div>
                            <div className="w-60 relative px-1 mr-1">
                                <Input
                                    placeholder="SMVNo"
                                    value={smvNoFilter}
                                    onChange={(val) => { handleSMVNoFilter(val?.currentTarget) }}
                                />
                            </div>
                            <div className="relative px-1">
                                <Button
                                    variant="filled"
                                    onClick={() => { applyFilter() }}
                                >Apply
                                </Button>
                            </div>
                            <div className="relative px-1">
                                <Button
                                    variant="filled"
                                    onClick={() => { resetFilters() }}
                                >Clear
                                </Button>
                            </div>
                            <div className="relative px-1">
                                <Button
                                    variant="filled"
                                    onClick={() => { refreshData() }}
                                >Refresh
                                </Button>
                            </div>
                        </div>
                        <div className='overflow-auto'>
                            <table className="w-full member-table">
                                <thead>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr 
                                            key={headerGroup.id} 
                                            className='border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]'
                                        >
                                            {headerGroup.headers.map(header => {
                                                const { column } = header;
                                                return (
                                                    <th key={header.id} className='text-left font-semibold p-2 bg-[#F2F2F2]'>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id} 
                                            className='border-b border-stone-200 text-sm bg-white'
                                        >
                                            {row.getVisibleCells().map(cell => {
                                                const { column } = cell
                                                return (
                                                    <td key={cell.id} className='p-2 bg-white'>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex items-center justify-end gap-2 mt-3">
                            <button
                                className="border rounded w-8 h-8 grid place-items-center"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <Icon icon="lucide:chevron-left" className="text-xl" />
                            </button>
                            <button
                                className="border rounded w-8 h-8 grid place-items-center"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                <Icon icon="lucide:chevron-right" className="text-xl" />
                            </button>
                            <span className="flex items-center gap-1">
                                <div>Page</div>
                                <strong>
                                    {table.getState().pagination.pageIndex + 1} of{" "}
                                    {table.getPageCount().toLocaleString()}
                                </strong>
                            </span>
                            <span className="flex items-center gap-1">
                                | Go to page:
                                <input
                                    type="number"
                                    defaultValue={table.getState().pagination.pageIndex + 1}
                                    onChange={(e) => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                        table.setPageIndex(page);
                                    }}
                                    className="border p-1 rounded w-16 outline-none"
                                />
                            </span>
                            <select
                                value={table.getState().pagination.pageSize}
                                onChange={(e) => {
                                    table.setPageSize(Number(e.target.value));
                                }}
                                className="outline-none p-1 border rounded "
                            >
                                {[50, 100, 200, 300, 400].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={openDateRangeModal}
                setOpen={setOpenDateRangeModal}
                title='Select Date Range'
                className="max-w-sm"
            >
                <div className='flex items-center my-10'>
                    <div
                        className='flex items-center flex-1 justify-center cursor-pointer'
                        onClick={() => {
                            setShowStartDatePicker(true);
                            setShowEndDatePicker(false);
                        }}
                    >
                        <Icon icon="material-symbols:date-range" className='text-primary text-2xl mr-2' />
                        <div className='text-primary'>
                            {startDate ? startDate.format('DD/MM/YYYY') : 'Start Date'}
                        </div>
                    </div>
                    {
                        (showEndDatePicker || showStartDatePicker) && <div className='fixed inset-0' onClick={() => {
                            setShowEndDatePicker(false);
                            setShowStartDatePicker(false);
                        }} />
                    }
                    {showStartDatePicker && (
                        <div className="my-4">
                            <Datetime
                                value={startDate}
                                onChange={handleStartDateChange}
                                inputProps={{ style: { display: 'none' } }}
                                open={true}
                            />
                        </div>
                    )}
                    <div
                        className='flex items-center flex-1 justify-center cursor-pointer'
                        onClick={() => {
                            setShowEndDatePicker(true);
                            setShowStartDatePicker(false)
                        }}
                    >
                        <Icon icon="material-symbols:date-range" className='text-primary text-2xl mr-2' />
                        <div className='text-primary'>
                            {endDate ? endDate.format('DD/MM/YYYY') : 'End Date'}
                        </div>
                    </div>

                    {showEndDatePicker && (
                        <div className="my-4">
                            <Datetime
                                value={endDate}
                                onChange={handleEndDateChange}
                                inputProps={{ style: { display: 'none' } }}
                                open={true}
                            />
                        </div>
                    )}
                </div>
                <div className='flex justify-end items-center'>
                    <Button className="rounded mr-2 bg-sky-600 py-2 px-4 text-sm uppercase text-white bg-red400  data-[active]:bg-[#c81b1b]" onClick={() => handleResetDateRange()}>
                        Reset
                    </Button>
                    <Button className="rounded bg-sky-600 py-2 px-4 text-sm uppercase text-white bg-primary disabled:bg-gray300 disabled:cursor-not-allowed  data-[active]:bg-[#225fea]" disabled={!(startDate && endDate)} onClick={() => handleDateRangeApply()}>
                        Done
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default MantraLekhan