import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";

import TextField from "../../../Components/Common/Input/TextField";
import { api } from "../../../services/api.service";
import { toastr } from "../../../services/common.service";
import { PAGES } from "../../../constants/common.constants";
import { fetchData } from "../../../services/fetchdata.service";
import { Icon } from "@iconify/react/dist/iconify.js";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    if (userToken) navigate(PAGES.HOME);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email) toastr.show("Please enter email", "error");
      if (!password) toastr.show("Please enter password", "error");

      const paylod = {
        username: email,
        password: password,
      };

      const response = await api.post("/login", paylod);
      if (response?.data?.token) {
        toastr.show("Success !!", "success");
        localStorage.setItem("token", "Bearer " + response?.data?.token);

        const loggedInUser = _.cloneDeep(response.data);
        delete loggedInUser.token;
        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));

        navigate(PAGES.HOME);

        // fetch base information
        fetchData(dispatch);
      } else {
        toastr.show("Incorrect Username/Password", "error");
      }
    } catch (err) {
      toastr.show("Incorrect Username/Password", "error");
    }
  };

  return (
    <div className="h-screen">
      <div className="bg-white h-full flex items-center justify-center">
        <div
          className="p-9 rounded-3xl h-2/3 w-3/5 shadow-blue-gray-900 shadow-2xl bg-cover flex items-center justify-end"
          style={{ backgroundImage: "url('/assets/image/Login-Image1.Webp')" }}
        >
          {/* <div className="bg-brown-800 rounded-l-3xl h-full w-full">
            <div>
              <img
                className="rounded-l-3xl h-full w-full"
                src="/assets/image/Login-Image1.Webp"
              />
            </div>
          </div> */}
          <div>
            <div className="p-9 rounded-xl bg-blue-50 w-fit">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                  className="mx-auto h-20 rounded-full w-auto"
                  src="/assets/image/admin.jpg"
                  alt="smv logo"
                />
                <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-primary">
                  SMV Login
                </h2>
              </div>
              <form onSubmit={(e) => handleLogin(e)}>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      SMV No./Mobile No.
                    </label>
                    <div className="mt-2">
                      <TextField
                        type="text"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="mt-2">
                      <div className="flex items-center border border-[#b0bec5] rounded-lg">
                        <div className="flex-grow">
                          <TextField
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="on"
                            className="border-none"
                          />
                        </div>
                        <div
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <Icon
                              icon="bx:show"
                              className="w-[40px] cursor-pointer text-xl"
                            />
                          ) : (
                            <Icon
                              icon="bx:hide"
                              className="w-[40px] cursor-pointer text-xl"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#336aeaf3] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
