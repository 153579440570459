import React from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { Fragment, useState } from "react";

const ImgPreviewModal = (props) => {
  const { show, closeModal, previewImg } = props;

  return (
    <div>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center scale-125">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className=" transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div>
                    <img
                      src={previewImg}
                      alt="img-preview"
                      className="w-[300px] 2xl:w-[600px] h-auto"
                    />
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ImgPreviewModal;
