export const ACTION_TYPES = {
    "SET_MEMBERS_LIST": "SET_MEMBERS_LIST",
    "ADD_MEMBER": "ADD_MEMBER",
    "UPDATE_MEMBER": "UPDATE_MEMBER",
    "REMOVE_MEMBER": "REMOVE_MEMBER",
    "SET_COMMON_FIELDS": "SET_COMMON_FIELDS",

    "SET_SAINT_LEADERS": "SET_SAINT_LEADERS",
    "ADD_SAINT_LEADER": "ADD_SAINT_LEADER",
    "UPDATE_SAINT_LEADER": "UPDATE_SAINT_LEADER",
    "REMOVE_SAINT_LEADER": "REMOVE_SAINT_LEADER",

    "SET_HOS_USERS": "SET_HOS_USERS",
    "ADD_HOS_USERS": "ADD_HOS_USERS",
    "UPDATE_HOS_USERS": "UPDATE_HOS_USERS",
    "REMOVE_HOS_USERS": "REMOVE_HOS_USERS",

    "SABHA": "SABHA",
    "ADD_SABHA": "ADD_SABHA",
    "UPDATE_SABHA": "UPDATE_SABHA",
    "DELETE_SABHA": "DELETE_SABHA",

    "SET_NEW_MEMBERS_LIST": "SET_MEMBERS_LIST",
    "ADD_NEW_MEMBER": "ADD_MEMBER",
    "UPDATE_NEW_MEMBER": "UPDATE_MEMBER",
    "REMOVE_NEW_MEMBER": "REMOVE_MEMBER",

    "SET_MEMBER_FLAG": "SET_MEMBER_FLAG",
    "UPDATE_MEMBER_FLAG": "UPDATE_MEMBER_FLAG"

}