import { api } from "../../services/api.service";
import { toastr } from "../../services/common.service";

export const getMantraLekhan = async (filterStr) => {
    try {
        const filterURL = filterStr? `mantra-lekhan/dashboard?${filterStr}` : 'mantra-lekhan/dashboard?limit=10&skip=0';
        const response = await api.get(filterURL);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const getMantraLekhanAudit = async (filterStr) => {
    try {
        const filterURL = filterStr? `mantra-lekhan/user-audit?${filterStr}` : 'mantra-lekhan/user-audit?skip=0';
        const response = await api.get(filterURL);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const blockMantraLekhanUser = async (userId) => {
    try {
        const apiURL = 'mantra-lekhan/block';
        const response = await api.post(apiURL, {userId: userId});
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

export const unBlockMantraLekhanUser = async (userId) => {
    try {
        const apiURL = 'mantra-lekhan/unblock';
        const response = await api.post(apiURL, {userId: userId});
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
}

/* export const updateUserPermission = async (permissionsData) => {
    try {
        const response = await api.post('admin/user/permissions', permissionsData);
        return response;
    } catch (error) {
        toastr.show(error.response?.data?.message ?? "Something went wrong", "error")
    }
} */