import { mdiCalendarMonthOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import DateTimePicker from "react-datetime";

export default function DateField(props) {
  const {
    name,
    dateFormat,
    id,
    onChange,
    placeholder,
    disabled,
    value,
    className,
    label,
    required,
    timeFormat,
    parentStyle,
  } = props;

  return (
    <div className={`${parentStyle} w-full`}>
      {label && (
        <label
          htmlFor={id}
          className="text-sm text-gray300 font-semibold mb-2 inline-block"
        >
          {label}
          {required && <span className="text-red400">*</span>}
        </label>
      )}
      <div className="relative">
        <DateTimePicker
          id={id}
          value={value || ""}
          dateFormat={dateFormat ? dateFormat : "DD/MM/YYYY"}
          timeFormat={timeFormat}
          onChange={onChange}
          inputProps={{
            placeholder: placeholder,
            disabled: disabled,
            className: `bg-white w-full h-full outline-none`,
          }}
          className={`bg-white appearance-none text-sm h-9 border border-[#b0bec5] text-black900 outline-none px-2.5 py-2 rounded-lg ${className} placeholder:text-[#b0bec5] placeholder:text-sm caret-stone-400 placeholder:pl-0.5`}
          displayTimeZone="Asia/Calcutta"
        />
        <label
          htmlFor={id}
          className="absolute right-2 top-[50%] translate-y-[-50%]"
        >
          <Icon path={mdiCalendarMonthOutline} size={"18px"} color="#b0bec5" />
        </label>
      </div>
    </div>
  );
}
