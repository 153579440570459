import { mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { s3Url } from "../../../services/api.service";

const MobileWallpapers = (props) => {
  const { wallpaperImages, removeWallpaperImage } = props;

  return (
    <div className="bg-white w-full h-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
      <div>
        <div className="flex-grow">
          <div className="grid grid-cols-5 -m-2.5">
            {
              wallpaperImages?.mobileFileUrls?.length > 0 &&
              wallpaperImages?.mobileFileUrls?.map((eachUrl, index) => {
                return (
                  <div key={index} className="h-full p-2.5">
                    <div className="rounded-lg w-[160] h-[380px] overflow-hidden relative">
                      <img
                        src={`${s3Url}${eachUrl}`}
                        alt="profile-img"
                        className="w-full object-contain"
                      />
                      <div className="absolute rounded-lg w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.50)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease]">
                          <button onClick={() => removeWallpaperImage(eachUrl, 'mobile')}>
                            <Icon path={mdiTrashCan} size={1} />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileWallpapers;
