import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInputField = (props) => {
  const { label, id, required, onChange, value, countryCode } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    if (!countryCode && value?.length <=10) {
      setPhoneNumber(`91${value}` )
    } else {
      const phoneNo = processMobileNumber(value);
      setPhoneNumber(phoneNo)
    }
  }, [value])


  const processMobileNumber = (mobileNumber) => {
    // Remove any non-digit characters from the mobile number
    const cleanNumber = mobileNumber || mobileNumber?.replace(/\D/g, '');
  
    // Count the number of digits
    const numberOfDigits = cleanNumber?.length;
    if (numberOfDigits > 10) {
      // If more than 10 digits, treat the first two as country code
      const countryCode = cleanNumber.substring(0, 2);
      const restOfTheNumber = cleanNumber.substring(2);

      return `+${countryCode} ${restOfTheNumber}`;
    } else {
      // If 10 or fewer digits, return the number as is

      return cleanNumber;
    }
  }

  return (
    <>
      <div>
        {label && (
          <label
            htmlFor={id}
            className="text-sm text-gray300 font-semibold mb-2 inline-block"
          >
            {label}
            {required ? <span className="text-red400">*</span> : <></>}
          </label>
        )}
        <PhoneInput
          value={`${phoneNumber}`}
          onChange={onChange}
          inputStyle={{ width: '100%' }}
          country='in'
          countryCodeEditable={false}
          enableSearch
        />
      </div>
    </>
  );
};

export default PhoneInputField;
