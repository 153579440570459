import React from 'react';
import AddMember from './AddMember';

const NewMember = (props) => {

    const { userDetail, setIsAddMemberModalOpen, isNewUser = false } = props
    
    return (
        <>
            <div className={`block fixed inset-0 z-[10000] bg-[#00000066]`}></div>
            <div className={`absolute max-h-[90%] pb-15 pt-5 transition-all duration-150 px-5 block max-w-[1000px] w-full  bg-white rounded-md left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] overflow-hidden z-[99999] modal`}>
                <AddMember 
                    setIsAddMemberModalOpen={setIsAddMemberModalOpen}
                    userDetail={userDetail}
                    isNewUser={isNewUser}
                />
            </div>
        </>
    )
}

export default NewMember