import React, { useEffect } from "react";
import _ from "lodash";

import CreatableMultiselectMain from "../../Common/Input/CreatableMultiselectMain";
import { GROUP_NUMBERS } from "../../../constants/common.constants";

const DeptGroupSelection = ({
  department,
  setDepartmentGroups,
  departmentGroups,
  fullDepartmentList,
}) => {

  useEffect(() => {
    setDepartmentGroups(department?.departmentGroups || []);
  }, [department]);

  const selectGroupNo = (groupNos) => {
    setDepartmentGroups(groupNos.sort((a, b) => parseInt(a) - parseInt(b)));
  };

  const alreadyInUseNos = _.flatten(
    fullDepartmentList
      .filter((dept) => dept.sabhaId === department.sabhaId)
      .map((dept) => dept.departmentGroups)
  );
  
  const groupNumberOptions = GROUP_NUMBERS.map((option) => {
    const returnOpt = {
      label: option.label,
      value: option.value,
    }
    if (alreadyInUseNos.includes(option.value)) {
      returnOpt.isDisabled = true; 
    }
    return returnOpt;
  });

  return (
    <div className="flex justify-between border-r border-gray-200">
      <CreatableMultiselectMain
        id="2"
        className="!h-9 placeholder:text-black900"
        isMulti={true}
        required={true}
        placeholder="Select Group Number"
        value={
          departmentGroups?.map((d) => ({
            label: d,
            value: d,
          })) || []
        }
        minWidth="400px"
        onChange={(value) => {
          const _value = value?.map((d) => d.value) || [];
          selectGroupNo(_value);
        }}
        options={groupNumberOptions}
        menuPosition="fixed"
      />
    </div>
  );
};

export default DeptGroupSelection;
