import { Dialog, Transition, TransitionChild } from '@headlessui/react'
import { Icon } from '@iconify/react'
import React, { Fragment, useEffect, useState } from 'react'
import { createPlaylistSection, updatePlaylistSection } from './actionCreator'
import { toastr } from '../../../services/common.service'

const SinglePlaylistMenu = ({ isOpen, closeModal, selectedSections, setSelectedSections, playlists, sectionData }) => {
    const [title, setTitle] = useState('');
    const [playlistId, setPlaylistId] = useState('');
    const [selectedPlaylist, setSelectedPlaylist] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPlaylists, setFilteredPlaylists] = useState(playlists);

    const handleclose = () => {
        setTitle('');
        setPlaylistId('');
        setSelectedPlaylist({});
        setIsDisabled(true);
        closeModal();
        setSearchQuery('')
    }

    useEffect(() => {
        const filtered = playlists.filter(playlist =>
            playlist.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredPlaylists(filtered);
    }, [searchQuery, playlists]);



    useEffect(() => {
        if (title.length > 0 && playlistId) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [sectionData, title, playlistId])


    useEffect(() => {
        setTitle(sectionData.name || '');
        if (sectionData._id) {
            setPlaylistId(sectionData?.playlist[0]._id || '')
            setSelectedPlaylist(sectionData?.playlist[0] || {})
        }
    }, [sectionData])

    const handlePlaylistSelection = (item) => {
        setPlaylistId(item?._id);
        setSelectedPlaylist(item);
        if (!title) {
            setTitle(item.name)
        }
    }

    const isValidPayload = () => {
        let filteredSections = selectedSections.filter(item => item._id != sectionData._id);
        let isTitleExist = filteredSections.some(item => item.name === title);
        let isPlaylistExist = filteredSections.some(item => item.playlist[0]?._id === playlistId && item.type === "single");

        if (isTitleExist) {
            toastr.show("Section with this title already exists", "error");
            return false;
        } else if (isPlaylistExist) {
            toastr.show("Section with this playlist already exists", "error");
            return false;
        } else if (!title) {
            toastr.show("Please select section title", "error");
            return false;
        } else if (!selectedPlaylist) {
            toastr.show("Please select playlist", "error");
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        let isValid = await isValidPayload();
        if (isValid) {
            let arrOfIndex = [];
            let indx = playlists.findIndex((item => item._id === playlistId));
            arrOfIndex.push(indx);
            let newData = {
                name: title ? title : selectedPlaylist.name,
                type: "single",
                playlist: arrOfIndex.map(index => playlists[index]),
                position: selectedSections.length,
            };
            if (sectionData._id) {
                newData._id = sectionData._id;
            }
            return newData
        }
    };

    const createSection = async () => {
        try {
            let data = await handleSave();

            if (data) {
                const response = await createPlaylistSection(data);
                if (response.data) {
                    toastr.show("Section created successfully!", "success");
                    let dataWithId = { ...data, _id: response.data }

                    setSelectedSections((prevSection) => [...prevSection, dataWithId]);
                }
                handleclose()
            }

        } catch (error) {
            toastr.show(error.message, "error");
        }
    };

    const updateSection = async () => {
        try {
            let isValid = await isValidPayload();
            if (isValid) {
                const data = { ...sectionData };
                let arrOfIndex = [];
                let indx = playlists.findIndex(item => item._id === playlistId);
                arrOfIndex.push(indx);
                data.playlist = arrOfIndex.map(index => playlists[index]);
                data.name = title;

                delete data.isDeleted;
                delete data.createdAt;
                delete data.updatedAt;
                delete data.__v;
                delete data.order;
                const response = await updatePlaylistSection(data);
                if (response.data) {
                    toastr.show("Section updated successfully", "success");
                    const index = selectedSections.findIndex(item => item._id === sectionData._id);
                    if (index !== -1) {
                        const updatedSections = [...selectedSections];
                        updatedSections[index] = data;
                        setSelectedSections(updatedSections);
                    }
                }
                handleclose()
            }
        } catch (error) {
            toastr.show(error.message, "error");
        }
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" open={isOpen} className="relative z-20" onClose={handleclose}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-fit h-[90vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className='h-full'>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-semibold leading-6 text-gray-900 mb-3"
                                        >
                                            Choose a playlist
                                        </Dialog.Title>
                                        <div className='flex items-center gap-5'>
                                            <div className='w-1/2 border border-gray400 max-w-[500px] p-2 rounded-md'>
                                                <input type='text' className='outline-none w-full' value={title} placeholder='Enter section title' onChange={(e) => setTitle(e.target.value)} />
                                            </div>
                                            <div className='w-1/2 flex items-center border border-gray400 max-w-[500px] p-2 rounded-md'>
                                                <Icon icon="iconoir:search" className='text-xl mr-2 text-gray400' />
                                                <input type='text' value={searchQuery} className='outline-none flex-grow' placeholder='Your playlists' onChange={(e) => setSearchQuery(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <div className="mt-2 h-[calc(90vh_-_200px)] overflow-auto">
                                                <div className='grid grid-cols-2 gap-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 mr-2.5'>
                                                    {
                                                        filteredPlaylists.length > 0 && filteredPlaylists.map((item, index) => {
                                                            return (
                                                                <div key={index} className={`border ${playlistId === item._id ? 'border-primary' : ''} border-gray400 flex flex-col rounded-lg p-3 w-[160px] h-[180px] 2xl:w-[180px] 2xl:h-[200px] cursor-pointer`} onClick={() => handlePlaylistSelection(item)}>
                                                                    <img src={
                                                                        item.thumbnailImages ? item.thumbnailImages.default : "/assets/image/no-thumbnail.png"
                                                                    } className='w-full h-[75px] 2xl:h-[85px] rounded-md' />
                                                                    <div className='mt-2 flex flex-col flex-grow justify-between'>
                                                                        <div className='text-sm text-ellipsis overflow-hidden line-clamp-2'>{item.name}</div>
                                                                        <div className='text-sm'>{item.videos.length}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="mt-5 flex justify-end">
                                                <button
                                                    type="button"
                                                    className="mr-3 inline-flex justify-center uppercase rounded-md font-semibold bg-white px-4 py-2 text-sm  text-red-600 hover:text-red-600 focus:outline-none"
                                                    onClick={handleclose}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    disabled={isDisabled}
                                                    className="inline-flex justify-center uppercase rounded-md border border-transparent bg-primary disabled:bg-gray400 px-4 py-2 text-sm font-medium text-white hover:bg-primary focus:outline-none"
                                                    onClick={() => sectionData._id ? updateSection() : createSection()}
                                                >
                                                    {
                                                        sectionData._id ? "Update" : "Done"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default SinglePlaylistMenu