import { api } from "../../services/api.service";

export const getNewMembersList = async () => {
    const response = await api.get('/admin/new-users');
    return response;
}

export const getSabhaTypes = async () => {
    const response = await api.get('/admin/sabha-type');
    return response;
}

export const deleteNewMember = async (id) => {
    const response = await api.post(`/admin/new-user/delete`, {_id : id});
    return response;
}